import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { FormatHelper } from 'src/app/shared/common/FormatHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { EmployeeDatetimeType } from '../models/EmployeeDatetimeType';
import { DatetimesTypesService } from './datetimes-types.service';

@Injectable({
    providedIn: 'root'
})
export class DatetimesTypesStoreService {

    private readonly datetimeTypesSubject = new BehaviorSubject<EmployeeDatetimeType[]>([]);
    public readonly datetimeTypes$ = this.datetimeTypesSubject.asObservable();

    public get datetimeTypes(): EmployeeDatetimeType[] {
        return this.datetimeTypesSubject.getValue();
    }

    public set datetimeTypes(val: EmployeeDatetimeType[]) {
        this.datetimeTypesSubject.next(val);
    }

    constructor(
        private datetimesTypesService: DatetimesTypesService,
        private formatHelper: FormatHelper,
        private apiHelper: ApiHelper,
        private swalHelper: SwalHelper
    ) {
        void this.fetchAll();
    }

    public getCanPlanDatetimeTypes(): Observable<EmployeeDatetimeType[]> {
        return this.datetimeTypes$.pipe(map(datetimes => {
            return datetimes.filter(datetime => datetime.can_plan);
        }));
    }

    public getIsShiftDatetimeTypes(): Observable<EmployeeDatetimeType[]> {
        return this.datetimeTypes$.pipe(map(datetimes => {
            return datetimes.filter(datetime => datetime.is_shift);
        }));
    }

    public getDatetimeTypesExceptPause(): Observable<EmployeeDatetimeType[]> {
        return this.datetimeTypes$.pipe(map(datetimes => {
            return datetimes.filter(datetime => datetime.employee_datetime_type_ID !== 7);
        }));
    }

    public get types(): Observable<EmployeeDatetimeType[]> {
        return this.datetimeTypes$;
    }

    public async addDatetimeType(val: EmployeeDatetimeType) {

        const tmpId = this.formatHelper.uuid();
        const tmpDatetime: EmployeeDatetimeType = {
            employee_datetime_type_ID: tmpId,
            name: val.name,
            abbreviation: val.abbreviation,
            color: val.color,
            timer: val.timer,
            can_plan: val.can_plan,
            is_shift: val.is_shift,
            worktime_classification: val.worktime_classification,
            can_delete: false,
            is_approvable: true,
            is_active: true,
            created_at: new Date().toISOString().substring(0, 10)
        };
        this.datetimeTypes = [
            ...this.datetimeTypes,
            tmpDatetime
        ];

        try {
            const datetimeType: any = await lastValueFrom(this.datetimesTypesService
                .saveDatetimeType(val).pipe(map((response: any) => response.datetimeType)));

            const index = this.datetimeTypes.indexOf(this.datetimeTypes.find(t => t.employee_datetime_type_ID === tmpId));
            this.datetimeTypes[index] = {
                ...datetimeType
            };

            this.datetimeTypes = [...this.datetimeTypes];

            return true;
        } catch (e) {
            await this.removeBranch(tmpId, false);
            return false;
        }

    }

    public async updateDatetimeType(typeID: number | string, val: EmployeeDatetimeType) {
        const datetimeType = this.datetimeTypes.find(branch => branch.employee_datetime_type_ID === typeID);
        if (datetimeType) {
            // optimistic update
            const index = this.datetimeTypes.indexOf(datetimeType);

            this.datetimeTypes[index] = {
                employee_datetime_type_ID: typeID,
                created_at: datetimeType.created_at,
                can_delete: datetimeType.can_delete,
                ...val,
            };

            this.datetimeTypes = [...this.datetimeTypes];

            try {
                await lastValueFrom(this.datetimesTypesService.updateDatetimeType(typeID, val));

            } catch (e) {
                this.apiHelper.handleErrorResponse(e);
                this.datetimeTypes[index] = {
                    ...datetimeType,
                };
            }
        }
    }

    public async removeBranch(typeID: string | number, serverRemove = true) {

        let deleteConfirmed = false;

        if (serverRemove) {
            deleteConfirmed = await this.swalHelper.showConfirmDeleteDialog();
        }

        if (deleteConfirmed || !serverRemove) {
            // optimistic update
            const datetimeType = this.datetimeTypes.find(t => t.employee_datetime_type_ID === typeID);
            this.datetimeTypes = this.datetimeTypes.filter(todo => todo.employee_datetime_type_ID !== typeID);
            if (serverRemove) {
                try {
                    await lastValueFrom(this.datetimesTypesService.deleteDatetimeType(typeID));
                } catch (e) {
                    this.apiHelper.handleErrorResponse(e);
                    this.datetimeTypes = [...this.datetimeTypes, datetimeType];
                }
            }
        }
    }

    public async fetchAll() {
        try {
            this.datetimeTypes = await lastValueFrom(this.datetimesTypesService.getAllDatetimeTypes());
        } catch (error) {
            console.log(error);
        }
    }
}
