<app-screen-size *ngIf="showScreenSize"></app-screen-size>
<!-- Topbar Start -->
<div class="navbar-custom topnav-navbar">
    <div class="container-fluid pl-lg-2 pl-0">
        <!-- Menu toggle -->
        <a (click)="collapseMenu()" [attr.aria-expanded]="!isCollapsed"
           aria-controls="collapseExample" class="navbar-toggle d-block"
           data-target="#topnav-menu-content" data-toggle="collapse"
           id="navbar-toggle">
            <div class="lines"><span></span><span></span><span></span></div>
        </a>
        <!-- LOGO -->
        <a *ngIf="loggedInUser$ | async as user" class="topnav-logo d-block pl-1" routerLink="/dashboard">
            <img [attr.src]="user?.workspace?.logoBase64Safe" alt="logo">
        </a>

        <ul class="list-unstyled topbar-right-menu float-right mb-0">
            <li *ngIf="this.innerWidth >= 992" class="search-bar d-none d-lg-block">
                <div>
                    <app-search [isModal]="false"></app-search>
                </div>
            </li>
            <li class="dropdown notification-list d-block d-lg-none">
                <a (click)="openSearchModal()"
                   aria-expanded="false" aria-haspopup="true"
                   class="nav-link dropdown-toggle arrow-none" data-toggle="dropdown"
                   id="topbar-notifydrop-1" role="button">
                    <i class="mdi mdi-magnify noti-icon mdi-24px"></i>
                </a>
            </li>

            <li (openChange)="readNotifications($event)"
                class="dropdown notification-list" ngbDropdown>
                <a aria-expanded="false" aria-haspopup="true"
                   class="nav-link dropdown-toggle arrow-none" data-toggle="dropdown"
                   id="topbar-notifydrop-2" ngbDropdownToggle role="button">
                    <i [ngClass]="{bell: notiService.showUnreadBadge}" class="mdi mdi-bell-outline noti-icon mdi-24px"></i>
                    <span *ngIf="notiService.showUnreadBadge" class="noti-icon-badge bg-primary"></span>
                </a>

                <div aria-labelledby="topbar-notifydrop-2"
                     class="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown fadeIn animated faster shadow" ngbDropdownMenu>

                    <!-- item-->
                    <div class="dropdown-item noti-title mb-2">
                        <h5 class="m-0">
                            <span class="float-right"></span>
                            <span>{{ 'notifications.page_title_index' | translate }}</span>
                        </h5>
                    </div>

                    <div data-simplebar data-simplebar-force-visible="true" style="max-height: 230px; overflow-y: scroll;">
                        <!-- item-->
                        <a *ngFor="let n of notiService.notifications$ | async"
                           [class.bg-light]="!n.read_at"
                           [routerLink]="n.data.redirect_url"
                           class="dropdown-item notify-item">
                            <div class="notify-icon bg-primary">
                                <i class="mdi mdi-comment-account-outline"></i>
                            </div>

                            <p class="notify-details">
                                <span>{{ n.data.title }}</span>
                                <small class="text-muted">{{ n.data.message }}</small>
                            </p>
                        </a>
                    </div>
                    <div *ngIf="!(notiService.notifications$ | async)?.length" class="text-center">
                        <span>{{ 'notifications.no_unread_notifications' | translate }}</span>
                    </div>

                    <!-- All-->
                    <a class="dropdown-item text-center text-primary notify-item notify-all" routerLink="/notification/list">
                        {{ 'notifications.action_show_all' | translate }}
                    </a>
                </div>
            </li>

            <li class="dropdown notification-list" ngbDropdown #helpDropdown="ngbDropdown">
                <a aria-expanded="false" aria-haspopup="true"
                   class="nav-link dropdown-toggle arrow-none" data-toggle="dropdown"
                   id="topbar-notifydrop-3" ngbDropdownToggle role="button">
                    <i [ngClass]="{bell: isNewVersionAvailable}" class="mdi mdi-help-circle noti-icon mdi-24px"></i>
                    <span *ngIf="isNewVersionAvailable" class="noti-icon-badge bg-primary"></span>
                </a>

                <div aria-labelledby="topbar-notifydrop-3" id="sidebar-help"
                     class="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu fadeIn animated faster shadow help-list" ngbDropdownMenu>
                    <div class="help-sidebar-title mb-2">
                        <span>{{ 'help.quick_help' | translate }}</span>
                        <span class="float-right cursor-pointer">
                                    <i (click)="helpDropdown.close()" class="mdi mdi-close mdi-24px"></i>
                        </span>
                    </div>
                    <div class="help-sidebar-content">
                        <!-- newest version -->
                        <h5 class="help-sidebar-category">{{ 'help.newest_version' | translate }}</h5>
                        <ng-container *ngIf="latestVersions$ | async as latestVersions">
                            <a [routerLink]="['/whats-new']"
                               class="help-sidebar-item newest-version-container">
                                <div>
                                    <div class="help-sidebar-item-title">
                                        <span> {{ latestVersions[0]?.title }} </span>
                                    </div>
                                    <div class="help-sidebar-item-description" [innerHtml]="latestVersions[0].description"></div>
                                </div>
                                <div>
                                    <span *ngIf="isNewVersionAvailable" class="noti-icon-badge version-badge-relative bg-primary"></span>
                                </div>
                            </a>
                        </ng-container>

                        <!-- 5 newest versions -->
                        <h5 class="help-sidebar-category">{{ 'help.suggested_texts' | translate }}</h5>
                        <a *ngFor="let version of versionTexts$ | async"
                           target="_blank"
                           href="{{version.url}}"
                           class="help-sidebar-item">
                           <div class="help-sidebar-item-title">
                               <span> {{ version.title }} </span>
                           </div>
                           <div class="help-sidebar-item-description" [innerHtml]="version.description"></div>
                        </a>
                        <h5 class="help-sidebar-category">{{ 'help.quick_links' | translate }}</h5>
                        <a class="help-sidebar-item" [href]="docUrl" target="_blank">
                          <div class="help-sidebar-item-title">
                              <i class="mdi mdi-18px mdi-information-outline mr-2 text-primary"></i>
                              <span>{{ 'help.documentation' | translate }}</span>
                          </div>
                        </a>
                        <a class="help-sidebar-item" [routerLink]="['whats-new']">
                          <div class="help-sidebar-item-title d-flex justify-content-between align-items-center">
                              <div>
                                  <i class="mdi mdi-18px mdi-access-point mr-2 text-primary"></i>
                                  <span style="margin-left: -4px"> {{ 'help.whats_new' | translate }}</span>
                              </div>
                              <span *ngIf="isNewVersionAvailable" class="noti-icon-badge version-badge-relative bg-primary"></span>
                          </div>
                        </a>
                    </div>
                    <div class="help-sidebar-footer">
                        <a class="help-sidebar-item" (click)="showTidio()">
                            <div class="help-sidebar-item-title">
                                <i class="mdi mdi-18px mdi-chat-processing-outline mr-2 text-primary"></i>
                                {{ 'help.chat_with_us' | translate }}
                            </div>
                        </a>
                    </div>
                </div>
            </li>

            <li (openChange)="onOpenMenuDropdown($event)"
                *ngIf="loggedInUser$ | async as user"
                class="dropdown notification-list"
                ngbDropdown>
                <a aria-expanded="false" aria-haspopup="true"
                   class="nav-link dropdown-toggle nav-user arrow-none mr-0" data-toggle="dropdown" id="topbar-userdrop"
                   ngbDropdownToggle role="button">
                    <span class="account-user-avatar">
                        <ng-container *ngIf="!IOS_DEPLOY_HIDE && (canViewSubscription$ | async)?.can && subscriptionData$ | async as data">
                             <span *ngIf="data.subscription.package === 'STANDARD'"
                                   class="premium-crown">
                                <i class="mdi mdi-crown text-primary"></i>
                             </span>
                            <span *ngIf="data.subscription.package === 'PREMIUM'"
                                  class="premium-crown">
                                <i class="mdi mdi-crown text-warning"></i>
                            </span>
                        </ng-container>
                        <span class="desktop-user-avatar avatar-wrapper"
                              [ngClass]="{'impersonation': isImpersonationLogin}"
                              [ngbTooltip]="isImpersonationLogin ? ('global.impersonation_logged_in_as' | translate) + user?.fullname : ''"
                              [tooltipClass]="'impersonation-tooltip'"
                              container="body"
                              placement="bottom">
                            <img *ngIf="user?.avatar" [src]="user.avatar" alt="user-image" class="avatar-image">
                        </span>

                        <span [ngClass]="{'avatar-wrapper': !userDropdownOpened}" class="mobile-user-avatar">
                            <img *ngIf="!userDropdownOpened && user?.avatar" [src]="user.avatar" alt="user-image" class="avatar-image">
                            <span *ngIf="userDropdownOpened" class="burger open"><span></span><span></span><span></span></span>
                        </span>
                    </span>
                    <span>
                        <span *ngIf="greeting === 'morning'" class="account-user-name">
                            <span class="mr-1">{{ 'global.good_morning' | translate }},</span>
                            <span class="font-weight-bold">{{ user.salutation || user.name }}</span>
                        </span>

                        <span *ngIf="greeting === 'noon'" class="account-user-name">
                            <span class="mr-1">{{ 'global.good_noon' | translate }},</span>
                            <span class="font-weight-bold">{{ user.salutation || user.name }}</span>
                        </span>

                        <span *ngIf="greeting === 'afternoon'" class="account-user-name">
                            <span class="mr-1">{{ 'global.good_afternoon' | translate }},</span>
                            <span class="font-weight-bold">{{ user.salutation || user.name }}</span>
                        </span>

                        <span *ngIf="greeting === 'evening'" class="account-user-name">
                            <span class="mr-1">{{ 'global.good_evening' | translate }},</span>
                            <span class="font-weight-bold">{{ user.salutation || user.name }}</span>
                        </span>

                        <span *ngIf="greeting === 'day'" class="account-user-name">
                            <span class="mr-1">{{ 'global.good_day' | translate }},</span>
                            <span class="font-weight-bold">{{ user.salutation || user.name }}</span>
                        </span>

                        <ng-container *ngIf="!IOS_DEPLOY_HIDE && showSubscriptionInfo$ | async">
                            <small
                                *ngIf="subscriptionInfoString$ | async as infoString"
                                [class.text-danger]="(subscriptionOrTrialEnded$ | async) === true"
                                [class.text-warning]="(subscriptionOrTrialEnded$ | async) === false"
                                class="position-absolute d-none d-md-block">{{ infoString }}</small>
                        </ng-container>
                    </span>
                </a>

                <div
                    aria-labelledby="topbar-userdrop"
                    class="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown fadeIn animated faster shadow"
                    id="dropdown-menu"
                    ngbDropdownMenu>
                    <div class="dropdown-item user-item">
                        <span class="account-user-avatar-dropdown mr-3 avatar-wrapper">
                            <img
                                *ngIf="user?.avatar"
                                [src]="user?.avatar"
                                alt="user-image"
                                class="avatar-image"
                            >
                        </span>

                        <span>
                            <span *ngIf="greeting === 'morning'" class="account-user-name">
                                <span class="mr-1">{{ 'global.good_morning' | translate }},</span>
                                <span class="font-weight-bold">{{ user.salutation || user.name }}</span>
                            </span>

                            <span *ngIf="greeting === 'noon'" class="account-user-name">
                                <span class="mr-1">{{ 'global.good_noon' | translate }},</span>
                                <span class="font-weight-bold">{{ user.salutation || user.name }}</span>
                            </span>

                            <span *ngIf="greeting === 'afternoon'" class="account-user-name">
                                <span class="mr-1">{{ 'global.good_afternoon' | translate }},</span>
                                <span class="font-weight-bold">{{ user.salutation || user.name }}</span>
                            </span>

                            <span *ngIf="greeting === 'evening'" class="account-user-name">
                                <span class="mr-1">{{ 'global.good_evening' | translate }},</span>
                                <span class="font-weight-bold">{{ user.salutation || user.name }}</span>
                            </span>

                            <span *ngIf="greeting === 'day'" class="account-user-name">
                                <span class="mr-1">{{ 'global.good_day' | translate }},</span>
                                <span class="font-weight-bold">{{ user.salutation || user.name }}</span>
                            </span>
                        </span>
                    </div>

                    <ng-container *ngIf="!IOS_DEPLOY_HIDE && showSubscriptionInfo$ | async">
                        <a *ngIf="subscriptionInfoString$ | async as infoString"
                           [class.text-danger]="(subscriptionOrTrialEnded$ | async) === true"
                           [class.text-warning]="(subscriptionOrTrialEnded$ | async) === false"
                           class="dropdown-item notify-item d-md-none">{{ infoString }}</a>
                    </ng-container>

                    <!-- item-->
                    <a (click)="openSwitchWorkspaceModal()" class="dropdown-item notify-item workspace-switch">
                        <div class="workspace-logo text-center mb-2">
                            <img [attr.src]="user?.workspace?.logoBase64Safe" alt="logo">
                        </div>

                        <div>
                            <span class="text-wrap">{{ user.workspace?.label }}</span>
                            <ng-container *ngIf="!IOS_DEPLOY_HIDE && showSubscriptionInfo$ | async">
                                <small
                                    *ngIf="subscriptionInfoString$ | async as infoString"
                                    [class.text-danger]="(subscriptionOrTrialEnded$ | async) === true"
                                    [class.text-warning]="(subscriptionOrTrialEnded$ | async) === false"
                                    class="d-none d-md-block">{{ infoString }}</small>
                            </ng-container>
                        </div>

                        <div class="toggle-link text-primary d-none">{{ 'menu.workspace' | translate }}</div>
                    </a>

                    <div *ngIf="isImpersonationLogin" class="impersonate-login-wrap">
                        <div class="alert alert-warning w-100 shadow impersonate-login-info">
                          <div class="impersonate-login-label">{{ ('global.impersonation_logged_in_as' | translate) }}</div>
                          <div class="impersonate-login-value">{{ user?.fullname }}</div>
                        </div>
                    </div>

                    <a (click)="hideSensitiveInfo($event)" class="checkbox-item notify-item d-flex justify-content-between align-items-center">
                        {{ 'topbar.hide_sensitive_info' | translate }}
                        <div class="pointer-events-none">
                            <input data-switch="success"
                                   [checked]="hiddenInputs"
                                   id="hideSensitiveInfo"
                                   type="checkbox"
                            >

                            <label class="m-0" for="hideSensitiveInfo">&nbsp;</label>
                        </div>
                    </a>

                    <a [routerLink]="(userType$ | async) === 'EMPLOYEE' ? ['/employee', user.employee?.employee_ID, 'detail', 'overview'] : ['/user', 'detail', user.user_ID]"
                       class="dropdown-item notify-item">{{ 'menu.my_employee_profile' | translate }}</a>

                    <a *ngIf="(userType$ | async) === 'EMPLOYEE'" [routerLink]="['/employee', user.employee?.employee_ID, 'detail', 'setting']"
                       class="dropdown-item notify-item">{{ 'menu.my_employee_settings' | translate }}</a>

                    <a *ngIf="!IOS_DEPLOY_HIDE && (canViewSubscription$ | async)?.can && subscriptionData$ | async as data"
                       [routerLink]="['/setting', 'subscription-settings']"
                       class="dropdown-item notify-item">
                        {{'subscription.subscription' | translate}}
                        <span *ngIf="data.subscription.package === 'STANDARD'"
                              class="">
                                <i class="mdi mdi-crown mdi-18px text-primary"></i>
                             </span>
                        <span *ngIf="data.subscription.package === 'PREMIUM'"
                              class="">
                                <i class="mdi mdi-crown mdi-18px text-warning"></i>
                            </span>
                    </a>

                    <a (click)="logout()" class="dropdown-item notify-item">{{ 'topbar.logout' | translate }}</a>
                </div>
            </li>
        </ul>
    </div>
</div>
<!-- end Topbar -->

<div id="scrollable-mobile-menu" [class.opened]="!isCollapsed" [ngClass]="{'dev-background': !environment.production}" class="topnav mobile-menu mb-3 bg-gray" style="min-height: 54px;">
    <div class="container-fluid">
        <nav class="navbar navbar-dark navbar-expand-lg topnav-menu">
            <div [ngbCollapse]="isCollapsed"
                 class="collapse navbar-collapse fadeIn animated faster" id="collapseExample">
                <ul class="navbar-nav">
                    <ng-container *ngIf="{stats: menuStats$ | async, menu: menu$ | async} as menuObject">
                        <ng-container *ngFor="let m of menuObject.menu; last as isLast">
                            <li id="mobile-li-menu-{{m.menu_item_ID}}" [ngClass]="{ 'dropleft-down nav-item-right' : isLast && m.name === 'menu.settings' }"
                                class="nav-item dropdown"
                                ngbDropdown>
                                <a (click)="redirect(m, false, false, true)" *ngIf="m.name !== 'separator'" [routerLink]="m.submenu ? null : m.routerLink"
                                   aria-expanded="false" aria-haspopup="true"
                                   class="nav-link dropdown-toggle arrow-none d-flex justify-content-between align-items-center" data-toggle="dropdown" id="topnav-dashboards"
                                   ngbDropdownToggle role="button">
                                    <span>{{ m.name | translate }}</span>
                                    <span class="d-flex justify-content-between">
                                        <span *ngIf="menuObject.stats && menuObject.stats[m.name] === 'badge'"
                                              class="entity-indicator-dot badge-primary mr-4"></span>
                                        <span *ngIf="m.submenu" class="arrow-down"></span>
                                    </span>
                                </a>

                                <div *ngIf="m.submenu" aria-labelledby="topnav-dashboards" class="dropdown-menu" ngbDropdownMenu>
                                    <div class="container-fluid">
                                        <ng-container *ngFor="let s of m.submenu">
                                            <a
                                               *ngIf="s.name !== 'menu.subscription' || (s.name === 'menu.subscription' && !IOS_DEPLOY_HIDE)"
                                               class="dropdown-item submenu-text" routerLinkActive="active" [routerLink]="s.routerLink">
                                                <span>
                                                  <span *ngIf="s.icon" class="icon">
                                                    <i class="{{ s.icon + ' mdi-30px' }}"></i>
                                                  </span>

                                                  <span>{{ s.name | translate }}</span>
                                                </span>
                                            </a>
                                        </ng-container>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                    </ng-container>
                </ul>
            </div>
        </nav>
    </div>
</div>

<div [ngClass]="{'dev-background': !environment.production}" class="desktop-menu">
    <!-- Menu toggle -->
    <div class="toggler-container d-flex align-items-center justify-content-start">
        <i (click)="collapseMenu()"
           class="font-20 mdi mdi-arrow-collapse-{{arrowDirection}} navbar-toggler-arrow">
        </i>
    </div>
    <!-- Sidebar -left -->
    <div class="show" data-simplebar="init" id="leftside-menu-container">
        <div class="simplebar-wrapper" style="margin: 0;">
            <div class="simplebar-height-auto-observer-wrapper">
                <div class="simplebar-height-auto-observer"></div>
            </div>
            <div class="simplebar-mask">
                <div class="simplebar-offset" style="right: 0; bottom: 0;">
                    <div aria-label="scrollable content" class="simplebar-content-wrapper" role="region" style="height: 100%; overflow: hidden scroll;" tabindex="0">
                        <div class="simplebar-content" style="padding: 0;">
                            <!--- Sidemenu -->
                            <ul class="side-nav pt-1" id="side-nav-list">
                                <ng-container *ngIf="{stats: menuStats$ | async, menu: menu$ | async} as menuObject">
                                    <ng-container *ngFor="let m of menuObject.menu; last as isLast" class="side-nav-item">
                                        <ng-container *ngIf="m.name === 'separator'">
                                            <hr class="my-1">
                                        </ng-container>
                                        <li id="li-menu-{{m.menu_item_ID}}" *ngIf="m.name !== 'separator'">
                                            <a [class.side-nav-link-active]="submenuCollapsed[m.routerLink] || activeUrl == m.routerLink"
                                               aria-controls="sidebarDashboards" aria-expanded="false" class="side-nav-link"
                                               data-bs-toggle="collapse" role="button">
                                                <i *ngIf="m.icon" (click)="redirect(m, true, true)" class="{{ m.icon + ' font-20 mr-3' }}">
                                                    <span *ngIf="menuObject.stats && menuObject.stats[m.name] === 'badge'"
                                                          class="entity-indicator-dot badge-primary"></span>
                                                </i>
                                                <span (click)="redirect(m, true, true)" class="menu-name">
                                                    <span>{{ m.name | translate }}</span>
                                                    <span *ngIf="menuObject.stats && menuObject.stats[m.name] === 'badge'"
                                                          class="entity-indicator-dot badge-primary mr-3"></span>
                                                </span>
                                                <span *ngIf="m.submenu" (click)="redirect(m, false)" [class.menu-arrow-rotate]="submenuCollapsed[m.routerLink]" class="menu-arrow"></span>
                                            </a>
                                            <div [(ngbCollapse)]="!submenuCollapsed[m.routerLink]"
                                                 class="submenu-collapse collapse show" id="sidebarDashboards">
                                                <ul class="side-nav-second-level">
                                                    <li *ngFor="let s of m.submenu">
                                                        <a (click)="clickMenuSubitem(s)" [class.dropdown-item-active]="activeUrl == s.routerLink" [routerLink]="s.routerLink"
                                                           class="dropdown-item submenu-text">
                                                            <span>
                                                                <span>{{ s.name | translate }}</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ng-container>
                            </ul>
                            <!--- End Sidemenu -->
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="simplebar-placeholder" style="width: auto; height: 1704px;"></div>
        </div>
        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
            <div class="simplebar-scrollbar" style="width: 0; display: none;"></div>
        </div>
        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
            <div class="simplebar-scrollbar" style="height: 121px; transform: translate3d(0px, 0px, 0px); display: block;"></div>
        </div>
    </div>
</div>
