<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
            <span *ngIf="companyEmployeePolicy">{{ 'company_employee_policies.page_title_edit' | translate }}</span>
            <span *ngIf="!companyEmployeePolicy">{{ 'company_employee_policies.page_title_create' | translate }}</span>
        </p>

        <button
            aria-label="Close"
            class="close"
            (click)="activeModal.dismiss()"
        >
      <span
          aria-hidden="true"
          class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form
                            autocomplete="off"
                            [formGroup]="policyEditForm"
                            (ngSubmit)="onSubmit()"
                        >
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_employee_policies.name' | translate }}</span>
                                        </label>

                                        <input
                                            class="form-control"
                                            formControlName="name"
                                            name="name"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.name.errors">
                                            <p
                                                *ngIf="f.name.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'company_employee_policies.name' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_employee_policies.sick_days' | translate }}</span>
                                        </label>

                                        <input
                                            class="form-control"
                                            formControlName="sick_day_hours"
                                            name="sick_day_hours"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.sick_day_hours.errors">
                                            <p
                                                *ngIf="f.sick_day_hours.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'company_employee_policies.sick_days' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_employee_policies.sick_days_hours_timeframe' | translate }}</span>
                                        </label>

                                        <ng-select
                                            class="custom"
                                            [bindLabel]="'label'"
                                            [bindValue]="'value'"
                                            formControlName="sick_day_hours_timeframe"
                                            name="sick_day_hours_timeframe"
                                            [clearable]="false"
                                            [items]="timeframes$ | async"
                                        >
                                        </ng-select>

                                        <div *ngIf="submitted && f.home_office_hours.errors">
                                            <p
                                                *ngIf="f.home_office_hours.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'company_employee_policies.home_office_days' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="control-label" for="sick_day_forbid_exceeded">
                                            <span>{{ 'employees_datetimes_types.forbid_exceeded' | translate }}</span>
                                            <span class="ml-2 mdi mdi-information text-primary"
                                                  [ngbTooltip]="('employees_datetimes_types.forbid_exceeded_description' | translate)"
                                                  [placement]="'bottom-start'"></span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-6 text-right">
                                    <div>
                                        <input data-switch="success" formControlName="sick_day_forbid_exceeded" id="sick_day_forbid_exceeded" name="sick_day_forbid_exceeded" type="checkbox">
                                        <label for="sick_day_forbid_exceeded">&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_employee_policies.home_office_days' | translate }}</span>
                                        </label>

                                        <input
                                            class="form-control"
                                            formControlName="home_office_hours"
                                            name="home_office_hours"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.home_office_hours.errors">
                                            <p
                                                *ngIf="f.home_office_hours.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'company_employee_policies.home_office_days' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_employee_policies.home_office_days_hours_timeframe' | translate }}</span>
                                        </label>

                                        <ng-select
                                            class="custom"
                                            [bindLabel]="'label'"
                                            [bindValue]="'value'"
                                            formControlName="home_office_hours_timeframe"
                                            name="home_office_hours_timeframe"
                                            [clearable]="false"
                                            [items]="timeframes$ | async"
                                        >
                                        </ng-select>

                                        <div *ngIf="submitted && f.home_office_hours.errors">
                                            <p
                                                *ngIf="f.home_office_hours.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'company_employee_policies.home_office_days' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="control-label" for="home_office_forbid_exceeded">
                                            <span>{{ 'employees_datetimes_types.forbid_exceeded' | translate }}</span>
                                            <span class="ml-2 mdi mdi-information text-primary"
                                                  [ngbTooltip]="('employees_datetimes_types.forbid_exceeded_description' | translate)"
                                                  [placement]="'bottom-start'"></span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-6 text-right">
                                    <div>
                                        <input data-switch="success" formControlName="home_office_forbid_exceeded" id="home_office_forbid_exceeded" name="home_office_forbid_exceeded" type="checkbox">
                                        <label for="home_office_forbid_exceeded">&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_employee_policies.holiday_days' | translate }}</span>
                                        </label>

                                        <input
                                            class="form-control"
                                            formControlName="holiday_hours"
                                            name="holiday_hours"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.holiday_hours.errors">
                                            <p
                                                *ngIf="f.holiday_hours.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'company_employee_policies.holiday_days' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_employee_policies.holiday_transfer_days' | translate }}</span>
                                        </label>

                                        <input
                                            class="form-control"
                                            formControlName="holiday_transfer_hours"
                                            name="holiday_transfer_hours"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.holiday_transfer_hours.errors">
                                            <p
                                                *ngIf="f.holiday_transfer_hours.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'company_employee_policies.holiday_transfer_days' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="control-label" for="holiday_forbid_exceeded">
                                            <span>{{ 'employees_datetimes_types.forbid_exceeded' | translate }}</span>
                                            <span class="ml-2 mdi mdi-information text-primary"
                                                  [ngbTooltip]="('employees_datetimes_types.forbid_exceeded_description' | translate)"
                                                  [placement]="'bottom-start'"></span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-6 text-right">
                                    <div>
                                        <input data-switch="success" formControlName="holiday_forbid_exceeded" id="holiday_forbid_exceeded" name="holiday_forbid_exceeded" type="checkbox">
                                        <label for="holiday_forbid_exceeded">&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_employee_policies.legal_pause' | translate }}</span>
                                        </label>

                                        <input
                                            class="form-control"
                                            formControlName="legal_pause"
                                            name="legal_pause"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.legal_pause.errors">
                                            <p
                                                *ngIf="f.legal_pause.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'company_employee_policies.legal_pause' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_employee_policies.legal_pause_treshold' | translate }}</span>
                                        </label>

                                        <input
                                            class="form-control"
                                            formControlName="legal_pause_treshold"
                                            name="legal_pause_treshold"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.legal_pause_treshold.errors">
                                            <p
                                                *ngIf="f.legal_pause_treshold.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'company_employee_policies.legal_pause_treshold' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <hr>

                            <!--<div class="row">
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <label class="control-label">
                                    <span>{{ 'company_employee_policies.holiday_work' | translate }}</span>
                                  </label>

                                  <div>
                                    <input
                                      data-switch="success"
                                      formControlName="holiday_work"
                                      id="holiday_work"
                                      name="holiday_work"
                                      type="checkbox"
                                    >

                                    <label for="holiday_work">&nbsp;</label>
                                  </div>
                                </div>
                              </div>
                            </div>-->

                            <!--<div class="row">
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <label class="control-label">
                                    <span>{{ 'company_employee_policies.weekend_work' | translate }}</span>
                                  </label>

                                  <div>
                                    <input
                                      data-switch="success"
                                      formControlName="weekend_work"
                                      id="weekend_work"
                                      name="weekend_work"
                                      type="checkbox"
                                    >

                                    <label for="weekend_work">&nbsp;</label>
                                  </div>
                                </div>
                              </div>
                            </div>-->

                            <div class="row mt-2">
                                <div class="col d-flex justify-content-end">
                                    <button *ngIf="companyEmployeePolicy" class="btn btn-link text-muted pr-0 mr-4" type="button" (click)="deletePolicy()">
                                        <u>{{ 'company_employee_policies.action_delete' | translate }}</u>
                                    </button>
                                    <button class="btn btn-success">
                                        <span>{{ 'company_employee_policies.action_save' | translate }}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
