import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { passwordMatchValidator } from 'src/app/core/validators/password-match.validator';
import { SignaturePadModalComponent } from 'src/app/document/components/partials/signature-pad-modal/signature-pad-modal.component';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { Role } from 'src/app/setting/models/Role';
import { BranchStoreService } from 'src/app/setting/services/branch-store.service';
import { RolesService } from 'src/app/setting/services/roles.service';
import { AppService } from 'src/app/shared/services/app.service';
import { WindowRef } from 'src/app/ui/window';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { IntegrationService } from '../../../../core/services/integration.service';
import { KioskService } from '../../../../kiosk/state/kiosk.service';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { SecuritySetting } from '../../../../setting/models/SecuritySetting';
import { CompanyService } from '../../../../setting/services/company.service';
import { SubscriptionService } from '../../../../setting/services/subscription.service';
import { AvailableFeatures } from '../../../models/AvailableFeatures';
import { TwoFactorSecret } from '../../../models/TwoFactorSecret';

@Component({
    selector: 'app-employee-setting',
    templateUrl: './employee-setting.component.html',
    styleUrls: ['./employee-setting.component.css']
})
export class EmployeeSettingComponent implements OnInit {
    @ViewChildren('inputField') inputFields: QueryList<ElementRef>;

    public availableLocales$: Observable<Array<string>>;
    public employee$: Observable<Employee>;
    public passwordEditForm: UntypedFormGroup;
    public salutationForm: UntypedFormGroup;
    public helpEditForm: UntypedFormGroup;
    public localeEditForm: UntypedFormGroup;
    public twoFactorForm: UntypedFormGroup;
    public roleForm: UntypedFormGroup;
    public activeUserForm: UntypedFormGroup;
    public branchForm: UntypedFormGroup;
    public contactListForm: UntypedFormGroup;
    public submitted = false;
    public userID$: Observable<number>;
    public employeeRoles$: Observable<Array<Role>>;
    public canEditRole: boolean;
    public canEditBranch: boolean;
    public canEditAccess: boolean;
    public canEditTwoFactor: boolean;
    public twoFactorSecretKey$: Observable<TwoFactorSecret>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public availableFeatures$: Observable<AvailableFeatures>;
    public activeIntegrations$: Observable<string[]>;
    public securitySetting$: Observable<SecuritySetting>;
    public hasTwoFactorActive = false;
    public loadingTwoFactor = false;
    public signaturesAllowed = false;
    public watchingMyself = false;
    public twoFactorCollapsed = true;
    public showPasswordReset = false;
    public twoFactorSecretKey: string;
    public permissions: Array<string>;
    public showKioskCode = false;
    public loggedInUser = this._authService.loggedUser;
    public certificateFormData: FormData;
    public employeeID: number;

    private _employeeID$: Observable<number>;
    private _userID$ = new ReplaySubject<number>(1);

    protected readonly Array = Array;

    public constructor(
        public branchStoreService: BranchStoreService,
        private _appService: AppService,
        private _authService: AuthenticationService,
        private _companyService: CompanyService,
        private _employeeService: EmployeeService,
        private _subscriptionService: SubscriptionService,
        private _integrationService: IntegrationService,
        private _fb: UntypedFormBuilder,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _windowRef: WindowRef,
        private _roleService: RolesService,
        private _kioskService: KioskService,
        private _cdr: ChangeDetectorRef
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.passwordEditForm.controls;
    }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;
        this.availableFeatures$ = this._subscriptionService.availableFeatures$;
        this.activeIntegrations$ = this._integrationService.getActiveIntegrations();

        this.permissions = this._authService?.permissions?.map(perm => perm.name);

        this.canEditRole = this._authService.checkPermission('role.edit');
        this.canEditBranch = this._authService.checkPermission('companyBranch.edit');
        this.canEditAccess = this._authService.checkPermission('employeeAccess.edit');
        this.canEditTwoFactor = this._authService.checkPermission('security.edit');

        this.availableLocales$ = this._appService.getAvailableLanguages();

        this.localeEditForm = this._fb.group({
            locale: ['cs']
        });

        this.twoFactorForm = this._fb.group({
            enabled: [null],
            two_factor_code: [null]
        });

        this.passwordEditForm = this._fb.group({
            password: ['', [Validators.required, Validators.minLength(8)]],
            passwordConfirm: ['', [Validators.required, Validators.minLength(8)]]
        }, {validators: passwordMatchValidator});

        this.roleForm = this._fb.group({
            role: ['', []],
        });

        this.activeUserForm = this._fb.group({
            activeUser: ['', []],
        });

        this.branchForm = this._fb.group({
            branch: ['', []],
        });

        this.salutationForm = this._fb.group({
            salutation: ['', []],
        });

        this.helpEditForm = this._fb.group({
            help_mode: [false, Validators.required]
        });

        this.contactListForm = this._fb.group({
            contact_list: [false, Validators.required]
        });

        this.certificateFormData = new FormData();

        this._employeeID$ = this._route.parent.params
            .pipe(
                map(params => parseInt(params.id, 10)),
                shareReplay()
            );

        this.refetchSettings();

        this.securitySetting$ = this._companyService.getSecuritySetting();

        this._companyService.getCompanySetting('documents.allow_signature').subscribe((settings) => {
            this.signaturesAllowed = settings?.allow_signature === '1';
        });

        this.employeeRoles$ = this._roleService.getAllRoles();

        this.userID$ = this._userID$;
    }

    public refetchSettings(): void {
        this._employeeID$.subscribe((employeeID) => {
            this.employeeID = employeeID;
            this.employee$ = this._employeeService.getEmployeeInfoByEmployeeID(employeeID, 'edit')
                .pipe(
                    tap(employee => {
                        this.watchingMyself = this.loggedInUser?.employee?.employee_ID === employee?.employee_ID;
                        if (!this.watchingMyself) {
                            this.salutationForm.disable();
                        }
                        if (!this.canEditBranch) {
                            this.branchForm.disable();
                        }
                        if (!this.canEditRole) {
                            this.roleForm.disable();
                        }
                        if (employee.permissions?.edit) {
                            this.showPasswordReset = true;
                        }
                        this._cdr.detectChanges();
                    }),
                    tap(employee => {
                        this.hasTwoFactorActive = employee.two_factor_active;
                        this._userID$.next(employee.user_ID);
                        this.helpEditForm.patchValue({help_mode: employee.help_mode});
                        this.salutationForm.patchValue({salutation: employee.salutation || employee.name});
                        this.localeEditForm.patchValue({locale: employee.locale || 'cs'});
                        this.activeUserForm.patchValue({activeUser: employee.is_active});
                        this.branchForm.patchValue({branch: employee.company_branch_ID === null ? 0 : employee.company_branch_ID});
                        this.roleForm.patchValue({role: employee.role});
                        this.contactListForm.patchValue({contact_list: employee.show_in_contacts});
                    }),
                    shareReplay()
                );
        });

        this.employee$.subscribe();
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.passwordEditForm.valid) {
            return;
        }

        void this._employeeService.savePasswordByEmployeeID(this.f.password.value, this.f.passwordConfirm.value, this.employeeID);

        this.passwordEditForm.patchValue({password: '', passwordConfirm: ''});

        this.submitted = false;
    }

    public onSubmitSalutation(): void {
        this._employeeService.saveSalutation(this.salutationForm.controls.salutation.value, this.employeeID)
            .catch(() => {
            });
    }

    public onSubmitHelp(): void {
        this._employeeService.setHelpMode(this.helpEditForm.controls.help_mode.value, this.employeeID)
            .catch(() => {
            });
    }

    public onSubmitContactList(): void {
        this._employeeService.saveMeta(this.employeeID, 'show_in_contacts', this.contactListForm.controls.contact_list.value === true ? '1' : '0');
    }

    public openSignaturePadSettingModal(): void {
        const modalRef = this._modalService.open(SignaturePadModalComponent, {centered: true});

        modalRef.componentInstance.showSaveSignatureControl = false;

        modalRef.componentInstance.mode = 'SETTING';

        modalRef.result
            .then(
                signature => {
                    this._employeeService.saveSignature(this.employeeID, signature)
                        .then(() => this.refetchSettings());
                },
                () => {
                }
            );
    }

    public onSubmitSetLocale(): void {
        this._employeeService.setLocale(this.localeEditForm.controls.locale.value, this.employeeID)
            .then(() => this._windowRef.nativeWindow.location.reload())
            .catch(() => {
            });
    }

    public onSubmitTwoFactor(): void {
        const value = this.twoFactorForm.value;
        this.loadingTwoFactor = true;
        this.userID$
            .subscribe(userID => {
                this._authService.activateTwoFactor(userID, this.twoFactorSecretKey, value.two_factor_code)
                    .then(() => {
                        this.loadingTwoFactor = false;
                        this.refetchSettings();
                    }, () => {
                        this.loadingTwoFactor = false;
                    });
            });
    }

    public deactivateTwoFactor(): void {
        this.userID$
            .subscribe(userID => {
                this._authService.deactivateTwoFactor(userID, {})
                    .then(() => {
                        this.twoFactorForm.patchValue({
                            enabled: false,
                            two_factor_code: ''
                        });
                        this.twoFactorCollapsed = true;
                        this.refetchSettings();
                    })
                    .catch(() => {
                    });
            });
    }

    public enableTwoFactor(): void {
        this.userID$
            .subscribe(userID => {
                this.twoFactorCollapsed = !this.twoFactorCollapsed;
                this.twoFactorSecretKey$ = this._authService.getTwoFactorSecretKey(userID)
                    .pipe(map(secret => {
                        const firstInput: HTMLInputElement = this.inputFields.first.nativeElement;
                        firstInput.focus();
                        this.twoFactorSecretKey = secret.secret_key;
                        return secret;
                    }));
            });
    }

    public checkCount(event: string): void {
        const length = event.length;
        if (length === 6) {
            this.inputFields.forEach(el => {
                el.nativeElement.blur();
            });
            this.onSubmitTwoFactor();
        }
    }

    public onSubmitActiveUser(): void {
        this._employeeService.setActiveUser(this.activeUserForm.controls.activeUser.value, this.employeeID)
            .catch(() => {
            });
    }

    public onSubmitRole(): void {
        this._employeeService.setRole(this.roleForm.controls.role.value, this.employeeID)
            .catch(() => {
            });
    }

    public onSubmitBranch(): void {
        let value = this.branchForm.value.branch;
        if (this.branchForm.value.branch === 0) {
            value = null;
        }
        this._employeeService.setBranch(value, this.employeeID)
            .catch(() => {
            });
    }

    public checkPermission(name: string): boolean {
        return (this.watchingMyself || this.permissions.includes(name + '.edit') || this.permissions.includes(name + '.editStructure'));
    }

    public toggleKioskCode(): void {
        this.showKioskCode = !this.showKioskCode;
    }

    public generateKioskLoginCode(employee: Employee): void {
        this._kioskService.generateKioskLoginCode(employee.employee_ID)
            .then((response) => {
                employee.login_code = response.login_code;
            });
    }

    public onUpload(files: FileList, formControlName: string): void {
        if (files.length > 0) {
            this.certificateFormData.append(formControlName, files[0]);
        }
    }

    public onSubmitCertificate(passwordInputValue: string): void {
        this.certificateFormData.delete('private_key_password');
        this.certificateFormData.append('private_key_password', passwordInputValue);

        this._employeeService.setSignatureCertificate(this.certificateFormData, this.employeeID)
            .then(() => {
                this.refetchSettings();
            });
    }

    public deleteCertificate(): void {
        this._employeeService.deleteCertificate(this.employeeID)
            .then(() => {
                this.refetchSettings();
            });
    }

    public resetPassword(): void {
        this._employeeService.resetEmployeePassword(this.employeeID);
    }

    public sendInvitation(employee: Employee): void {
        this._subscriptionService.sendInvite({
            email: employee.email,
            id: employee.user_ID
        }).then(() => { },
            () => { });
    }
}
