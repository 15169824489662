<ng-template
  #helpModal
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ helpData?.name }}</span>
      </p>

      <button
        aria-label="Close"
        class="close"
        (click)="modal.dismiss()"
      >
        <span
          class="font-24 text-muted"
          aria-hidden="true"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0" >
              <div class="description" [innerHtml]="helpData?.description | safeHtml" style="font-weight: 700">

              </div>
              <div class="content" [innerHtml]="helpData?.content | safeHtml">

              </div>
                <div class="text-center mt-3" *ngIf="helpData?.url">
                    <a target="_blank" href="{{helpData?.url}}" class="btn btn-primary">
                        {{ 'help.url_action' | translate }}
                    </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Begin page -->
<div class="wrapper">
  <app-navbar [refreshData]="refreshLoggedUser"></app-navbar>
  <!-- ============================================================== -->
  <!-- Start Page Content here -->
  <!-- ============================================================== -->
  <div class="content-page">
    <div class="content">

      <!-- Start Content-->
      <div class="container-fluid px-1 px-sm-3">
        <!-- start page title -->
        <div class="row">
          <div class="col-12">
            <div class="page-title-box px-2 px-sm-0">
              <h4 class="page-title notranslate text-dark">{{ pageTitle$ | async }}</h4>
            </div>
          </div>
        </div> <!-- end page title -->

        <!-- help alert -->
        <div *ngIf="helpData" class="alert alert-white w-100 p-0 mb-3 shadow"
        >
          <div class="bg-gradient px-3 py-2 w-100 d-flex align-items-center">
            <p class="mb-0">
                <span *ngIf="helpData.tooltip_text">
                    {{ helpData.tooltip_text }}
                </span>
              <a class="cursor-pointer" (click)="openHelpModal(helpModal)">
                <u>{{ 'help.help_alert_action' | translate }}</u>
              </a>.
            </p>
            <div class="ml-auto">
              <a class="cursor-pointer" (click)="openHelpModal(helpModal)">
                <i class="mdi mdi-help-circle text-primary mdi-24px help-icon"></i>
              </a>
            </div>
          </div>
        </div>



        <router-outlet></router-outlet>
      </div> <!-- container -->
    </div> <!-- content -->
    <div class="welcome-modal-container"></div>

    <app-footer></app-footer>
  </div>
  <!-- ============================================================== -->
  <!-- End Page content -->
  <!-- ============================================================== -->
</div>
<!-- END wrapper -->
