<!--<editor-fold desc="Modaly">-->
<ng-template #content let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'employees_datetimes.document_monthly_attendance' | translate }} </span>
                <span>{{ year }}</span>
                <span>/</span>
                <span>{{ month }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
                type="button"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-0 shadow-none">
                        <div
                            *ngIf="selectedSummary"
                            class="card-body p-0"
                        >
                            <div class="row border-bottom text-nowrap">
                                <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                                    <span class="text-dark">{{ 'employees.full_name' | translate }}</span>
                                </div>

                                <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                                    <span>{{ selectedSummary.fullname }}</span>
                                </div>
                            </div>

                            <div class="row border-bottom text-nowrap">
                                <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                                    <span class="text-dark">{{ 'employees_datetimes.document_gross_salary' | translate }}</span>
                                </div>

                                <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                                    <span>{{ selectedSummary.gross_salary | localizedCurrency }}</span>
                                </div>
                            </div>

                            <div class="row border-bottom text-nowrap">
                                <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                                    <span class="text-dark">{{ 'employees_datetimes.document_net_salary' | translate }}</span>
                                </div>

                                <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                                    <span>{{ selectedSummary.net_salary | localizedCurrency }}</span>
                                </div>
                            </div>
                        </div>

                        <div
                            *ngIf="!selectedSummary"
                            class="card-body p-0"
                        >
                            <div class="row border-bottom text-nowrap">
                                <div class="col-12 col-sm-12 col-md-12 py-1 py-md-2">
                                    <span class="text-dark">{{ 'employees_datetimes.document_not_calculated' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template
    #filterFormOptions
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'employees.adjust_filters' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form
                (ngSubmit)="onSubmitFilter()"
                [formGroup]="filterForm"
                class="filter-form"
            >
                <fieldset>
                    <legend>{{ 'employees.filter_section_company_groups' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_company_groups' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'label'"
                                    [bindValue]="'company_group_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="companyGroups$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="companyGroups"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_company_groups_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('companyGroups') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.name }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'employees.filter_section_employee_branch' | translate }}</legend>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_company_branch' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'name'"
                                    [bindValue]="'company_branch_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="companyBranches$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="mainCompanyBranches"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_company_branch_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('mainCompanyBranches') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                        <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                                        <span>&nbsp;</span>
                                        <span>{{ item.name }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'employees.filter_section_employee_position' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_employee_position_types' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'name'"
                                    [bindValue]="'name'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="employeePositionTypes$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="employeePositionTypes"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_employee_position_types_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('employeePositionTypes') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                        <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                                        <span>&nbsp;</span>
                                        <span>{{ 'employees_positions.' + item.name | translate }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_position_company_branch' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'name'"
                                    [bindValue]="'company_branch_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="companyBranches$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="positionCompanyBranches"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_position_company_branch_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('positionCompanyBranches') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                        <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                                        <span>&nbsp;</span>
                                        <span>{{ item.name }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'employees.filter_section_tags' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_tags' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'label'"
                                    [bindValue]="'tag_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="tags$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="tags"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_tags_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('tags') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.label }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'employees.filter_section_skills' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_hard_skills' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'label'"
                                    [bindValue]="'tag_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="hardSkillsTags$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="hardSkillsTags"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_hard_skills_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('hardSkillsTags') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.label }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_soft_skills' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'label'"
                                    [bindValue]="'tag_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="softSkillsTags$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="softSkillsTags"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_soft_skills_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('softSkillsTags') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.label }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'employees.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!--</editor-fold desc="Modaly">-->

<!-- DEAKTIVACE
<div
  *ngIf="datetimeDocumentStatus$ | async as status"
  class="row"
>
  <div class="col-lg-6 mb-3">
    <div class="card h-100 shadow">
      <app-help name="employee.attendance.document_detail"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-6">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'employees_datetimes.document_monthly_attendance' | translate }} </span>
              <span>{{ year }}</span>
              <span>/</span>
              <span>{{ month }}</span>
            </p>
          </div>

          <div class="text-right col-6">
            <button
              *ngIf="status.step1"
              class="btn btn-primary d-none d-sm-inline-block"
              (click)="destroyBatch()"
            >{{ 'employees_datetimes.document_start_over' | translate }}</button>

            <button
              *ngIf="status.step1"
              class="btn btn-primary btn-icon d-inline-block d-sm-none font-18"
              (click)="destroyBatch()"
            >
              <i class="mdi mdi-pencil-outline"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="card-body px-4">
        <div class="row border-bottom text-nowrap">
          <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
            <span class="text-dark">{{ 'employees_datetimes.document_gross_salary_summary' | translate }}</span>
          </div>

          <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
            <span *ngIf="grossSalarySummary">{{ grossSalarySummary | localizedCurrency }}</span>
            <span *ngIf="!grossSalarySummary">---</span>
          </div>
        </div>

        <div class="row border-bottom text-nowrap">
          <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
            <span class="text-dark">{{ 'employees_datetimes.document_gross_salary_average' | translate }}</span>
          </div>

          <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
            <span *ngIf="averageGrossSalary">{{ averageGrossSalary | localizedCurrency }}</span>
            <span *ngIf="!averageGrossSalary">---</span>
          </div>
        </div>

        <div class="row border-bottom text-nowrap">
          <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
            <span class="text-dark">
              <span>{{ 'employees_datetimes.document_gross_salary_average_hourly_rate' | translate }}</span>
            </span>
          </div>

          <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
            <span *ngIf="averageHourlyGrossSalary">
              <span>{{ averageHourlyGrossSalary | localizedCurrency}}</span>
            </span>
            <span *ngIf="!averageHourlyGrossSalary">---</span>
          </div>
        </div>

        <div
          *ngIf="status.step1"
          class="row border-bottom text-nowrap"
        >
          <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
            <span class="text-dark">{{ 'employees_datetimes.document_worked_hours_average' | translate }}</span>
          </div>

          <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
            <span>{{ averageWorkedHours | number: '1.0-2' }}</span>
          </div>
        </div>

        <p class="mt-3 accordion font-16 text-dark pt-2 pb-2">
          <span>{{ 'employees_datetimes.document_history' | translate }}</span>
        </p>

        <div
          *ngFor="let s of status.activities"
          class="row"
        >
          <div class="col-12 col-md-3">
            <small class="text-dark">{{ s?.created_at | date: 'dd.MM.yyyy' }}</small>
          </div>

          <div class="col-12 col-md-9">
            <small>{{ s?.description }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6">
    <div class="row">
      <div class="col-lg-12">
        <div class="card shadow">
          <div class="card-header border-bottom-0 py-3">
            <div class="row">
              <div class="col-6 col-sm-6 col-md-7 text-nowrap">
                <p class="modal-title font-16 pt-2 pb-2">
                  <i
                    class="mdi mdi-check mdi-24px mr-2"
                    [ngClass]="{ 'text-light':  !status.step1, 'text-green': status.step1 }"
                  ></i>

                  <span class="text-dark">{{ 'employees_datetimes.document_normalized_data_check' | translate }}</span>
                </p>
              </div>

              <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                <a
                  class="btn btn-primary d-none d-sm-inline-block"
                  [queryParams]="{ month: month, year: year }"
                  [routerLink]="['/employee', 'attendance', 'normalized-check']"
                >{{ 'employees_datetimes.action_check' | translate }}</a>

                <a
                  class="btn btn-primary btn-icon d-inline-block d-sm-none font-18"
                  [queryParams]="{ month: month, year: year }"
                  [routerLink]="['/employee', 'attendance', 'normalized-check']"
                >
                  <i class="mdi mdi-pencil-outline"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card shadow">
          <div class="card-header border-bottom-0 py-3">
            <div class="row">
              <div class="col-6 col-sm-6 col-md-7 text-nowrap">
                <p class="modal-title font-16 pt-2 pb-2">
                  <i
                    class="mdi mdi-check mdi-24px mr-2"
                    [ngClass]="{ 'text-light':  !status.step2, 'text-green': status.step2 }"
                  ></i>

                  <span class="text-dark">{{ 'employees_datetimes.document_hourly_rate_check' | translate }}</span>
                </p>
              </div>

              <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                <a
                  *ngIf="status.step1"
                  class="btn btn-primary d-none d-sm-inline-block"
                  [queryParams]="{ month: month, year: year }"
                  [routerLink]="['/employee', 'attendance', 'hour-gross-count']"
                >{{ 'employees_datetimes.action_check' | translate }}</a>

                <a
                  *ngIf="status.step1"
                  class="btn btn-primary btn-icon d-inline-block d-sm-none font-18"
                  [queryParams]="{ month: month, year: year }"
                  [routerLink]="['/employee', 'attendance', 'hour-gross-count']"
                >
                  <i class="mdi mdi-pencil-outline"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card shadow">
          <div class="card-header border-bottom-0 py-3">
            <div class="row">
              <div class="col-6 col-sm-6 col-md-7 text-nowrap">
                <p class="modal-title font-16 pt-2 pb-2">
                  <i
                    class="mdi mdi-check mdi-24px mr-2"
                    [ngClass]="{ 'text-light':  !status.step3, 'text-green': status.step3}"
                  ></i>

                  <span class="text-dark">{{ 'employees_datetimes.document_gross_salary_check' | translate }}</span>
                </p>
              </div>

              <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                <a
                  *ngIf="status.step2"
                  class="btn btn-primary d-none d-sm-inline-block"
                  [queryParams]="{ month: month, year: year }"
                  [routerLink]="['/employee', 'attendance', 'gross-salary-count']"
                >{{ 'employees_datetimes.action_check' | translate }}</a>

                <a
                  *ngIf="status.step2"
                  class="btn btn-primary btn-icon d-inline-block d-sm-none font-18"
                  [queryParams]="{ month: month, year: year }"
                  [routerLink]="['/employee', 'attendance', 'gross-salary-count']"
                >
                  <i class="mdi mdi-pencil-outline"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card shadow">
          <div class="card-header border-bottom-0 py-3">
            <div class="row">
              <div class="col-6 col-sm-6 col-md-7 text-nowrap">
                <p class="modal-title font-16 pt-2 pb-2">
                  <i
                    class="mdi mdi-check mdi-24px mr-2"
                    [ngClass]="{ 'text-light':  !status.step4, 'text-green': status.step4 }"
                  ></i>

                  <span class="text-dark">{{ 'employees_datetimes.document_net_salary_check' | translate }}</span>
                </p>
              </div>

              <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                <a
                  *ngIf="status.step3"
                  class="btn btn-primary d-none d-sm-inline-block"
                  [queryParams]="{ month: month, year: year }"
                  [routerLink]="['/employee', 'attendance', 'net-salary-count']"
                >{{ 'employees_datetimes.action_check' | translate }}</a>

                <a
                  *ngIf="status.step3"
                  class="btn btn-primary btn-icon d-inline-block d-sm-none font-18"
                  [queryParams]="{ month: month, year: year }"
                  [routerLink]="['/employee', 'attendance', 'net-salary-count']"
                >
                  <i class="mdi mdi-pencil-outline"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card shadow">
          <div class="card-header border-bottom-0 py-3">
            <div class="row">
              <div class="col-6 col-sm-6 col-md-7 text-nowrap">
                <p class="modal-title font-16 pt-2 pb-2">
                  <i
                    class="mdi mdi-check mdi-24px mr-2"
                    [ngClass]="{ 'text-light':  !status.step5, 'text-green': status.step5 }"
                  ></i>

                  <span class="text-dark">{{ 'employees_datetimes.document_net_salary_real_check' | translate }}</span>
                </p>
              </div>

              <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                <a
                  *ngIf="status.step4"
                  class="btn btn-primary d-none d-sm-inline-block"
                  [queryParams]="{ month: month, year: year }"
                  [routerLink]="['/employee', 'attendance', 'real-net-salary-count']"
                >{{ 'employees_datetimes.action_check' | translate }}</a>

                <a
                  *ngIf="status.step4"
                  class="btn btn-primary btn-icon d-inline-block d-sm-none font-18"
                  [queryParams]="{ month: month, year: year }"
                  [routerLink]="['/employee', 'attendance', 'real-net-salary-count']"
                >
                  <i class="mdi mdi-pencil-outline"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card shadow">
          <div class="card-header border-bottom-0 py-3">
            <div class="row">
              <div class="col-6 col-sm-6 col-md-7 text-nowrap">
                <p class="modal-title font-16 pt-2 pb-2">
                  <span class="text-dark">{{ 'employees_datetimes.document_payroll_generate' | translate }}</span>
                </p>
              </div>

              <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                <button
                  [breakpointInPx]="1160"
                  [generateButton]="'employees_datetimes.action_generate' | translate"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
-->

<div
    *ngIf="combined$ | async as data; else loadingTemplate"
    class="row"
>
    <div class="col-lg-6 mb-3">
        <div class="card h-100 shadow">
            <app-help name="attendance.documents.detail.info"></app-help>
            <div class="card-header py-3">
                <div class="row">
                    <div class="col-12">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ 'employees_datetimes.document_monthly_attendance' | translate }} </span>
                            <span>{{ year }}</span>
                            <span>/</span>
                            <span>{{ month }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="card-body px-4">
                <div class="row border-bottom text-nowrap">
                    <div class="col-12 col-md-6 col-lg-9 py-1 py-md-2">
                        <span class="text-dark">{{ 'employees_datetimes.document_gross_salary_summary' | translate }}</span>
                    </div>

                    <div class="col-12 col-md-6 col-lg-3 py-1 py-md-2">
                        <span *ngIf="grossSalarySummary">{{ grossSalarySummary | localizedCurrency }}</span>
                        <span *ngIf="!grossSalarySummary">---</span>
                    </div>
                </div>

                <div class="row border-bottom text-nowrap">
                    <div class="col-12 col-md-6 col-lg-9 py-1 py-md-2">
                        <span class="text-dark">{{ 'employees_datetimes.document_gross_salary_average' | translate }}</span>
                    </div>

                    <div class="col-12 col-md-6 col-lg-3 py-1 py-md-2">
                        <span *ngIf="averageGrossSalary">{{ averageGrossSalary | localizedCurrency }}</span>
                        <span *ngIf="!averageGrossSalary">---</span>
                    </div>
                </div>

                <div class="row border-bottom text-nowrap">
                    <div class="col-12 col-md-6 col-lg-9 py-1 py-md-2">
            <span class="text-dark">
              <span>{{ 'employees_datetimes.document_gross_salary_average_hourly_rate' | translate }}</span>
            </span>
                    </div>

                    <div class="col-12 col-md-6 col-lg-3 py-1 py-md-2">
            <span *ngIf="averageHourlyGrossSalary">
              <span>{{ averageHourlyGrossSalary | localizedCurrency}}</span>
            </span>
                        <span *ngIf="!averageHourlyGrossSalary">---</span>
                    </div>
                </div>

                <div
                    *ngIf="data.status.step1"
                    class="row border-bottom text-nowrap"
                >
                    <div class="col-12 col-md-6 col-lg-9 py-1 py-md-2">
                        <span class="text-dark">{{ 'employees_datetimes.document_worked_hours_average' | translate }}</span>
                    </div>

                    <div class="col-12 col-md-6 col-lg-3 py-1 py-md-2">
                        <span>{{ averageWorkedHours | number: '1.0-2' }}</span>
                    </div>
                </div>

                <p class="mt-3 accordion font-16 text-dark pt-2 pb-2">
                    <span>{{ 'employees_datetimes.document_history' | translate }}</span>
                </p>

                <div
                    *ngFor="let s of data.status.activities"
                    class="row"
                >
                    <div class="col-12 col-md-3">
                        <small class="text-dark">{{ s?.created_at | date: 'dd.MM.yyyy' }}</small>
                    </div>

                    <div class="col-12 col-md-9">
                        <small>{{ s?.description }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div *ngIf="data.stats" class="row">
            <div class="col-lg-6 mb-3">
                <div class="card h-100 mb-lg-0 shadow">
                    <app-help name="attendance.documents.detail.statistics"></app-help>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <h5 class="text-muted font-weight-normal mt-0 text-truncate">
                                    <span>{{ 'employees_datetimes.count_of_employees' | translate }}</span>
                                </h5>

                                <h3 class="my-2 py-1 text-dark">
                                    <span>{{ data.stats.number_of_employees }}</span>
                                </h3>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-12">
                                <h5 class="text-muted font-weight-normal mt-0 text-truncate">
                                    <span>{{ 'employees_datetimes.count_of_processed_employees' | translate }}</span>
                                </h5>

                                <h3 class="my-2 py-1 text-dark">
                                    <span>{{ data.stats.number_of_processed_employees }}</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 mb-3">
                <div class="card h-100 mb-lg-0 shadow">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <h5 class="text-muted font-weight-normal mt-0 text-truncate">
                                    <span>{{ 'employees_datetimes.total_working_hours' | translate }}</span>
                                </h5>

                                <h3 class="my-2 py-1 text-dark">
                                    <span>{{ data.stats.total_working_hours }}</span>
                                </h3>

                                <h5 class="text-muted font-weight-normal mt-0 text-truncate">
                                    <span>{{ 'employees_datetimes.average_working_hours' | translate }}</span>
                                </h5>

                                <h3 class="my-2 py-1 text-dark">
                                    <span>{{ data.stats.average_working_hours }}</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 mb-3">
                <div class="card h-100 mb-lg-0 shadow">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <h5 class="text-muted font-weight-normal mt-0 text-truncate">
                                    <span>{{ 'employees_datetimes.total_holiday_hours' | translate }}</span>
                                </h5>

                                <h3 class="my-2 py-1 text-dark">
                                    <span>{{ data.stats.total_holiday_hours }}</span>
                                </h3>

                                <h5 class="text-muted font-weight-normal mt-0 text-truncate">
                                    <span>{{ 'employees_datetimes.average_holiday_hours' | translate }}</span>
                                </h5>

                                <h3 class="my-2 py-1 text-dark">
                                    <span>{{ data.stats.average_holiday_hours }}</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 mb-3">
                <div class="card h-100 mb-lg-0 shadow">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <h5 class="text-muted font-weight-normal mt-0 text-truncate">
                                    <span>{{ 'employees_datetimes.total_sickness_hours' | translate }}</span>
                                </h5>

                                <h3 class="my-2 py-1 text-dark">
                                    <span>{{ data.stats.total_sickness_hours }}</span>
                                </h3>

                                <h5 class="text-muted font-weight-normal mt-0 text-truncate">
                                    <span>{{ 'employees_datetimes.average_sickness_hours' | translate }}</span>
                                </h5>

                                <h3 class="my-2 py-1 text-dark">
                                    <span>{{ data.stats.average_sickness_hours }}</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card shadow">
                    <app-help name="attendance.documents.detail.check"></app-help>
                    <div class="card-header border-bottom-0 py-3">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-7 text-nowrap">
                                <p class="modal-title font-16 pt-2 pb-2">
                                    <i
                                        [ngClass]="{ 'text-light':  !data.status.step1, 'text-green': data.status.step1 }"
                                        class="mdi mdi-check mdi-24px mr-2"
                                    ></i>

                                    <span class="text-dark">{{ 'employees_datetimes.document_normalized_data_check' | translate }}</span>
                                </p>
                            </div>

                            <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                                <a
                                    [queryParams]="{ month: month, year: year }"
                                    [routerLink]="['/employee', 'attendance', 'normalized-check']"
                                    class="btn btn-primary d-none d-sm-inline-block"
                                >{{ 'employees_datetimes.action_check' | translate }}</a>

                                <a
                                    [queryParams]="{ month: month, year: year }"
                                    [routerLink]="['/employee', 'attendance', 'normalized-check']"
                                    class="btn btn-primary btn-icon d-inline-block d-sm-none"
                                >
                                    <i class="mdi mdi-pencil-outline mdi-24px"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="data.status.step1" class="row">
            <div class="col-lg-12">
                <div class="card shadow">
                    <div class="card-header border-bottom-0 py-3">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-7 text-nowrap">
                                <p class="modal-title font-16 pt-2 pb-2">
                                    <span class="text-dark">{{ 'employees_datetimes.document_start_over' | translate }}</span>
                                </p>
                            </div>

                            <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                                <button
                                    (click)="destroyBatch()"
                                    *ngIf="data.status.step1"
                                    class="btn btn-danger d-none d-sm-inline-block"
                                >{{ 'employees_datetimes.document_start_over' | translate }}</button>

                                <button
                                    (click)="destroyBatch()"
                                    *ngIf="data.status.step1"
                                    class="btn btn-danger btn-icon d-inline-block d-sm-none"
                                >
                                    <i class="mdi mdi-pencil-outline mdi-24px"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="rows?.length; else loadingUsersTemplate" class="row">
    <div class="col-12">
        <div class="card shadow">
            <app-help name="attendance.documents.detail.list"></app-help>
            <div class="card-header py-3">
                <p class="modal-title font-16 text-dark pt-2 pb-2">
                    <span>{{ 'employees_datetimes.document_attendance_lists' | translate }}</span>
                </p>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive no-row-hover">
                            <app-data-table
                                (searchChanged)="searchChanged($event)"
                                (sortChanged)="sortChanged($event)"
                                (filteredRowsChanged)="filteredRowsChanged($event)"
                                [emitEvent]="true"
                                [columnsToFilterIn]="['fullname']"
                                [disableInPlaceFiltering]="false"
                                [disableInPlaceSorting]="false"
                                [loading]="loading"
                                [rows]="rows"
                                [search]="search"
                                [sort]="sort"
                                [trackByFn]="trackByFn"
                            >
                                <ng-template #headerButton>
                                    <a
                                        (click)="recalculateDatetimes()"
                                        class="btn btn-light btn-icon ml-sm-2"
                                        title="{{ 'employees_datetimes.recalculate_attendance' | translate }}"
                                    >
                                        <i class="mdi mdi-timer-refresh-outline mdi-24px text-muted"></i>
                                    </a>

                                    <button (click)="openFilterFormOptions()"
                                            [formGroup]="filterForm"
                                            class="btn btn-primary btn-icon ml-sm-2"
                                            type="button"
                                    >
                                        <i class="mdi mdi-filter-outline"></i>
                                    </button>

                                    <div class="d-inline-block" ngbDropdown>
                                        <button class="ml-sm-2 btn btn-icon btn-light downloadButton" id="dropdownBasic1" ngbDropdownToggle type="button"></button>
                                        <div aria-labelledby="dropdownBasic1" ngbDropdownMenu>
                                            <ng-container *ngIf="selectedEmployeeIDs?.length > 0">
                                                <button (click)="downloadAllExport(rows, 'excel')" ngbDropdownItem>{{ 'employees_datetimes.attendance_export.excel' | translate }}</button>
                                                <button (click)="downloadAllExport(rows, 'aggregated_excel')" ngbDropdownItem>{{ 'employees_datetimes.attendance_export.aggregated_excel' | translate }}</button>
                                                <button (click)="downloadAllExport(rows, 'premier')" ngbDropdownItem>{{ 'employees_datetimes.attendance_export.premier' | translate }}</button>
                                                <button (click)="downloadAllExport(rows, 'premier-absence')" ngbDropdownItem>{{ 'employees_datetimes.attendance_export.premier_absence' | translate }}</button>
                                                <button (click)="downloadAllExport(rows, 'pamica')" ngbDropdownItem>{{ 'employees_datetimes.attendance_export.pamica' | translate }}</button>
                                                <button (click)="downloadAllExport(rows, 'pdf')" ngbDropdownItem>{{ 'employees_datetimes.attendance_export.pdf' | translate }}</button>
                                            </ng-container>

                                            <ng-container *ngIf="selectedEmployeeIDs?.length === 0">
                                                <button ngbDropdownItem>{{ 'employees_datetimes.noone_is_selected' | translate }}</button>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-template>

                                <ng-template #headerRow>
                                    <th appDataTableHeaderCell="fullname">{{ 'employees.full_name' | translate }}</th>
                                    <th appDataTableHeaderCell="fullname">{{ 'employees_salaries.amount' | translate }}</th>
                                    <th appDataTableHeaderCell="worked_hours_this_month">
                                        {{ 'employees_datetimes.attendance_detail_list-at_work' | translate }} [{{ 'employees_datetimes.attendance_detail_list_unit_hour' | translate }}]
                                        <small class="d-block">{{ 'employees_datetimes.attendance_detail_list-at_work_description' | translate }}</small>
                                    </th>
                                    <th appDataTableHeaderCell="absence_hours">
                                        {{ 'employees_datetimes.attendance_detail_list-absence' |translate }} [{{ 'employees_datetimes.attendance_detail_list_unit_hour' | translate }}]
                                        <small class="d-block">{{ 'employees_datetimes.attendance_detail_list-absence_description' |translate }}</small>
                                    </th>
                                    <th appDataTableHeaderCell="overtime_hours">
                                        {{ 'employees_datetimes.attendance_detail_list-overtime' |translate }} [{{ 'employees_datetimes.attendance_detail_list_unit_hour' | translate }}]
                                        <small class="d-block">{{ 'employees_datetimes.attendance_detail_list-overtime_description' |translate }}</small>
                                    </th>
                                    <th
                                        [disableSort]="true"
                                        appDataTableHeaderCell
                                        class="text-right"
                                    >{{ 'accounts.table_action' | translate }}</th>
                                </ng-template>

                                <ng-template #dataRow let-row>
                                    <td appDataTableDataCell class="table-user">
                                        <app-employee-box-avatar [avatar]="row.avatar" [canView]="row.permissions.view" [employeeID]="row.employee_ID" [fullname]="row.fullname" [position]="row.position" [isArchived]="row.is_archived"></app-employee-box-avatar>
                                    </td>

                                    <td appDataTableDataCell>
                                        <div *ngIf="row.salaries">
                                            <div *ngFor="let salary of row.salaries" class="text-nowrap">
                                                <span>
                                                    {{salary.amount | localizedCurrency}}
                                                    /
                                                    {{'employees_salaries.frequency_'+salary.frequency | translate | lowercase}}
                                                </span>
                                            </div>
                                        </div>
                                    </td>

                                    <td appDataTableDataCell>
                                        <span class="value text-dark">
                                            <span>{{ row.at_work?.expected | number:'1.1-1':'cs'  }}</span>
                                            <span class="text-muted px-1">|</span>
                                            <span>{{ row.at_work.real | number:'1.1-1':'cs' }}</span>
                                            <span class="text-muted px-1">|</span>
                                            <span>{{ row.at_work.waiting | number:'1.1-1':'cs'  }}</span>
                                        </span>
                                    </td>

                                    <td appDataTableDataCell>
                                        <span class="value text-dark">
                                            <span>{{ row.absence.real | number:'1.1-1':'cs'  }}</span>
                                            <span class="text-muted px-1">|</span>
                                            <span>{{ row.absence.waiting | number:'1.1-1':'cs'  }}</span>
                                        </span>
                                    </td>

                                    <td appDataTableDataCell>
                                        <span class="value text-dark">
                                            <span>{{ row.overtime.real | number:'1.1-1':'cs'  }}</span>
                                            <span class="text-muted px-1">|</span>
                                            <span>{{ row.overtime.waiting | number:'1.1-1':'cs'  }}</span>
                                        </span>
                                    </td>

                                    <td appDataTableDataCell class="text-right">
                                        <div>
                                            <a [routerLink]="['/employee', 'attendance', 'documents', 'detail', 'employee', row.employee_ID ]" [queryParams]="{month: month, year: year}" class="btn btn-white btn-icon btn-sm mr-1" title="{{ 'employees.action_view' | translate }}">
                                                <span class="text-muted">
                                                  <i class="mdi mdi-eye-outline mdi-24px"></i>
                                                </span>
                                            </a>
                                            <button (click)="downloadExport(row.employee_ID)" class="btn btn-white btn-icon btn-sm mr-1" title="{{ 'employees.action_download_attendance' | translate }}">
                                                <span class="text-muted">
                                                  <i class="mdi mdi-tray-arrow-down mdi-24px"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </ng-template>
                            </app-data-table>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <app-pagination
                            (pageChanged)="pageChanged($event)"
                            [itemsPerPage]="itemsPerPage"
                            [page]="page"
                            [total]="itemsTotal"
                        ></app-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingTemplate>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="card shadow p-3">
                <div class="item">
                    <ngx-skeleton-loader [theme]="{
        'margin': '13px 0'
      }" appearance="line" count="10"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="row">
                <div class="col-6">
                    <div class="card shadow p-3">
                        <div class="item">
                            <ngx-skeleton-loader [theme]="{
        'margin': '14px 0'
      }" appearance="line" count="3"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card shadow p-3">
                        <div class="item">
                            <ngx-skeleton-loader [theme]="{
        'margin': '14px 0'
      }" appearance="line" count="3"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="card shadow p-3">
                        <div class="item">
                            <ngx-skeleton-loader [theme]="{
        'margin': '14px 0'
      }" appearance="line" count="3"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card shadow p-3">
                        <div class="item">
                            <ngx-skeleton-loader [theme]="{
        'margin': '14px 0'
      }" appearance="line" count="3"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card shadow p-3">
                        <div class="item">
                            <ngx-skeleton-loader [theme]="{
        'margin': '14px 0'
      }" appearance="line" count="1"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #loadingUsersTemplate>
    <div class="row">
        <div class="col-12">
            <div class="card shadow p-3">
                <div class="item">
                    <ngx-skeleton-loader [theme]="{
        'margin': '13px 0'
      }" appearance="line" count="10"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</ng-template>
