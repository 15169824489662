<div class="row">
    <div class="col-lg-12">
        <div class="form-group">
            <label class="control-label">{{'employees_positions.select_position' | translate}}</label>
            <ng-select #positionSelect [items]="companyPositions$ | async"
                       bindLabel="label"
                       bindValue="company_position_ID"
                       class="custom"
                       [clearable]="false"
                       [(ngModel)]="selectedCompanyPosition"
                       (change)="onCompanyPositionChange($event)"
                       [compareWith]="compareFn"
            >
            </ng-select>
        </div>
    </div>
</div>
<form [formGroup]="form" autocomplete="off">
    <ng-container [formGroupName]="'company_position'">
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="control-label">{{'employees_positions.name' | translate }}</label>
                    <input class="form-control" formControlName="name" name="name" type="text">
                    <div *ngIf="submitted && f.name?.errors">
                        <p *ngIf="f.name.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'employees_positions.name' | translate}}
                            {{'angular_validation.required' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="control-label">{{'employees_positions.type' | translate}}</label>
                    <ng-select [items]="positionsTypes"
                               bindLabel="label"
                               bindValue="value"
                               class="custom"
                               formControlName="type">
                    </ng-select>
                    <div *ngIf="submitted && f.type?.errors">
                        <p *ngIf="f.type.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'employees_positions.type' | translate}}
                            {{'angular_validation.required' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="control-label">{{'employees_positions.job_description' | translate }}</label>
                    <textarea class="form-control" formControlName="job_description" name="job_description" type="text"></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="control-label">{{'employees_positions.description' | translate }}</label>
                    <textarea class="form-control" formControlName="description" name="description" type="text"></textarea>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{'employees_positions.position_description' | translate }}</label>
                <textarea class="form-control" formControlName="position_description" name="position_description" type="text"></textarea>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label class="control-label">{{'employees_positions.from' | translate}}</label>
                <input
                    (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                    [locale]="locale$ | async"
                    allowInput="true"
                    altFormat="d.m.Y"
                    altInput="true"
                    class="form-control"
                    dateFormat="Y-m-d"
                    formControlName="from"
                    mwlFlatpickr
                    name="from"
                    type="text">
            </div>
            <div *ngIf="submitted && f.from.errors">
                <p *ngIf="f.from.errors.required" class="text-danger validation-text">
                    {{ 'angular_validation.field' | translate }} {{'employees_positions.from' | translate}}
                    {{'angular_validation.required' | translate }}
                </p>
            </div>
        </div>
        <div class="col-6 to-wrap">
            <div class="form-group">
                <label class="control-label">{{'employees_positions.to' | translate}}</label>
                <input (flatpickrClose)="fpHelper.applyAltInputValue($event, false)" [locale]="locale$ | async" allowInput="true"
                       altFormat="d.m.Y"
                       altInput="true"
                       class="form-control"
                       dateFormat="Y-m-d"
                       formControlName="to"
                       mwlFlatpickr name="to" type="text">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label mb-3">
                    {{'employees_positions.indefinitely' | translate}}
                </label>
                <div>
                    <input data-switch="success" formControlName="indefinitely" id="indefinitely" name="indefinitely" type="checkbox">
                    <label class="m-0" for="indefinitely">&nbsp;</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-6 test-time-wrap">
                    <div class="form-group">
                        <label class="control-label">{{'employees_positions.test_time_end' | translate}}</label>

                        <input
                            (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                            [locale]="locale$ | async"
                            allowInput="true"
                            altFormat="d.m.Y"
                            altInput="true"
                            class="form-control"
                            dateFormat="Y-m-d"
                            formControlName="test_time_end"
                            mwlFlatpickr
                            name="test_time_end"
                            type="text">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="control-label mb-3">
                            {{'employees_positions.no_test_time' | translate}}
                        </label>
                        <div>
                            <input data-switch="success" formControlName="no_test_time" id="no_test_time" name="no_test_time" type="checkbox">
                            <label class="m-0" for="no_test_time">&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="control-label">{{'employees.company_branch_ID' | translate}}</label>
                    <button *ngIf="canEdit" class="btn btn-link p-0 mb-2"
                            type="button"
                            (click)="openBranchEditModal()">{{'company_branches.action_create' | translate}}
                    </button>
                </div>
                <ng-select (change)="onBranchChange($event)" [class.is-invalid]="submitted && f.company_branch_ID.errors" [clearable]="false" class="custom" formControlName="company_branch_ID" id="company_branch_ID">
                    <ng-option [value]="0">{{ 'company_branches.no_branch' | translate }}</ng-option>
                    <ng-option *ngFor="let companyBranch of companyBranches$ | async" [value]="companyBranch.company_branch_ID">
                        {{companyBranch.name }}
                        <small class="text-muted d-block">{{ companyBranch.address }}</small>
                    </ng-option>
                </ng-select>
            </div>
        </div>
    </div>
    <div *ngIf="showSetMainBranch" class="row">
        <div class="col-9">
            <label class="control-label">{{ 'employees_positions.set_as_main_branch' | translate }}</label>
        </div>
        <div class="col-3 text-right align-self-center">
            <div>
                <input data-switch="success" formControlName="set_as_main_branch" id="set_as_main_branch" name="set_as_main_branch" type="checkbox">
                <label for="set_as_main_branch">&nbsp;</label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{'employees_positions.place_of_work' | translate }}</label>
                <input class="form-control" formControlName="place_of_work" name="place_of_work" type="text">
            </div>
        </div>
    </div>

    <h4 class="mb-3">
        {{'employees_positions.timetable' | translate}}
    </h4>

    <div [ngClass]="{'pointer-events-none': !canEdit}" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label mb-3">
                    {{'employees_positions.odd_even_week' | translate}}
                </label>
                <div>
                    <input data-switch="success" [checked]="oddEvenWeek" id="odd_even_week" (change)="oddEvenWeekChanged()" name="odd_even_week" type="checkbox">
                    <label class="m-0" for="odd_even_week">&nbsp;</label>
                </div>
            </div>
        </div>
    </div>
    <div [formGroupName]="'working_days'" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <div class="row">
                    <div class="col-6 form-group">
                        <label *ngIf="oddEvenWeek" class="control-label">
                            {{'employees_positions.odd_week' | translate}}
                        </label>
                            <table class="table-bordered-2px w-100">
                                <tbody>
                                    <tr *ngFor="let day of workDays; let i = index; let isLast = last">
                                        <td class="p-2">
                                            {{day.label}}
                                        </td>
                                        <td class="td-input-wrapper">
                                            <input (change)="onInputChange($event, day.value.toString())" class="form-control low invisible-border" [formControlName]="day.value" name="hours_per_day" min="0" max="24" type="number">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    </div>
                    <div *ngIf="oddEvenWeek" class="col-6 form-group">
                        <label class="control-label">
                            {{'employees_positions.even_week' | translate}}
                        </label>
                            <table class="table-bordered-2px w-100">
                                <tbody>
                                    <tr *ngFor="let day of workDays; let i = index; let isLast = last">
                                        <td class="p-2">
                                            {{day.label}}
                                        </td>
                                        <td class="td-input-wrapper">
                                            <input (change)="onInputChange($event, (day.value + 7).toString())" class="form-control low invisible-border" [formControlName]="day.value + 7" name="hours_per_day" min="0" max="24" type="number">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    </div>
                </div>
                <p class="text-muted">{{ 'employees_positions.put_time_without_law_break' | translate }}</p>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label mb-3">
                    {{'employees_positions.is_uneven' | translate}}
                </label>
                <div>
                    <input data-switch="success" formControlName="is_uneven" id="is_uneven" name="is_uneven" type="checkbox">
                    <label class="m-0" for="is_uneven">&nbsp;</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees_positions.flexible_work_hours' | translate }}</span>
                </label>
                <div>
                    <input
                        data-switch="success"
                        formControlName="flexible_work_hours"
                        id="flexible_work_hours"
                        name="flexible_work_hours"
                        type="checkbox"
                    >
                    <label for="flexible_work_hours">&nbsp;</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label class="control-label">{{'employees_positions.shift_start' | translate}}</label>
                <input [locale]="locale$ | async" [noCalendar]="true" allowInput="true"
                       (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                       altFormat="H:i"
                       altInput="true"
                       class="form-control"
                       dateFormat="H:i"
                       enableTime="true"
                       formControlName="shift_start"
                       mwlFlatpickr
                       name="shift_start"
                       time24hr="true"
                       type="text"
                >
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label class="control-label">{{'employees_positions.shift_end' | translate}}</label>
                <input [locale]="locale$ | async" [noCalendar]="true" allowInput="true"
                       (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                       altFormat="H:i"
                       altInput="true"
                       class="form-control"
                       dateFormat="H:i"
                       enableTime="true"
                       formControlName="shift_end"
                       mwlFlatpickr
                       name="shift_end"
                       time24hr="true"
                       type="text"
                >
            </div>
        </div>
    </div>

    <app-custom-fields [customFields]="employeePosition?.custom_fields"
                       [widget]="'EMPLOYEE_POSITION'"
                       [sensitive]="false"
                       [submitted]="submitted"
                       [fieldset]="false"
                       [hr]="true"
                       [columns]="1"
                       [form]="form">
    </app-custom-fields>

    <div *ngIf="employeePosition?.company_position?.company_position_ID" class="alert alert-secondary mt-2" role="alert">
        {{ 'employees_positions.edit_alert' | translate }}
    </div>
</form>
