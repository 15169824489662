<ng-container xmlns="http://www.w3.org/1999/html">
    <!--<editor-fold desc="MODALS">-->
    <ng-template
        #employeeTypeModal
        let-modal
    >
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-title font-16 mb-0 text-dark">
                    <span>{{ 'employees.change_type' | translate }}</span>
                </p>

                <button
                    (click)="modal.dismiss()"
                    aria-label="Close"
                    class="close"
                >
          <span
              aria-hidden="true"
              class="font-24 text-muted"
          >
            <i class="mdi mdi-close"></i>
          </span>
                </button>
            </div>

            <div class="modal-body">
                <form
                    (ngSubmit)="onEmployeeTypeSubmit()"
                    [formGroup]="employeeTypeForm"
                >

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.type' | translate }}</label>
                                <ng-select [class.is-invalid]="submitted && f.type?.errors" [clearable]="false" class="custom" formControlName="type">
                                    <ng-option *ngFor="let type of employeeTypes$ | async" [value]="type">
                                        {{ ('employees.type_' + type) | translate }}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="changeToCommon()" class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label class="control-label">
                                    <span>{{ 'employees.role' | translate }}</span>
                                </label>
                                <ng-select [items]="employeeRoles$ | async" bindLabel="name" bindValue="role_ID" class="custom" formControlName="role" placeholder="Vyberte roli"></ng-select>
                                <div *ngIf="submitted && f.role.errors">
                                    <p *ngIf="f.role.errors.required" class="text-danger validation-text">
                                        <span>{{ 'angular_validation.field' | translate }} </span>
                                        <span>{{ 'employees.role' | translate }} </span>
                                        <span>{{ 'angular_validation.required' | translate }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="changeToCommon()" class="row">
                        <div class="col-6">
                            <label class="control-label">{{ 'employees.is_active' | translate }}</label>
                        </div>
                        <div class="col-6 text-right align-self-center">
                            <div>
                                <input data-switch="success" formControlName="is_active" id="is_active" name="is_active" type="checkbox">
                                <label for="is_active">&nbsp;</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-12 text-right">
                            <small class="text-muted">
                                <span>{{ 'employees.change_role_description' | translate }}</span>
                            </small>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-12 text-right">
                            <button class="btn btn-primary">
                                <span>{{ 'employees.action_change_employee_type' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>

    <ng-template
        #requestEmployeeChangeDataModal
        let-modal
    >
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-title font-16 mb-0 text-dark">
                    <span>{{ 'employees.request_employee_to_change_data' | translate }}</span>
                </p>

                <button
                    (click)="modal.dismiss()"
                    aria-label="Close"
                    class="close"
                >
          <span
              aria-hidden="true"
              class="font-24 text-muted"
          >
            <i class="mdi mdi-close"></i>
          </span>
                </button>
            </div>

            <div class="modal-body">
                <form #form
                    (ngSubmit)="onRequestEmployeeToUpdateDataSubmit()"
                    [formGroup]="requestEmployeeChangeDataForm"
                >
                    <div class="mb-4">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="control-label">
                                        <span>{{ 'employees.selected_widget_template' | translate }}</span>
                                    </label>

                                    <ng-select
                                        [multiple]="false"
                                        [closeOnSelect]="true"
                                        [items]="widgetTemplates$ | async"
                                        bindLabel="name"
                                        bindValue="id"
                                        class="custom"
                                        [clearable]="false"
                                        formControlName="widget_template_ID"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="require_update_message" class="control-label">
                                        <span>{{ 'employees.message_for_employee' | translate }}</span>
                                    </label>
                                    <textarea id="require_update_message" class="form-control" formControlName="require_update_message" name="require_update_comment" type="text"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12 text-right">
                            <button
                                [disabled]="isButtonDisabled()"
                                class="btn btn-primary">
                                <span>{{ 'employees.action_request_employee_to_change_data' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>

    <ng-template #businessCardModal let-modal>
        <div class="modal-content">
            <div class="modal-body position-relative">
                <button (click)="modal.dismiss()" aria-label="Close" class="close position-absolute" style="top:0.75rem; right:0.75rem;">
                    <span aria-hidden="true" class="font-24 text-muted">
                        <i class="mdi mdi-close"></i>
                    </span>
                </button>
                <div class="row">
                    <div class="mr-1 col-auto">
                        <img [src]="employee.avatar" alt="User's avatar" class="avatar-image-lg rounded-lg"/>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex align-items-center">
                                    <span class="font-20 font-weight-bold mr-1">{{ employee.fullname }}</span>
                                    <span *ngIf="employee.personal_code" class="font-12">({{ employee.personal_code }})</span>
                                </div>
                                <div class="font-14 font-weight-bold mb-2">{{ employee.company_branch_name }}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div *ngIf="employee.phone" class="font-12">
                                    <span class="mdi mdi-phone mr-2"></span>
                                    <span><a href="tel:{{ employee.phone }}">{{ employee.phone }}</a></span>
                                </div>
                                <div *ngIf="employee.email" class="font-12">
                                    <span class="mdi mdi-email mr-2"></span>
                                    <span><a href="mailto:{{ employee.email }}">{{ employee.email }}</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #content id="ChangePasswordModal" let-modal>
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-title font-16 mb-0 text-dark">
                    <span>{{ 'employees.change_password' | translate }}</span>
                </p>

                <button
                    (click)="modal.dismiss()"
                    aria-label="Close"
                    class="close"
                    type="button"
                >
          <span
              aria-hidden="true"
              class="font-24 text-muted"
          >
            <i class="mdi mdi-close"></i>
          </span>
                </button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card mb-0 shadow-none">
                            <div class="card-body p-0">
                                <form
                                    (ngSubmit)="onSubmit()"
                                    [formGroup]="employeeEditForm"
                                    autocomplete="off"
                                >
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label class="control-label">
                                                    <span>{{ 'employees.password' | translate }}</span>
                                                </label>

                                                <input
                                                    class="form-control"
                                                    formControlName="password"
                                                    name="password"
                                                    type="password"
                                                >

                                                <div *ngIf="submitted && f.password.errors">
                                                    <p
                                                        *ngIf="f.password.errors.required"
                                                        class="text-danger validation-text"
                                                    >
                                                        <span>{{ 'angular_validation.field' | translate }} </span>
                                                        <span>{{ 'employees.password' | translate }} </span>
                                                        <span>{{ 'angular_validation.required' | translate }}</span>
                                                    </p>

                                                    <p
                                                        *ngIf="f.password.errors.minlength"
                                                        class="text-danger validation-text"
                                                    >
                                                        <span>{{ 'angular_validation.min_password_length' | translate }}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label class="control-label">
                                                    <span>{{ 'employees.password_confirmation' | translate }}</span>
                                                </label>

                                                <input
                                                    class="form-control"
                                                    formControlName="passwordConfirm"
                                                    name="password_confirmation"
                                                    type="password"
                                                >

                                                <div *ngIf="submitted && f.passwordConfirm.errors">
                                                    <p
                                                        *ngIf="f.passwordConfirm.errors.required"
                                                        class="text-danger validation-text"
                                                    >
                                                        <span>{{ 'angular_validation.field' | translate }} </span>
                                                        <span>{{ 'employees.password_confirmation' | translate }} </span>
                                                        <span>{{ 'angular_validation.required' | translate }}</span>
                                                    </p>

                                                    <p
                                                        *ngIf="f.passwordConfirm.errors.minlength"
                                                        class="text-danger validation-text"
                                                    >
                                                        <span>{{'angular_validation.min_password_length' | translate}}</span>
                                                    </p>
                                                </div>

                                                <div *ngIf="submitted && employeeEditForm.errors">
                                                    <p
                                                        *ngIf="employeeEditForm.errors.mismatch"
                                                        class="text-danger validation-text"
                                                    >
                                                        <span>{{ 'angular_validation.passwords_not_same' | translate }}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-6 text-left">
                                            <button class="btn btn-success">
                                                <span>{{ 'employees.action_save' | translate }}</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #contentSetHelpMode id="helpModeModal" let-modal>
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-title font-16 mb-0 text-dark">
                    <span>{{ 'global.help_mode' | translate }}</span>
                </p>

                <button
                    (click)="modal.dismiss()"
                    aria-label="Close"
                    class="close"
                    type="button"
                >
          <span
              aria-hidden="true"
              class="font-24 text-muted"
          >
            <i class="mdi mdi-close"></i>
          </span>
                </button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card mb-0 shadow-none">
                            <div class="card-body p-0">
                                <form
                                    (ngSubmit)="onSubmitHelp()"
                                    [formGroup]="helpEditForm"
                                    autocomplete="off"
                                >
                                    <div class="row">
                                        <div class="col-6">
                                            <label class="control-label mb-0">
                                                <span>{{ 'global.app_help_mode' | translate }}</span>
                                            </label>
                                        </div>

                                        <div class="col-6 text-right align-self-center">
                                            <div>
                                                <input
                                                    data-switch="success"
                                                    formControlName="help_mode"
                                                    id="help_mode"
                                                    name="help_mode"
                                                    type="checkbox"
                                                >

                                                <label for="help_mode">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>

                                    <p>
                                        <span>{{ 'global.app_help_mode_text_1' | translate }}</span>
                                        <i class="mdi mdi-help-circle mdi-24px text-primary"></i>
                                        <span> {{ 'global.app_help_mode_text_2' | translate }}</span>
                                    </p>

                                    <div class="row mt-4">
                                        <div class="col-sm-6 text-left">
                                            <button
                                                class="btn btn-success"
                                                type="submit"
                                            >{{ 'employees.action_save' | translate }}</button>
                                        </div>

                                        <div class="col-6 text-right">
                                            <button
                                                (click)="modal.dismiss()"
                                                class="btn btn-link text-muted pr-2"
                                                type="button"
                                            >
                                                <u>{{ 'demo.action_cancel' | translate }}</u>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #contentOnboarding id="OnboardingModal" let-modal>
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-title font-16 mb-0 text-dark">
                    <span>{{ 'employees_onboardings.assign' | translate }}</span>
                </p>

                <button
                    (click)="modal.dismiss()"
                    aria-label="Close"
                    class="close"
                >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
                </button>
            </div>
        </div>
    </ng-template>

    <!--<editor-fold desc="Top 3 cards">-->
    <ng-container *ngIf="(employee$ | async) as employee;">
        <div class="mb-3" *ngIf="employee.states.joining || employee.states.checklist || employee.states.change_request || employee.states.testing_end || employee.states.position_end || employee.states.termination_period || isTerminatedInPast()">
            <div *ngIf="employee.states.joining" class="alert d-block employee-state-1 mb-2 w-100 shadow">
                <span> {{ 'employees.joining_alert_text' | translate }} </span>
            </div>
            <div *ngIf="employee.states.checklist" class="alert d-block employee-state-2 mb-2 w-100 shadow">
                <span> {{ 'employees.checklist_alert_text' | translate }} </span>
            </div>
            <div *ngIf="employee.states.change_request" class="alert d-block employee-state-3 mb-2 w-100 shadow">
                <span> {{ 'employees.change_request_alert_text' | translate }} </span>
            </div>
            <div *ngIf="employee.states.testing_end" class="alert d-block employee-state-4 mb-2 w-100 shadow">
                <span> {{ 'employees.testing_end_alert_text' | translate }} </span>
            </div>
            <div *ngIf="employee.states.position_end" class="alert d-block employee-state-5 mb-2 w-100 shadow">
                <span> {{ 'employees.position_end_alert_text' | translate }} </span>
            </div>
            <div *ngIf="(canTerminate$ | async) && employee.states.termination_period" class="alert d-block employee-state-6 mb-2 w-100 shadow">
                <span> {{ 'employees.terminate_alert_text' | translate }}. </span>
                <span><u (click)="openTerminationModal(employee)"> {{ 'employees.terminate_alert_button' | translate }}</u>.</span>
            </div>
            <div *ngIf="isTerminatedInPast()" class="alert d-block employee-state-6 mb-2 w-100 shadow">
                <span> {{ 'employees.terminated_alert_text' | translate }}. </span>
                <span><u (click)="openTerminationModal(employee)"> {{ 'employees.terminate_alert_button' | translate }}</u>.</span>
            </div>
        </div>
    </ng-container>

    <app-employee-attendance-stats *ngIf="employeeID"
                                   [employeeID]="employeeID"
                                   [employee$]="employee$"
                                   [statsToCheck]="'employee'"
    ></app-employee-attendance-stats>

    <app-employee-onboardings *ngIf="(availableWidgets$ | async)?.employee?.overview?.onboardings && checkPermission('onboarding')"></app-employee-onboardings>

    <!--</editor-fold>-->

    <!--<editor-fold desc="Employee info">-->
    <ng-container *ngIf="(employee$ | async) as employee; else loadingProfileTemplate">
        <div
            *ngIf="profileLoaded; else loadingProfileTemplate"
            class="row"
            id="employee_info"
        >

            <div class="col-lg-12">
                <div
                    [ngClass]="requiredUpdates && requiredUpdates.employee_info === 'REQUESTED' ? 'notify-dot' : ''"
                    class="card shadow"
                >
                    <app-help name="employee.detail.personal_info"></app-help>

                    <div class="card-header py-3">
                        <div class="row d-none d-sm-flex">
                            <div class="col">
                                <div class="d-flex align-items-start mt-1">
                                    <div [ngClass]="{'hovereffect': checkPermission('employee', 'edit')}"
                                         class="avatar-wrapper">
                                        <img [src]="employee.avatar" alt="User's avatar" class="avatar-image"/>
                                        <div (click)="openAvatarChangeModal()" *ngIf="checkPermission('employee', 'edit')" class="overlay rounded-circle d-flex justify-content-center align-items-center">
                                            <span class="info"><i class="mdi mdi-pencil"></i></span>
                                        </div>
                                    </div>

                                    <div class="w-100 overflow-hidden">
                                        <p class="d-flex align-items-start modal-title font-16 text-dark pt-0">
                                          <span>
                                              <span>
                                                  {{ employee.fullname }}
                                                  <ng-container *ngIf="employee.abbreviation"> ({{ employee.abbreviation }})</ng-container>
                                                  <span (click)="openBusinessCardModal(businessCardModal)" class="text-primary mdi mdi-card-account-details-outline ml-2 cursor-pointer"></span>
                                              </span>
                                              <ng-container *ngIf="employee.facebook">
                                              <span>&nbsp;</span>
                                              <span><a href="{{ employee.facebook }}" target="_blank"><i class="mdi mdi-facebook mdi-18px"></i></a></span>
                                            </ng-container>
                                            <ng-container *ngIf="employee.linkedin">
                                              <span>&nbsp;</span>
                                              <span><a href="{{ employee.linkedin }}" target="_blank"><i class="mdi mdi-linkedin mdi-18px"></i></a></span>
                                            </ng-container>
                                          </span>


                                          <span *ngIf="employee.is_archived">
                                            <strong class="text-danger">
                                              <span>&nbsp;</span>
                                              <span>{{ 'employees.archived' | translate }}</span>
                                            </strong>
                                          </span>
                                            <span *ngIf="employee.employee_type === 'POTENTIAL'">
                                            <strong class="text-primary mr-1">
                                              <span>&nbsp;</span>
                                              <span>{{ 'employees.potential' | translate }}</span>
                                            </strong>
                                            <button
                                                (click)="openEmployeeTypeModal()"
                                                *ngIf="employee.employee_type === 'POTENTIAL'"
                                                class="btn btn-icon btn-primary btn-sm editButton"
                                            ><i class="mdi mdi-pencil"></i></button>
                                          </span>
                                        </p>

                                        <div class="mb-1">{{ employee.position }}</div>
                                        <app-tag-list *ngIf="checkPermission('tag')"
                                                      [addButtonTitle]="'employees.tags_button' | translate"
                                                      [entityID]="employee.employee_ID"
                                                      [entityType]="'Employee'">
                                        </app-tag-list>
                                    </div>
                                </div>
                            </div>


                            <div class="col-auto text-right align-self-center">
                                <a
                                    *ngIf="(canViewPersonalData$ | async)"
                                    (click)="downloadProfilePdf(employee.employee_ID, employee.fullname)"
                                    class="btn btn-light btn-icon mr-2"
                                    title="{{ 'employees.action_download_profile' | translate }}"
                                >
                                    <i class="mdi mdi-card-account-details-outline mdi-24px text-muted"></i>
                                </a>
                                <button
                                    (click)="openEmployeeEditModal()"
                                    *ngIf="(canEdit$ | async) && !employee.is_archived"
                                    [updateButton]="'employees.action_edit' | translate"
                                ></button>


                                <div
                                    *ngIf="(canArchive$ | async) || (canTerminate$ | async) || (canUnarchive$ | async)"
                                    class="dropdown notification-list d-inline-block ml-2"
                                    ngbDropdown
                                >
                                    <button
                                        class="btn btn-light btn-icon font-12"
                                        id="dropdownBasic1"
                                        ngbDropdownToggle
                                    >
                                        <i class="mdi mdi-dots-vertical mdi-24px"></i>
                                    </button>

                                    <div
                                        aria-labelledby="dropdownBasic1"
                                        class="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown fadeIn animated faster shadow"
                                        ngbDropdownMenu
                                    >
                                        <ng-container [ngTemplateOutlet]="dropdownMenu"></ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row d-flex d-sm-none">
                            <div class="col">
                                <div class="avatar-wrapper">
                                    <img (click)="checkPermission('employee', 'edit') ? openAvatarChangeModal() : null"
                                         [src]="employee.avatar"
                                         alt="Generic placeholder image"
                                         class="avatar-image"
                                    >
                                </div>
                            </div>

                            <div class="col-auto text-right align-self-center">
                                <button
                                    (click)="openEmployeeEditModal()"
                                    *ngIf="(canEdit$ | async) && !employee.is_archived"
                                    class="btn btn-primary btn-icon mr-2"
                                >
                                    <i class="mdi mdi-pencil-outline mdi-24px"></i>
                                </button>

                                <div
                                    class="dropdown notification-list d-inline-block"
                                    ngbDropdown
                                >
                                    <button
                                        *ngIf="(canArchive$ | async) || (canTerminate$ | async) || (canUnarchive$ | async)"
                                        class="btn btn-light btn-icon font-12"
                                        id="dropdownBasic2"
                                        ngbDropdownToggle
                                    >
                                        <i class="mdi mdi-dots-vertical mdi-24px"></i>
                                    </button>

                                    <div
                                        aria-labelledby="dropdownBasic2"
                                        class="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown fadeIn animated faster"
                                        ngbDropdownMenu
                                    >
                                        <ng-container [ngTemplateOutlet]="dropdownMenu"></ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row d-flex d-sm-none">
                            <div class="col-12 text-left mt-2">
                                <p class="modal-title font-16 text-dark pt-2">
                                  <span>
                                    <span>{{ employee.fullname }}</span>
                                      <span (click)="openBusinessCardModal(businessCardModal)" class="text-primary mdi mdi-card-account-details-outline ml-2 cursor-pointer"></span>
                                  </span>
                                    <span *ngIf="employee.is_archived">
                                        <strong class="text-danger">
                                          <span>&nbsp;</span>
                                          <span>{{ 'employees.archived' | translate }}</span>
                                        </strong>
                                    </span>
                                    <span *ngIf="employee.employee_type === 'POTENTIAL'">
                                        <strong class="text-primary mr-1">
                                          <span>&nbsp;</span>
                                          <span>{{ 'employees.potential' | translate }}</span>
                                        </strong>
                                        <button
                                            (click)="openEmployeeTypeModal()"
                                            *ngIf="employee.employee_type === 'POTENTIAL'"
                                            class="btn btn-icon btn-primary btn-sm editButton"
                                        ><i class="mdi mdi-pencil"></i></button>
                                    </span>
                                </p>

                                <span>{{ employee.position }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="card-body px-4 pb-0">
                        <ng-container *ngIf="entityRequests">
                            <ng-container *ngFor="let request of entityRequests">
                                <div *ngIf="request?.entity_type === 'App\\Models\\Employee'" class="alert alert-warning mb-3 w-100 shadow">
                                    <span>{{ 'employees.waiting_to_be_processed_employee_info_part_1' | translate }}</span>
                                    <span><u (click)="openEntityUpdateRequestModal(request)"> {{ 'employees.require_update_detail_action'| translate }}</u> </span>
                                    <span>{{ 'employees.waiting_to_be_processed_employee_info_part_2' | translate }}</span>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div *ngIf="requiredUpdates && requiredUpdates.employee_info === 'REQUESTED'" class="alert alert-warning mb-3 w-100 shadow">
                            <span> {{ 'employees.require_update_description_employee_info' | translate }} </span>
                            <span><u (click)="openEmployeeEditModal()"> {{ 'employees.require_update_edit_action'| translate }}</u>. </span>
                            <u (click)="refuseUpdateData()"> {{ 'employees.require_update_cancel_action' | translate }}</u>.
                        </div>
                        <div class="row border-bottom">
                            <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                                <span class="text-dark">{{ 'employees.personal_code' | translate }}</span>
                            </div>

                            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                                <span>{{ employee?.personal_code }}</span>
                            </div>
                        </div>

                        <div class="row border-bottom">
                            <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                                <span class="text-dark">{{ 'employees.email' | translate }}</span>
                            </div>

                            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                                <span>{{ employee?.email }}</span>
                            </div>
                        </div>

                        <div class="row border-bottom">
                            <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                                <span class="text-dark">{{ 'employees.phone' | translate }}</span>
                            </div>

                            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                                <span>{{ employee?.phone }}</span>
                            </div>
                        </div>

                        <ng-container *ngIf="employee.custom_fields.length > 0">
                            <div *ngFor="let field of employee.custom_fields" class="row border-bottom">
                                <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                                    <span class="text-dark"> {{ field.label }} </span>
                                </div>
                                <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive text-cropped">
                                    <ng-container [ngSwitch]="field.type">
                                        <span *ngSwitchCase="'RICHTEXT'" [innerHTML]="field.value"></span>
                                        <span *ngSwitchCase="'PERCENT'"> {{ field.value }} % </span>
                                        <span *ngSwitchCase="'AMOUNT'"> {{ field.value | localizedCurrency }} </span>
                                        <span *ngSwitchCase="'DATE'"> {{ field.value | date: 'dd.MM.yyyy' }} </span>
                                        <span *ngSwitchCase="'DATETIME'"> {{ field.value | date: 'dd.MM.yyyy HH:mm' }} </span>
                                        <span *ngSwitchCase="'TOGGLE'">  {{ (field.value ? 'global.YES' : 'global.NO') | translate }} </span>
                                        <span *ngSwitchDefault> {{ field.value }} </span>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>


                        <div class="row border-bottom">
                            <div class="col-12 col-sm-6 col-md-3 py-1 py-md-2">
                                <span class="text-dark">{{ 'employees.team' | translate }}</span>
                            </div>

                            <div class="col-12 col-sm-6 col-md-9 py-1 py-md-2 d-flex justify-content-between">
                                <span>{{ employee?.team ?? ('employees.no_team' | translate) }}</span>
                                <ng-container *ngIf="canEdit$ | async">
                                    <button *ngIf="!employee.is_archived" (click)="openEmployeeChangeCompanyGroupModal()" class="btn btn-icon btn-primary btn-sm editButton">
                                        <i class="mdi mdi-pencil"></i>
                                    </button>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row border-bottom">
                            <div class="col-12 col-sm-6 col-md-3 py-1 py-md-2">
                                <span class="text-dark">{{ 'employees.supervisor' | translate }}</span>
                            </div>

                            <div class="col-12 col-sm-6 col-md-9 py-1 py-md-2 d-flex justify-content-between">
                                <span>{{ employee?.supervisor?.fullname ?? ('employees.no_supervisor' | translate) }}</span>
                                <ng-container *ngIf="canEdit$ | async">
                                    <button *ngIf="!employee.is_archived" (click)="openEmployeeSupervisorModal()" class="btn btn-icon btn-primary btn-sm editButton">
                                        <i class="mdi mdi-pencil"></i>
                                    </button>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row border-bottom mb-3">
                            <div class="col-12 col-sm-6 col-md-3 py-1 py-md-2">
                                <span class="text-dark">{{ 'employees.access_to_application' | translate }}</span>
                            </div>

                            <div class="col-12 col-sm-6 col-md-9 py-1 py-md-2 d-flex justify-content-between">
                                <span *ngIf="employee?.has_active_access">
                                    <span class="mdi mdi-check-circle-outline mr-1 text-green"></span>{{ 'global.YES' | translate }}
                                    <span *ngIf="employee?.has_active_access_to">({{ 'global.to' | translate }} {{ employee?.has_active_access_to | date:'dd.MM.yyyy'}})</span>
                                </span>
                                <span *ngIf="!employee?.has_active_access">
                                    <span class="mdi mdi-close-circle-outline mr-1 text-red"></span>{{ 'global.NO' | translate }}
                                    <span *ngIf="employee?.has_password">({{ 'employees.has_password' | translate }})</span>
                                    <span *ngIf="!employee?.has_password">({{ 'employees.does_not_have_password' | translate }})</span>
                                </span>
                                <ng-container *ngIf="canEdit$ | async">
                                    <button (click)="openEmployeeActiveAccessModal()" class="btn btn-icon btn-primary btn-sm editButton">
                                        <i class="mdi mdi-pencil"></i>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </ng-container>

    <ng-template #loadingProfileTemplate>
        <div class="card shadow p-3">
            <div class="fb-item">
                <div class="first-section-wrapper d-flex align-items-center">
                    <div class="gravatar mr-3">
                        <ngx-skeleton-loader
                            [theme]="{ width: '80px', height: '80px' }"
                            appearance="circle"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="gravatar-title pt-3">
                        <div>
                            <ngx-skeleton-loader
                                [theme]="{ width: '200px' }"
                            ></ngx-skeleton-loader>
                        </div>
                        <div>
                            <ngx-skeleton-loader
                                [theme]="{ width: '170px' }"
                            >
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
                <div class="second-section-wrapper">
                    <div class="item">
                        <ngx-skeleton-loader [theme]="{ 'margin': '15px 0' }"
                                             appearance="line"
                                             count="3">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>


    <!--</editor-fold>-->
    <div class="row">
        <ng-container *ngIf="(employee$ | async) as employee; else loadingPersonalDataTemplate">
            <ng-container *ngIf="profileLoaded; else loadingPersonalDataTemplate">
                <div class="col-12 mb-3">
                    <app-employee-personal-data (refetchActiveRequests)="getActiveRequests(employee.employee_ID)" (refetchRequiredUpdates)="getRequiredDataUpdates(employee.employee_ID)"
                                                *ngIf="checkPermission('employeePersonalData') && (availableWidgets$ | async)?.employee?.overview?.personal_data"
                                                [employee]="employee"
                                                [entityRequests]="entityRequests"
                                                [requiredUpdates]="requiredUpdates"
                                                id="personal_info">
                    </app-employee-personal-data>
                </div>

                <div *ngIf="checkPermission('employee', 'edit') && (availableWidgets$ | async)?.employee?.overview?.note" class="col-12 col-lg-6 mb-3">
                    <app-note
                        [entity]="{ id: employee?.employee_ID, type: 'Employee' }"
                    ></app-note>
                </div>
                <div *ngIf="checkPermission('skill') && (availableWidgets$ | async)?.employee?.overview?.skills" class="col-12 col-lg-6 mb-3">
                    <div class="card shadow h-100">
                        <app-help name="employee.detail.skills"></app-help>
                        <div class="card-header py-3">
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-7">
                                    <p class="modal-title font-16 text-dark pt-2 pb-2">
                                        <span>{{ 'employees.skills' | translate }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="control-label mb-1">{{ 'employees.hard_skills' | translate }}</div>
                                    <app-tag-list
                                        [category]="'hard_skill'"
                                        [entityID]="employee.employee_ID"
                                        [entityType]="'Employee'"
                                        [modalTitle]="'employees.hard_skills' | translate"
                                    >
                                    </app-tag-list>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <div class="control-label mb-1">{{ 'employees.soft_skills' | translate }}</div>
                                    <app-tag-list
                                        [category]="'soft_skill'"
                                        [entityID]="employee.employee_ID"
                                        [entityType]="'Employee'"
                                        [modalTitle]="'employees.soft_skills' | translate"
                                    >
                                    </app-tag-list>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <app-employee-contact-info
                    (getEmployeeActiveRequests)="getActiveRequests(employee.employee_ID)"
                    (getEmployeeRequiredUpdates)="getRequiredDataUpdates(employee.employee_ID)"
                    [canShow]="{address: checkPermission('employeeAddress'), contactPerson: checkPermission('employeeContactPerson')}"
                    [employee]="employee"
                    [entityRequests]="entityRequests"
                    [requiredUpdates]="requiredUpdates"
                    class="{{this.canShowBoth((availableWidgets$ | async)?.employee?.overview) === true ? 'd-lg-flex w-100': (this.canShowSome((availableWidgets$ | async)?.employee?.overview) === true ? 'w-100 w-lg-50' : '')}}">
                </app-employee-contact-info>

                <div *ngIf="checkPermission('employeeAccess') && (availableWidgets$ | async)?.employee?.overview?.accesses" class="col-12 col-lg-6 mb-3">
                    <app-employee-access></app-employee-access>
                </div>
                <div *ngIf="checkPermission('scheduledTask') && (availableWidgets$ | async)?.employee?.overview?.notifications" class="col-12 col-lg-6 mb-3">
                    <app-scheduled-task-list
                        *ngIf="employee?.employee_ID"
                        [entity]="{ id: employee?.employee_ID, type: 'Employee' }"
                        [mode]="'compact'"
                    ></app-scheduled-task-list>
                </div>

                <div *ngIf="checkPermission('employee', 'edit') && (availableWidgets$ | async)?.employee?.overview?.requests" class="col-12">
                    <app-entity-update-request
                        *ngIf="employee?.employee_ID"
                        [employeeIds]="[employee?.employee_ID]"
                        [entityRequests]="entityRequests"
                        [mode]="'noHeader'"
                    ></app-entity-update-request>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #loadingPersonalDataTemplate>
            <div class="col-12">
                <div class="card shadow p-3">
                    <div class="item">
                        <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="5"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</ng-container>

<ng-template #dropdownMenu>
    <button
        (click)="openTerminationModal(employee)"
        *ngIf="canTerminate$ | async"
        ngbDropdownItem
    >{{ 'employees.terminate_employment' | translate }}</button>

    <button
        (click)="unarchiveEmployee()"
        *ngIf="canUnarchive$ | async"
        ngbDropdownItem
    >{{ 'employees.action_remove_from_archive' | translate }}</button>

    <button
        (click)="archiveEmployee()"
        *ngIf="canArchive$ | async"
        ngbDropdownItem
    >{{ 'employees.action_archive' | translate }}</button>

    <button
        (click)="openActivityModal()"
        *ngIf="canEdit$ | async"
        ngbDropdownItem
    >{{ 'employees.action_create_activity' | translate }}</button>

    <button
        (click)="openEmployeeTypeModal()"
        *ngIf="canEdit$ | async"
        ngbDropdownItem
    >{{ 'employees.change_type' | translate }}</button>

    <button
        *ngIf="canEdit$ | async"
        [routerLink]="['/employee', employee.employee_ID, 'detail', 'setting']"
        ngbDropdownItem
    >{{ 'employees.change_role' | translate }}</button>

    <button
        (click)="openRequestEmployeeChangeDataModal()"
        *ngIf="canEdit$ | async"
        ngbDropdownItem
    >{{ 'employees.request_employee_to_change_data' | translate }}</button>

    <button
        (click)="loginAsThisEmployee(employee)"
        *ngIf="isAdmin"
        ngbDropdownItem
    >{{ 'employees.login_as_this_employee' | translate }}</button>

    <button
        (click)="deleteEmployee()"
        *ngIf="canDelete$ | async"
        ngbDropdownItem
    >{{ 'employees.action_delete' | translate }}</button>
</ng-template>
