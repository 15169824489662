import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { EntityUpdateRequest } from 'src/app/entity_update_request/state/entity_update_request.model';
import { EntityUpdateRequestService } from 'src/app/entity_update_request/state/entity_update_request.service';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { EntityUpdateRequestModalComponent } from '../../partials/modal/entity_update_request-modal.component';

@Component({
    selector: 'app-entity-update-request',
    templateUrl: './entity_update_request-list.component.html',
    styleUrls: ['./entity_update_request-list.component.css']
})
export class EntityUpdateRequestComponent implements OnInit {
    @ViewChild('filterFormOptions', {static: false})
    public filterFormOptions: TemplateRef<ElementRef>;

    @Input() employeeIds: Array<any>;
    @Input() mode: string;
    @Input() isDashboard = false;
    @Input() entityRequests: Array<EntityUpdateRequest>;

    @Output() getCountEvent = new EventEmitter<string>();

    public canEdit = false;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public filterForm: UntypedFormGroup;
    public states$: Observable<any>;
    public states = ['NEW'];
    public employees$: Observable<any>;
    private _filterFormModalRef: NgbModalRef;

    public constructor(
        private _flatpickrLocale: FlatpickrLocaleService,
        private _fb: UntypedFormBuilder,
        private _modalService: NgbModal,
        private _entityUpdateRequestService: EntityUpdateRequestService,
        private _employeeService: EmployeeService,
        private _authService: AuthenticationService,
        protected _changeDetectorRef: ChangeDetectorRef,
    ) {
        this.filterForm = this._fb.group({
            states: [this.states],
            employeesIDs: [this.employeeIds],
        });

        this.filterForm[`defaultValue`] = this.filterForm.value;
    }

    public get statesCount(): number {
        return this.filterForm.get('states').value?.length;
    }

    public get employeesIDsCount(): number {
        return this.filterForm.get('employeesIDs').value?.length;
    }

    public ngOnInit(): void {
        this._fetchListData(this.employeeIds, this.states);

        this.states$ = this._entityUpdateRequestService.getStates();
        this.employees$ = this._employeeService.getAllEmployees();

        this.canEdit = this._authService.checkPermission('employee.edit');
    }

    public openEntityUpdateRequestModal(entityUpdateRequest?: EntityUpdateRequest): void {
        const modalRef = this._modalService.open(EntityUpdateRequestModalComponent, {centered: true});
        if (entityUpdateRequest) {
            modalRef.componentInstance.entityUpdateRequest = entityUpdateRequest;
        }

        modalRef.result
            .then(
                () => {
                    const values = this.filterForm.value;
                    if (this.employeeIds) {
                        this._fetchListData(this.employeeIds, values.states);
                    } else {
                        this._fetchListData(values.employeesIDs, values.states);
                    }
                }, () => { }
            );
    }

    public openFilterFormOptions(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormOptions);
    }

    public onSubmit(): void {
        this._filterFormModalRef?.close();

        const values = this.filterForm.value;

        if (this.employeeIds) {
            this._fetchListData(this.employeeIds, values.states);
        } else {
            this._fetchListData(values.employeesIDs, values.states);
        }
    }

    protected _fetchListData(employeesIDs?: Array<number>, states?: Array<string>): void {
        this._entityUpdateRequestService.getAllRequests(employeesIDs, states)
            .subscribe((sub: any) => {
                this.entityRequests = sub.data;
                this.getCountEvent.emit(sub.recordsTotal.toString());
            });
    }
}
