import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ID } from '@datorama/akita';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { KPI } from 'src/app/kpi/kpi/state/kpi.model';
import { KPIService } from 'src/app/kpi/kpi/state/kpi.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { UserService } from '../../../../setting/services/user.service';
import { CreateEditKpiModalComponent } from '../../partials/create-edit-kpi-modal/create-edit-kpi-modal.component';

@Component({
    selector: 'app-kpi-list',
    templateUrl: './kpi-list.component.html',
    styleUrls: ['./kpi-list.component.css']
})
export class KpiListComponent extends FatherOfListComponent<KPI> implements OnInit {
    public canCreate = false;
    public canView = false;

    public sort: Sort<KPI> = {
        column: 'name',
        direction: SORT_ASC
    };

    private _fetchKPIs$ = new ReplaySubject<void>(1);

    public constructor(
        private _kpiService: KPIService,
        private _modalService: NgbModal,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnInit(): void {
        this.canCreate = this._authService.checkPermission('kpi.create');
        this.canView = this._authService.checkPermission('kpi.view');

        this._rows$ = this._fetchKPIs$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._kpiService.getKPIsList(params)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();
    }

    public openEditModal(kpi?: KPI): void {
        const modalRef = this._modalService.open(CreateEditKpiModalComponent, {centered: true});

        modalRef.componentInstance.kpi = kpi;

        modalRef.result
            .then(
                () => this._fetchListData(),
                () => { }
            );
    }

    public trackByFn(index: number, kpi: KPI): ID {
        return kpi.kpi_ID;
    }

    protected _fetchListData(): void {
        this._fetchKPIs$.next();
    }
}
