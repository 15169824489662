import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeDatetimeType } from 'src/app/setting/models/EmployeeDatetimeType';
import { DatetimesTypesStoreService } from 'src/app/setting/services/datetimes-types-store.service';

@Component({
    selector: 'app-create-edit-datetime-type',
    templateUrl: './create-edit-datetime-type.component.html',
    styleUrls: ['./create-edit-datetime-type.component.css']
})
export class CreateEditDatetimeTypeComponent implements OnInit {
    public datetimeType: EmployeeDatetimeType;
    public datetimeTypeEditForm: UntypedFormGroup;
    public submitted = false;

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.datetimeTypeEditForm.controls;
    }

    public constructor(
        public activeModal: NgbActiveModal,
        private _dateTimesTypeStoreService: DatetimesTypesStoreService,
        private _fb: UntypedFormBuilder,
    ) { }

    public ngOnInit(): void {
        this.datetimeTypeEditForm = this._fb.group({
            name: ['', Validators.required],
            abbreviation: [''],
            color: ['', Validators.required],
            timer: [false, Validators.required],
            can_plan: [false, Validators.required],
            is_shift: [false, Validators.required],
            is_visible: [false, Validators.required],
            is_approvable: [true, Validators.required],
            is_active: [true, Validators.required],
            worktime_classification: ['NEUTRAL', Validators.required],
        });

        if (this.datetimeType) {
            this.datetimeTypeEditForm.patchValue({
                name: this.datetimeType.name,
                abbreviation: this.datetimeType.abbreviation,
                color: this.datetimeType.color,
                timer: this.datetimeType.timer,
                can_plan: this.datetimeType.can_plan,
                is_shift: this.datetimeType.is_shift,
                is_approvable: this.datetimeType.is_approvable,
                is_active: this.datetimeType.is_active,
                worktime_classification: this.datetimeType.worktime_classification,
            });

            if (!this.datetimeType.can_delete) {
                this.datetimeTypeEditForm.get('name').disable();
            }
        }
    }

    public deleteDatetimeType(): void {
        this._dateTimesTypeStoreService.removeBranch(this.datetimeType.employee_datetime_type_ID, true)
            .then(() => this.activeModal.close('close'));
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.datetimeTypeEditForm.valid) {
            return;
        }

        let result = null;

        if (this.datetimeType) {
            result = this._dateTimesTypeStoreService.updateDatetimeType(
                this.datetimeType.employee_datetime_type_ID,
                this.datetimeTypeEditForm.getRawValue()
            );
        } else {
            result = this._dateTimesTypeStoreService.addDatetimeType(this.datetimeTypeEditForm.value);
        }

        result
            .then(() => this.activeModal.close('close'));
    }
}
