import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { arrayUpdate } from '@datorama/akita';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AnalysisService } from 'src/app/analysis/services/analysis.service';
import { CreateEditEmployeeReviewModalComponent } from 'src/app/kpi/components/partials/create-edit-employee-review-modal/create-edit-employee-review-modal.component';
import { EmployeeReview } from 'src/app/kpi/employee-review/employee-review.model';
import { DatetimesTypesService } from 'src/app/setting/services/datetimes-types.service';
import { AxisChart } from 'src/app/shared/common/AxisChart';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';

interface CategoryType {
    label: string;
    value: string;
}

interface PeriodType {
    label: string;
    value: string;
}

interface FilterFormValues {
    from: string;
    to: string;
    categoryValues: Array<string>;
}

interface SalaryFilterFormValues extends FilterFormValues {
    groupByCategory: boolean;
}

interface WithChartData {
    [key: string]: {
        series: Array<{
            data: Array<number>;
        }>;
    };
}

@Component({
    selector: 'app-employee-analysis',
    templateUrl: './employee-analysis.component.html',
    styleUrls: ['./employee-analysis.component.css']
})
export class EmployeeAnalysisComponent implements OnInit, OnDestroy {
    @ViewChild('filterFormAttendanceOptions', {static: false})
    public filterFormAttendanceOptions: TemplateRef<ElementRef>;

    @ViewChild('filterFormKPIOptions', {static: false})
    public filterFormKPIOptions: TemplateRef<ElementRef>;

    @ViewChild('filterFormSalaryOptions', {static: false})
    public filterFormSalaryOptions: TemplateRef<ElementRef>;

    public attendanceCategories$: Observable<Array<CategoryType>>;
    public attendanceFilterForm: UntypedFormGroup;
    public attendanceFrom: string;
    public attendanceGroupByCategory: boolean;
    public attendancePeriodType: PeriodType;
    public attendancePeriodTypes: Array<PeriodType> = [];
    public attendanceSelectedCategories: Array<string>;
    public attendanceTo: string;
    public canRenderAttendance$: Observable<boolean>;
    public canRenderKPI$: Observable<boolean>;
    public canRenderSalary$: Observable<boolean>;
    public employeeID: number;
    public kpiCategories$: Observable<Array<CategoryType>>;
    public kpiFilterForm: UntypedFormGroup;
    public kpiLoading$: Observable<boolean>;
    public kpiStats$: Observable<{ line: AxisChart; kpis: Array<any>; } | null>;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public salaryCategories: Array<CategoryType>;
    public salaryLoading$: Observable<boolean>;
    public salaryFilterForm: UntypedFormGroup;
    public availableWidgets$: Observable<AvailableWidgets>;
    public salaryStats$: Observable<AxisChart | null>;
    public permissions: Array<string>;
    public watchingMyself = false;

    private _filterFormAttendanceModalRef: NgbModalRef;
    private _filterFormKPIModalRef: NgbModalRef;
    private _filterFormSalaryModalRef: NgbModalRef;
    private _kpiCategories$ = new ReplaySubject<Array<CategoryType>>(1);
    private _kpiLoading$ = new BehaviorSubject<boolean>(true);
    private _fetchEvaluations$ = new ReplaySubject<void>(1);
    private _salaryLoading$ = new BehaviorSubject<boolean>(true);
    private _fetchSalaries$ = new ReplaySubject<void>(1);
    private _subscription: Subscription;

    public constructor(
        public fpHelper: FlatpickrHelper,
        private _analysisService: AnalysisService,
        private _companyService: CompanyService,
        private _attendanceTypesService: DatetimesTypesService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _router: Router,
        private _translateService: TranslateService,
        private _authService: AuthenticationService,
        private _cdr: ChangeDetectorRef
    ) { }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;

        this.permissions = this._authService?.permissions?.map(perm => perm.name);

        this._subscription = this._route.parent.params.subscribe(params => {
            this.employeeID = parseInt(params.id, 10);
            const loggedEmployeeID = this._authService?.employee?.employee_ID;
            this.watchingMyself = loggedEmployeeID === this.employeeID;

            this._setup();
            this._cdr.detectChanges();
        });

        this._fetchEvaluations$.next();
        this._fetchSalaries$.next();
    }

    public onAttendanceFilterFormSubmit(): void {
        this._filterFormAttendanceModalRef?.close();

        this.attendanceFrom = this.attendanceFilterForm.value.from;

        this.attendanceGroupByCategory = this.attendanceFilterForm.value.groupByCategory;

        this.attendancePeriodType = this.attendanceFilterForm.value.periodType;

        this.attendanceSelectedCategories = this.attendanceFilterForm.value.categoryValues;

        this.attendanceTo = this.attendanceFilterForm.value.to;
    }

    public onKPIFilterFormSubmit(): void {
        this._filterFormKPIModalRef?.close();

        this._fetchEvaluations$.next();
    }

    public onSalaryFilterFormSubmit(): void {
        this._filterFormSalaryModalRef?.close();

        this._fetchSalaries$.next();
    }

    public openFilterFormAttendanceOptions(): void {
        this._filterFormAttendanceModalRef = this._modalService.open(this.filterFormAttendanceOptions);
    }

    public openFilterFormKPIOptions(): void {
        this._filterFormKPIModalRef = this._modalService.open(this.filterFormKPIOptions);
    }

    public openFilterFormSalaryOptions(): void {
        this._filterFormSalaryModalRef = this._modalService.open(this.filterFormSalaryOptions);
    }

    public openKPIReviewEditModal(kpiReview: EmployeeReview): void {
        const modalRef = this._modalService.open(CreateEditEmployeeReviewModalComponent, {centered: true});

        modalRef.componentInstance.employeeID = kpiReview.employee_ID;
        modalRef.componentInstance.review = kpiReview;
    }

    public checkPermission(name: string): boolean {
        return (this.watchingMyself || this.permissions.includes(name) || this.permissions.includes(name + '.view') || this.permissions.includes(name + '.viewStructure'));
    }

    private _setup(): void {
        this._setupAttendance();
        this._setupKPI();
        this._setupSalary();
    }

    private _setupAttendance(): void {
        this._setupAttendanceFilterForm();

        this.attendanceCategories$ = this._attendanceTypesService.getAllDatetimeTypes()
            .pipe(
                map(types => types.map(type => ({
                    label: type.name,
                    value: String(type.employee_datetime_type_ID)
                }))),
                tap(categories => {
                    const categoryValues = categories.map(c => c.value);

                    this.attendanceSelectedCategories = categoryValues;

                    this.attendanceFilterForm.patchValue({categoryValues});

                    this.attendanceFilterForm[`defaultValue`] = this.attendanceFilterForm.value;
                }),
                shareReplay()
            );

        this.attendancePeriodTypes = [
            {
                label: this._translateService.instant('employees_analysis.period_week'),
                value: 'week'
            },
            {
                label: this._translateService.instant('employees_analysis.period_month'),
                value: 'month'
            },
            {
                label: this._translateService.instant('employees_analysis.period_quarter'),
                value: 'quarter'
            },
            {
                label: this._translateService.instant('employees_analysis.period_year'),
                value: 'year'
            }
        ];

        this.attendanceFilterForm.patchValue({periodType: this.attendancePeriodTypes[0].value});

        this.attendancePeriodType = this.attendancePeriodTypes[0].value as unknown as PeriodType;

        this.canRenderAttendance$ = this.attendanceCategories$.pipe(map(() => true));
    }

    private _setupAttendanceFilterForm(): void {
        this.attendanceSelectedCategories = [];
        this.attendanceFrom = moment().startOf('month').format('YYYY-MM-DD');
        this.attendanceTo = moment().endOf('month').format('YYYY-MM-DD');

        this.attendanceFilterForm = this._fb.group({
            categoryValues: [this.attendanceSelectedCategories],
            from: [this.attendanceFrom],
            groupByCategory: [this.attendanceGroupByCategory],
            periodType: [this.attendancePeriodType],
            to: [this.attendanceTo],
        });
    }

    private _setupKPI(): void {
        this.kpiCategories$ = this._kpiCategories$;

        this.kpiLoading$ = this._kpiLoading$;

        this._setupKPIFilterForm();

        this.kpiStats$ = this._fetchEvaluations$
            .pipe(
                tap(() => this._kpiLoading$.next(true)),
                switchMap(() => {
                    return this._analysisService.getKPIAnalysisInfoByEmployeeID(this.employeeID, this.kpiFilterForm.value)
                        .pipe(
                            catchError(error => of(null))
                        );
                }),
                map(stats => this._transformZeroToNullInSeries(stats, 'line')),
                tap(stats => this._kpiCategories$.next(stats?.kpis)),
                tap(stats => this.kpiFilterForm.patchValue({categoryValues: stats?.kpis?.map(c => c.kpi_ID) || []})),
                tap(stats => this.kpiFilterForm[`defaultValue`] = this.kpiFilterForm.value),
                tap(() => this._kpiLoading$.next(false)),
                shareReplay()
            );

        this.canRenderKPI$ = this.kpiCategories$.pipe(map(() => true));
    }

    private _setupKPIFilterForm(): void {
        this.kpiFilterForm = this._fb.group({
            from: [moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD')],
            to: [moment().endOf('month').format('YYYY-MM-DD')],
            categoryValues: [[]]
        });
    }

    private _setupSalary(): void {
        this.salaryLoading$ = this._salaryLoading$;

        this._setupSalaryFilterForm();

        this.salaryCategories = [
            {value: 'salary', label: this._translateService.instant('employees_salaries.salary')},
            {value: 'hour_costs', label: this._translateService.instant('employees_salaries.hour_costs')},
            {value: 'month_costs', label: this._translateService.instant('employees_salaries.month_costs')},
            {value: 'bonuses', label: this._translateService.instant('employees_salaries.bonuses')},
            {value: 'benefits', label: this._translateService.instant('employees_salaries.benefits')},
            {value: 'growth_costs', label: this._translateService.instant('employees_salaries.growth_costs')},
            {value: 'property_costs', label: this._translateService.instant('employees_salaries.property_costs')}
        ];

        this.salaryFilterForm.patchValue({categoryValues: this.salaryCategories.map(c => c.value)});

        this.salaryFilterForm[`defaultValue`] = this.salaryFilterForm.value;

        this.salaryStats$ = this._fetchSalaries$
            .pipe(
                tap(() => this._salaryLoading$.next(true)),
                switchMap(() => {
                    return this._analysisService.getSalaryAnalysisInfoByEmployeeID(this.employeeID, this.salaryFilterForm.value)
                        .pipe(
                            catchError(error => of(null))
                        );
                }),
                tap(() => this._salaryLoading$.next(false))
            );
    }

    private _setupSalaryFilterForm(): void {
        this.salaryFilterForm = this._fb.group({
            from: [moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD')],
            to: [moment().endOf('month').format('YYYY-MM-DD')],
            categoryValues: [[]],
            groupByCategory: [false]
        });
    }

    private _transformZeroToNullInSeries<T extends WithChartData>(stats: T, key: keyof T): T {
        if (
            !stats ||
            !stats[key].series
        ) {
            return stats;
        }

        let series = [...stats[key].series];
        let seriesSingle;

        const compareByIndexWrapperFn = i => (item, n) => i === n;

        for (let i = 0; i < series.length; i++) {
            seriesSingle = series[i];

            series = arrayUpdate(
                series,
                compareByIndexWrapperFn(i),
                {
                    ...seriesSingle,
                    data: seriesSingle.data.map(v => v === 0 ? null : v)
                }
            );
        }

        stats = {
            ...stats,
            [key]: {
                ...stats[key],
                series
            }
        };

        return stats;
    }
}
