import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { passwordMatchValidator } from 'src/app/core/validators/password-match.validator';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeAddress } from 'src/app/employee/models/EmployeeAddress';
import { EmployeePosition } from 'src/app/employee/models/EmployeePosition';
import { Salary } from 'src/app/employee/models/Salary';
import { AddressService } from 'src/app/employee/services/address.service';
import { EmployeePositionService } from 'src/app/employee/services/employee-position.service';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { SalaryService } from 'src/app/employee/services/salary.service';
import { EntityUpdateRequest } from 'src/app/entity_update_request/state/entity_update_request.model';
import { EntityUpdateRequestService } from 'src/app/entity_update_request/state/entity_update_request.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { EntityUpdateRequestModalComponent } from '../../../../entity_update_request/components/partials/modal/entity_update_request-modal.component';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { Role } from '../../../../setting/models/Role';
import { Subscription as SubscriptionModel } from '../../../../setting/models/Subscription';
import { CompanyService } from '../../../../setting/services/company.service';
import { RolesService } from '../../../../setting/services/roles.service';
import { SubscriptionService } from '../../../../setting/services/subscription.service';
import { WidgetTemplate } from '../../../../shared/models/WidgetTemplate';
import { WidgetTemplatesService } from '../../../../shared/services/widget-templates.service';
import { EmployeeRequiredDataUpdates } from '../../../models/EmployeeRequiredDataUpdates';
import { ChangeAvatarModalComponent } from '../../partials/change-avatar-modal/change-avatar-modal.component';
import { ChangeEmployeeActiveAccessModalComponent } from '../../partials/change-employee-active-access-modal/change-employee-active-access-modal.component';
import { ChangeEmployeeCompanyGroupModalComponent } from '../../partials/change-employee-company-group/change-employee-company-group-modal.component';
import { CreateEmployeeTerminationModalComponent } from '../../partials/create-employee-termination-modal/create-employee-termination-modal.component';
import { CreateEditEmployeeActivityModalComponent } from '../create-edit-employee-activity-modal/create-edit-employee-activity-modal.component';
import { CreateEditEmployeeModalComponent } from '../create-edit-employee-modal/create-edit-employee-modal.component';
import { EditEmployeeSupervisorComponent } from '../edit-employee-supervisor/edit-employee-supervisor.component';

interface ConfirmDialogOptions {
    cancelButtonText?: string;
    confirmButtonText: string;
    title: string;
    text: string;
    confirmButtonColor?: string;
    showCancelButton?: boolean;
}

const DEFAULT_CONFIRM_DIALOG_OPTIONS: Pick<ConfirmDialogOptions, 'confirmButtonColor' | 'showCancelButton'> = {
    confirmButtonColor: '#6979ED',
    showCancelButton: true
};

interface EmployeeWithOwnedCompanyGroupNames extends Employee {
    ownedCompanyGroupNames: string;
}

@Component({
    selector: 'app-employee-overview',
    templateUrl: './employee-overview.component.html',
    styleUrls: ['./employee-overview.component.css']
})
export class EmployeeOverviewComponent implements OnInit, OnDestroy {
    @ViewChild('employeeTypeModal', {static: false})
    public employeeTypeModal: TemplateRef<ElementRef>;
    @ViewChild('requestEmployeeChangeDataModal', {static: false})
    public requestEmployeeChangeDataModal: TemplateRef<ElementRef>;

    public canArchive$: Observable<boolean>;
    public canDelete$: Observable<boolean>;
    public canEdit$: Observable<boolean>;
    public canCreate$: Observable<boolean>;
    public canTerminate$: Observable<boolean>;
    public canUnarchive$: Observable<boolean>;
    public canViewPersonalData$: Observable<boolean>;
    public employee$: Observable<EmployeeWithOwnedCompanyGroupNames | null>;
    public employeeTypes$: Observable<Array<string>>;
    public employee: EmployeeWithOwnedCompanyGroupNames;
    public employeeAddresses$: Observable<Array<EmployeeAddress>>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public employeeEditForm: UntypedFormGroup;
    public employeeTypeForm: UntypedFormGroup;
    public requestEmployeeChangeDataForm: UntypedFormGroup;
    public employeePositions$: Observable<Array<EmployeePosition>>;
    public employeeSalaries$: Observable<Array<Salary>>;
    public widgetTemplates$: Observable<Array<WidgetTemplate>>;
    public subscription$ = this._subscriptionService.subscription$;
    public subscription: SubscriptionModel;
    public requiredUpdates: EmployeeRequiredDataUpdates;
    public entityRequests: Array<EntityUpdateRequest>;
    public helpEditForm: UntypedFormGroup;
    public permissions: Array<string>;
    public employeeRoles$: Observable<Array<Role>>;
    public attendanceCols = 0;
    public watchingMyself = false;
    public isCollapsed = true;
    public profileLoaded = false;
    public submitted = false;
    public isAttendanceCardAvailable = false;
    public isAdmin = false;
    public employeeID: number;

    private _fetchEmployeeExtended$ = new ReplaySubject<void>(1);
    private _employeeTypeModal: NgbModalRef;
    private _requestEmployeeChangeDataModal: NgbModalRef;
    private _subscription: Subscription;

    public constructor(
        private _addressService: AddressService,
        private _authService: AuthenticationService,
        private _employeeService: EmployeeService,
        private _companyService: CompanyService,
        private _fb: UntypedFormBuilder,
        private _modalService: NgbModal,
        private _positionService: EmployeePositionService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _salaryService: SalaryService,
        private _translateService: TranslateService,
        private _location: Location,
        private _entityUpdateRequestService: EntityUpdateRequestService,
        private _cdr: ChangeDetectorRef,
        private _subscriptionService: SubscriptionService,
        private _roleService: RolesService,
        private _widgetTemplateService: WidgetTemplatesService
    ) { }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.employeeEditForm.controls;
    }

    public ngOnInit(): void {
        this.permissions = this._authService?.permissions?.map(perm => perm.name);

        this.employeeEditForm = this._fb.group(
            {
                password: ['', [Validators.required, Validators.minLength(8)]],
                passwordConfirm: ['', [Validators.required, Validators.minLength(8)]]
            },
            {
                validators: passwordMatchValidator
            }
        );

        this.employeeTypeForm = this._fb.group({
            type: [null, Validators.required],
            role: [null, Validators.required],
            is_active: [true, Validators.required]
        });

        this.requestEmployeeChangeDataForm = this._fb.group({
            widget_template_ID: [0, Validators.required],
            require_update_message: [''],
        });

        this.helpEditForm = this._fb.group({
            help_mode: [false, Validators.required]
        });

        this.availableWidgets$ = this._companyService.availableWidgets$
            .pipe(tap((availableWidgets: AvailableWidgets) => {
                if (
                    availableWidgets.dashboard.attendance_cards.days_in_company ||
                    availableWidgets.dashboard.attendance_cards.holidays_left ||
                    availableWidgets.dashboard.attendance_cards.sick_days_left ||
                    availableWidgets.dashboard.attendance_cards.work_chart
                ) {
                    this.isAttendanceCardAvailable = true;
                }
            }));

        this.subscription$.subscribe(subscription => this.subscription = subscription);

        // on employeeID in rout change
        this._subscription = this._route.parent.params.subscribe(params => {
            this.employeeID = parseInt(params.id, 10);
            this.profileLoaded = false;
            const loggedEmployeeID = this._authService?.employee?.employee_ID;
            this.watchingMyself = loggedEmployeeID === this.employeeID;
            if (this.watchingMyself) {
                this.getRequiredDataUpdates(this.employeeID, true);
                this.getActiveRequests(this.employeeID);
            }

            this._cdr.detectChanges();

            this.employee$ = this._fetchEmployeeExtended$
                .pipe(
                    switchMap(() => this._employeeService.getEmployeeInfoByEmployeeID(this.employeeID, 'extend', true)),
                    withLatestFrom(this.availableWidgets$),
                    map(([employee, availableWidgets]) => {
                        if (availableWidgets.employee.attendance_cards.sick_days_left && employee.total_sick_days > 0) {
                            this.attendanceCols++;
                        }
                        if (availableWidgets.employee.attendance_cards.days_in_company) {
                            this.attendanceCols++;
                        }
                        if (availableWidgets.employee.attendance_cards.holidays_left) {
                            this.attendanceCols++;
                        }
                        if (availableWidgets.employee.attendance_cards.work_chart) {
                            this.attendanceCols++;
                        }

                        return {
                            ...employee,
                            ownedCompanyGroupNames: employee.owned_company_groups.map(group => group.name).join(', ')
                        };
                    }),
                    tap(employee => {
                        this.helpEditForm.patchValue({help_mode: employee.help_mode});
                        if (employee.employee_type === 'POTENTIAL') {
                            this.employeeTypeForm.controls.type?.setValue('COMMON');
                        } else {
                            this.employeeTypeForm.patchValue({type: employee.employee_type});
                        }
                        this.employee = employee;
                        this.profileLoaded = true;
                    }),
                    catchError(error => {
                        if (error.status === 404) {
                            this._router.navigateByUrl('/404').then(() => { });
                        }

                        return of(null);
                    }),
                    shareReplay()
                );

            this.employeeAddresses$ = this._addressService.getAllAddressesForEmployee(this.employeeID);

            this.employeePositions$ = this._positionService.getAllPositionsByEmployeeID(this.employeeID);

            this.employeeSalaries$ = this._salaryService.getAllSalariesByEmployeeID(this.employeeID);
        });

        this.employeeRoles$ = this._roleService.getAllRoles()
            .pipe(
                map(roles => {
                    const employeeRoles = roles.filter(role => role.guard_type === 'employee');
                    const employeeRole = roles.find(role => role.internal_name === 'employee');
                    this.employeeTypeForm.controls.role?.setValue(employeeRole?.role_ID);

                    return employeeRoles;
                })
            );

        this._fetchEmployeeExtended$.next();

        this.widgetTemplates$ = this._widgetTemplateService.getAllTemplates('select')
            .pipe(map(templates => {
                templates.push({id: 0, name: this._translateService.instant('widget_template.no_template')});
                if (this._widgetTemplateService.defaultWidgetTemplate?.id) {
                    this.requestEmployeeChangeDataForm.patchValue({widget_template_ID: this._widgetTemplateService.defaultWidgetTemplate.id});
                }
                return templates;
            }));

        // permissions
        this.canTerminate$ = this.employee$
            .pipe(
                map(employee => employee?.permissions?.createTermination && !employee.is_archived),
                shareReplay()
            );

        this.canViewPersonalData$ = this.employee$
            .pipe(
                map(employee => employee?.permissions?.viewPersonalData || employee?.permissions?.requestEditPersonalData),
                shareReplay()
            );

        this.canArchive$ = this.employee$.pipe(
            map(employee => employee?.permissions?.createTermination && !employee.is_archived),
            shareReplay()
        );

        this.canDelete$ = this.employee$.pipe(
            map(employee => employee?.permissions?.delete),
            shareReplay()
        );

        this.canEdit$ = combineLatest([
            this._authService.hasPermissionTo('employee.edit').pipe(map(permission => permission.can)),
            this._authService.hasPermissionTo('employee.editStructure').pipe(map(permission => permission.can))
        ])
            .pipe(
                map(([canEdit, canEditStructure]) => canEdit || canEditStructure),
                shareReplay()
            );

        this.canUnarchive$ = this.employee$.pipe(
            map(employee => employee?.permissions?.createTermination && employee.is_archived),
            shareReplay()
        );

        this.canCreate$ = this._authService.hasPermissionTo('scheduledTask.create')
            .pipe(map(permission => permission.can));

        this.employeeTypes$ = this._employeeService.getEmployeeTypes();

        this.isAdmin = this._authService.loggedUser?.role_level === 1;
    }

    public archiveEmployee(): void {
        if (this.employee.owned_company_groups.length > 0) {
            this._showConfirmDialog({
                showCancelButton: false,
                confirmButtonText: this._translateService.instant('swal.action_ok'),
                text: this._translateService.instant('employees.cannot_archive_owner_text'),
                title: this._translateService.instant('employees.action_archive')
            }).then(() => { });
            return;
        }
        if (this.employee.is_supervisor) {
            this._showConfirmDialog({
                showCancelButton: false,
                confirmButtonText: this._translateService.instant('swal.action_ok'),
                text: this._translateService.instant('employees.cannot_archive_supervisor_text'),
                title: this._translateService.instant('employees.action_archive')
            }).then(() => { });
            return;
        }

        const text = this.subscription.subscription.per_seats_pricing ? 'employees.action_archive_per_seat_text' : 'employees.action_archive_text';
        this._showConfirmDialog({
            cancelButtonText: this._translateService.instant('swal.action_cancel_delete'),
            confirmButtonText: this._translateService.instant('swal.action_confirm_delete'),
            text: this._translateService.instant(text),
            title: this._translateService.instant('employees.action_archive')
        })
            .then(result => {
                if (result.value) {
                    this._employeeService.archiveEmployee(this.employeeID)
                        .then(
                            () => this._fetchEmployeeExtended$.next(),
                            () => {
                            }
                        );
                }
            });
    }

    public deleteEmployee(): void {
        if (this.employee.owned_company_groups.length > 0) {
            this._showConfirmDialog({
                showCancelButton: false,
                confirmButtonText: this._translateService.instant('swal.action_ok'),
                text: this._translateService.instant('employees.cannot_delete_owner_text'),
                title: this._translateService.instant('employees.action_delete')
            }).then(() => { });
            return;
        }
        if (this.employee.is_supervisor) {
            this._showConfirmDialog({
                showCancelButton: false,
                confirmButtonText: this._translateService.instant('swal.action_ok'),
                text: this._translateService.instant('employees.cannot_delete_supervisor_text'),
                title: this._translateService.instant('employees.action_delete')
            }).then(() => { });
            return;
        }

        const text = this.subscription.subscription.per_seats_pricing ? 'employees.action_delete_per_seat_text' : 'employees.action_delete_text';
        this._showConfirmDialog({
            cancelButtonText: this._translateService.instant('swal.action_cancel_delete'),
            confirmButtonText: this._translateService.instant('swal.action_confirm_delete'),
            text: this._translateService.instant(text),
            title: this._translateService.instant('employees.action_delete')
        })
            .then(result => {
                if (result.value) {
                    this._employeeService.deleteEmployee(this.employeeID)
                        .then(
                            () => this._router.navigate(['/employee', 'list']),
                            () => this._fetchEmployeeExtended$.next()
                        );
                }
            });
    }

    public getActiveRequests(employeeID: number): void {
        this._entityUpdateRequestService.getAllRequests(employeeID, ['NEW'])
            .subscribe((sub: any) => {
                this.entityRequests = sub.data;
            });
    }

    public getRequiredDataUpdates(employeeID: number, scroll?: boolean): void {
        this._employeeService.getRequiredDataUpdates(employeeID)
            .subscribe(requiredUpdates => {
                this.requiredUpdates = requiredUpdates;
                if (scroll) {
                    this._route.fragment.subscribe(fragment => {
                        if (fragment) {
                            const checkInterval = setInterval(() => {
                                const element = document.getElementById(fragment);
                                if (element) {
                                    clearInterval(checkInterval);
                                    const headerOffset = 70;
                                    const elementPosition = element.getBoundingClientRect().top;
                                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: 'smooth'
                                    });
                                    this._location.replaceState(this._location.path(false));
                                }
                            }, 10);
                        }
                    });
                }
            });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.employeeEditForm.valid) {
            return;
        }

        this._employeeService.savePasswordByEmployeeID(this.f.password.value, this.f.passwordConfirm.value, this.employeeID)
            .then(
                () => this._modalService.dismissAll(),
                () => {
                }
            );

        this.employeeEditForm.patchValue({password: '', passwordConfirm: ''});

        this.submitted = false;
    }

    public onSubmitHelp(): void {
        this._employeeService.setHelpMode(this.helpEditForm.controls.help_mode.value, this.employeeID)
            .then(
                () => this._modalService.dismissAll(),
                () => {
                }
            );
    }

    public onEmployeeTypeSubmit(): void {
        let form = {type: this.employeeTypeForm.value.type};
        if (this.changeToCommon()) {
            form = this.employeeTypeForm.value;
            if (this.employeeTypeForm.invalid) {
                this.submitted = true;
                return;
            }
            this.submitted = false;
        }
        this._employeeService.saveEmployeeType(form, this.employeeID)
            .then(
                () => {
                    this._modalService.dismissAll();
                    this.refetchEmployee();
                },
                () => {
                }
            );
    }

    public onRequestEmployeeToUpdateDataSubmit(): void {
        const value = this.requestEmployeeChangeDataForm.value;
        if (value.widget_template_ID === 0) {
            value.widget_template_ID = null;
        }
        this._employeeService.forceEmployeeToUpdateData(this.requestEmployeeChangeDataForm.value, this.employeeID)
            .then(
                () => this._modalService.dismissAll(),
                () => {
                }
            );
    }

    public isButtonDisabled(): boolean {
        return this.requestEmployeeChangeDataForm.invalid;
    }

    public checkPermission(name: string, action = 'view'): boolean {
        if (action === 'view') {
            return (this.watchingMyself || this.permissions.includes(name + '.view') || this.permissions.includes(name + '.viewStructure') || this.permissions.includes(name + '.read'));
        }
        if (action === 'edit') {
            return (this.watchingMyself || this.permissions.includes(name + '.edit') || this.permissions.includes(name + '.editStructure'));
        }
    }

    public openAvatarChangeModal(): void {
        const modalRef = this._modalService.open(ChangeAvatarModalComponent, {centered: true});

        modalRef.componentInstance.employee = this.employee;
        modalRef.componentInstance.selectedAvatarUrl = this.employee.avatar;

        modalRef.result
            .then(
                () => this._fetchEmployeeExtended$.next(),
                () => {
                }
            );
    }

    public openBusinessCardModal(content: any): void {
        this._modalService.open(content, {centered: true});
    }

    public openEmployeeEditModal(): void {
        const modalRef = this._modalService.open(CreateEditEmployeeModalComponent, {centered: true});

        let updateRequested = false;
        if (this.entityRequests) {
            for (const request of this.entityRequests) {
                if (request.entity_type === 'App\\Models\\Employee') {
                    updateRequested = true;
                    break;
                }
            }
        }

        modalRef.componentInstance.employee = this.employee;
        modalRef.componentInstance.updateRequested = updateRequested;

        modalRef.result
            .then(
                () => this.refetchEmployee(),
                () => {
                }
            );
    }

    public openEntityUpdateRequestModal(entityUpdateRequest?: EntityUpdateRequest): void {
        const modalRef = this._modalService.open(EntityUpdateRequestModalComponent, {centered: true});
        if (entityUpdateRequest) {
            modalRef.componentInstance.entityUpdateRequest = entityUpdateRequest;
        }
    }

    public refetchEmployee(): void {
        if (this.requiredUpdates && this.requiredUpdates.employee_info === 'REQUESTED') {
            const data: { employee_info: string } = {employee_info: 'UPDATED'};
            this._employeeService.forceEmployeeToUpdateData(data, this.employee.employee_ID)
                .then(() => {
                    this._fetchEmployeeExtended$.next();
                    this.getRequiredDataUpdates(this.employee.employee_ID);
                    this.getActiveRequests(this.employee.employee_ID);
                });
        } else {
            this._fetchEmployeeExtended$.next();
            this.getActiveRequests(this.employee.employee_ID);
        }
    }

    public refuseUpdateData(): void {
        const data: { employee_info: string } = {employee_info: 'REFUSED'};
        this._employeeService.forceEmployeeToUpdateData(data, this.employee.employee_ID)
            .then(() => this.getRequiredDataUpdates(this.employee.employee_ID));
    }

    public openHelpModeChangeModal(content: any): void {
        this._modalService.open(content, {centered: true});
    }

    public openPasswordChangeModal(content: any): void {
        this._modalService.open(content, {centered: true});
    }

    public openEmployeeTypeModal(): void {
        this._employeeTypeModal = this._modalService.open(this.employeeTypeModal);
    }

    public changeToCommon(): boolean {
        return this.employeeTypeForm.value.type === 'COMMON';
    }

    public openRequestEmployeeChangeDataModal(): void {
        this._requestEmployeeChangeDataModal = this._modalService.open(this.requestEmployeeChangeDataModal);
    }

    public openTerminationModal(employee: Employee): void {
        const modalRef = this._modalService.open(CreateEmployeeTerminationModalComponent, {centered: true});

        modalRef.componentInstance.employee = employee;

        modalRef.result
            .then(
                () => this._fetchEmployeeExtended$.next(),
                () => {
                }
            );
    }

    public canShowBoth(availableWidgets: any): boolean {
        return availableWidgets?.contact_persons && availableWidgets?.address && this.checkPermission('employeeAddress') && this.checkPermission('employeeContactPerson');
    }

    public canShowSome(availableWidgets: any): boolean {
        return (availableWidgets?.contact_persons && this.checkPermission('employeeContactPerson')) || (availableWidgets?.address && this.checkPermission('employeeAddress'));
    }

    public unarchiveEmployee(): void {
        const text = this.subscription.subscription.per_seats_pricing ? 'employees.action_remove_from_archive_per_seat_text' : 'employees.action_remove_from_archive_text';
        this._showConfirmDialog({
            cancelButtonText: this._translateService.instant('swal.action_cancel_delete'),
            confirmButtonText: this._translateService.instant('swal.action_confirm_delete'),
            text: this._translateService.instant(text),
            title: this._translateService.instant('employees.action_remove_from_archive')
        })
            .then(result => {
                if (result.value) {
                    this._employeeService.unArchiveEmployee(this.employeeID)
                        .then(
                            () => this._fetchEmployeeExtended$.next(),
                            () => {
                            }
                        );
                }
            });
    }

    private _showConfirmDialog(options: ConfirmDialogOptions): Promise<SweetAlertResult> {
        return Swal.fire({
            ...DEFAULT_CONFIRM_DIALOG_OPTIONS,
            ...options
        });
    }

    public openEmployeeSupervisorModal(): void {
        const modalRef = this._modalService.open(EditEmployeeSupervisorComponent, {centered: true});

        modalRef.componentInstance.employee = this.employee;

        modalRef.result
            .then(
                () => this.refetchEmployee(),
                () => {
                }
            );
    }

    public openEmployeeChangeCompanyGroupModal(): void {
        const modalRef = this._modalService.open(ChangeEmployeeCompanyGroupModalComponent, {centered: true});

        modalRef.componentInstance.employee = this.employee;

        modalRef.result
            .then(
                () => this.refetchEmployee(),
                () => {}
            );
    }

    public openEmployeeActiveAccessModal(): void {
        const modalRef = this._modalService.open(ChangeEmployeeActiveAccessModalComponent, {centered: true});

        modalRef.componentInstance.employee = this.employee;

        modalRef.result
            .then(
                () => this.refetchEmployee(),
                () => {}
            );
    }

    public openActivityModal(): void {
        const modalRef = this._modalService.open(CreateEditEmployeeActivityModalComponent, {centered: true});

        modalRef.componentInstance.employeeID = this.employeeID;
    }

    public loginAsThisEmployee(employee: Employee): void {
        this._authService.loginAsSpecificEmployee(employee.employee_ID);
    }

    public isTerminatedInPast(): boolean {
        return this.employee?.permissions?.createTermination && this.employee.termination?.terminated && new Date(this.employee.termination?.termination_end_date) < new Date();
    }

    public downloadProfilePdf(employeeId: number, employeeName: string): void {
        this._employeeService.downloadProfilePdf(employeeId, employeeName);
    }
}
