import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { IPendo, NGX_PENDO_CONTEXT } from 'ngx-pendo';
import { combineLatest, merge, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import Smartlook from 'smartlook-client';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { localStorageSafe } from 'src/app/shared/functions';
import { HelpKeys } from 'src/app/shared/models/HelpKeys';
import { AppService } from 'src/app/shared/services/app.service';
import { HelpService } from 'src/app/shared/services/help.service';
import { ChatService } from 'src/app/ui/chat/services/chat.service';
import { environment } from 'src/environments/environment';
import { Help } from '../../../../help/state/help.model';
import { FirebaseService } from '../../../../shared/services/firebase.service';
import { IOS_DEPLOY_HIDE } from '../../../constants';
import { LoggedInUser } from '../../../models/LoggedUser';
import { WelcomeModalComponent } from '../../welcome-modal/welcome-modal.component';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css']
})
export class MainComponent implements OnDestroy, OnInit {
    public refreshLoggedUser = new Subject<void>();
    public helpKey: keyof HelpKeys | null;
    public pageTitle$: Observable<string>;
    public helpData: Help;
    public helpMode: boolean;
    public loggedInUser = this._authService.loggedUser;

    private _allHelpData$: Observable<Array<Help>>;
    private _helpModalTemplate$ = new ReplaySubject<TemplateRef<ElementRef>>(1);
    private _helpSubscription: Subscription;

    @HostListener('window:unload')
    public windowOnUnload(): void {
        localStorageSafe.setItem('user_last_active_before_unload', new Date(localStorageSafe.getItem('user_last_active')).toString());
    }

    public constructor(
        @Inject(NGX_PENDO_CONTEXT) private _pendo: IPendo,
        private _appService: AppService,
        private _authService: AuthenticationService,
        private _chatService: ChatService,
        private _helpService: HelpService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _router: Router,
        private _titleService: Title,
        private _translateService: TranslateService,
        private _firebaseService: FirebaseService,
    ) { }

    @ViewChild('helpModal')
    public set helpModal(helpModalTemplate: TemplateRef<ElementRef>) {
        this._helpModalTemplate$.next(helpModalTemplate);
    }

    public ngOnDestroy(): void {
        this._helpSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.setPageTitle();

        this._allHelpData$ = this._helpService.helpData$;

        this._setupHelp();

        this._setupWelcomeModal();

        const isImpersonationLogin = localStorageSafe.getItem('impersonation_admin') === 'true';

        if (this.loggedInUser) {
            this.helpMode = this.loggedInUser.help_mode;
            this.setSentryContextData(this.loggedInUser);
            this.identifySmartlookUser(this.loggedInUser);
            this.identifyPendoUser(this.loggedInUser);
            this._chatService.initialize(this.loggedInUser);
            if (!environment.production) {
                this._appendScripts(this.loggedInUser);
            }
            if (environment.production) {
                if (!isImpersonationLogin && !this.loggedInUser?.show_welcome_modal) {
                    this._firebaseService.initialize();
                }
            }
        }
    }

    public setSentryContextData(loggedUser: LoggedInUser): void {
        Sentry.setContext('Logged User', {
            ID: loggedUser.central_user_ID.toString() + '-' + loggedUser.workspace.tenant_ID,
            Name: loggedUser.fullname,
            Email: loggedUser.email
        });
        Sentry.setContext('Tenant', {
            ID: loggedUser.workspace?.tenant_ID,
            Label: loggedUser.workspace?.label
        });
    }

    public identifySmartlookUser(loggedUser: LoggedInUser): void {
        Smartlook.identify(loggedUser.central_user_ID.toString() + '-' + loggedUser.workspace.tenant_ID, {
            name: loggedUser.fullname,
            email: loggedUser.email,
            tenant_ID: loggedUser.workspace?.tenant_ID,
            tenant_name: loggedUser.workspace?.label
        });
    }

    public identifyPendoUser(loggedUser: LoggedInUser): void {
        if (this._pendo !== undefined) {
            this._pendo.initialize({
                visitor: {
                    id: loggedUser.workspace?.tenant_ID,
                    name: loggedUser.fullname,
                    email: loggedUser.email,
                    role: loggedUser.role,
                    trialing: loggedUser.trialing.toString(),
                }, account: {
                    id: loggedUser.workspace?.tenant_ID,
                    name: loggedUser.workspace?.label
                }
            });
        }
    }

    public setPageTitle(): void {
        const titleFromSnapshot = this._route.snapshot.firstChild.firstChild?.data?.title || this._route.snapshot.firstChild.data?.title;
        const translatedTitleFromSnapshot = this._translateService.instant(titleFromSnapshot || 'menu.jeste_neni_klic');

        this.pageTitle$ = merge(
            of(translatedTitleFromSnapshot),
            this._appService.pageTitleObject
                .pipe(
                    map(titleObject => titleObject.label),
                    switchMap(title => {
                        if (title) {
                            return of(title);
                        } else {
                            return this._getTitleFromRouterEvent();
                        }
                    })
                )
        )
            .pipe(filter(title => title !== null));
    }

    public openHelpModal(template: TemplateRef<any>): void {
        this._modalService.open(template, {size: 'lg'});
    }

    private _getTitleFromRouterEvent(): Observable<string> {
        return this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this._route),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }

                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data),
                map(event => {
                    let pageTitle: string;

                    try {
                        pageTitle = this._translateService.instant(event.title);
                    } catch (error) {
                        pageTitle = '';
                    }

                    this._titleService.setTitle('Speybl | ' + pageTitle);

                    this._authService.pageTitle.next(event.title);

                    return pageTitle;
                })
            );
    }

    private _setupWelcomeModal(): void {
        this._route.queryParams
            .pipe(
                switchMap(routeParams => {
                    const isImpersonationLogin = localStorageSafe.getItem('impersonation_admin') === 'true';
                    if (this.loggedInUser?.show_welcome_modal && !isImpersonationLogin && !IOS_DEPLOY_HIDE) {
                        const modal = this._modalService.open(WelcomeModalComponent, {
                            size: 'lg',
                            centered: true,
                            container: '.welcome-modal-container',
                            backdrop: 'static',
                            keyboard: false
                        });

                        modal.componentInstance.loggedInUser = this.loggedInUser;

                        modal.result
                            .then((refreshProfile: boolean) => {
                                if (refreshProfile) {
                                    this.refreshLoggedUser.next();
                                }
                            });
                    }
                    return of(null);
                })
            ).subscribe();
    }

    private _setupHelp(): void {

        this._helpSubscription = combineLatest([
            this._helpService.helpKey$,
            this._allHelpData$,
        ])
            .pipe(
                switchMap(([helpKey, allHelpData]) => {
                    if (helpKey && this.helpMode) {
                        this.helpKey = helpKey;
                        this.helpData = allHelpData.find(help => help.external_ID === this.helpKey);

                        const event = new Event('page_opened.' + this.helpKey);
                        window.dispatchEvent(event);

                        return of(null);
                    } else {
                        return of(null);
                    }
                })
            )
            .subscribe();
    }

    private _appendScripts(loggedUser: LoggedInUser): void {
        const script1 = document.createElement('script');
        const noscript1 = document.createElement('noscript');
        const script3 = document.createElement('script');
        const script4 = document.createElement('script');
        script1.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NN5NFQB9');`;
        noscript1.innerHTML = `<iframe src="
            https://www.googletagmanager.com/ns.html?id=GTM-NN5NFQB9"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        script3.text = `window.usetifulTags = { userId : "${loggedUser.central_user_ID.toString() + '-' + loggedUser.workspace.tenant_ID}"};`;
        script4.text = '(function (w, d, s) {\n' +
            '    var a = d.getElementsByTagName(\'head\')[0];\n' +
            '    var r = d.createElement(\'script\');\n' +
            '    r.async = 1;\n' +
            '    r.src = s;\n' +
            '    r.setAttribute(\'id\', \'usetifulScript\');\n' +
            `    r.dataset.token = "${this._translateService.currentLang === 'cs' ? 'ebf67a12f657e7081aaeea6cbc9270e0' : '2200447c71612cd96e79fd45aea6003c'}";\n` +
            '                        a.appendChild(r);\n' +
            '  })(window, document, "https://www.usetiful.com/dist/usetiful.js");';

        document.head.prepend(script1);
        document.body.prepend(noscript1);
        document.body.appendChild(script3);
        document.body.appendChild(script4);
    }
}
