<div class="row">
    <div class="col-12">
        <div class="card shadow">
            <app-help name="settings.attendance.types"></app-help>

            <div class="card-header py-3">
                <div class="row">
                    <div class="col-sm-6 text-left my-auto">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ 'employees_datetimes_types.page_title_index' | translate }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <app-data-table
                                [disableInPlaceFiltering]="true"
                                [disableInPlaceSorting]="true"
                                [loading]="loading"
                                [rows]="rows"
                                [search]="search"
                                [sort]="sort"
                                [trackByFn]="trackByFn"
                                (searchChanged)="searchChanged($event)"
                                (sortChanged)="sortChanged($event)"
                            >
                                <ng-template #headerButton *ngIf="canCreate$ | async">
                                    <button
                                        class="ml-sm-2"
                                        [createButton]="'employees_datetimes_types.action_create' | translate"
                                        (click)="openEditModal()"
                                    ></button>
                                </ng-template>

                                <ng-template #headerRow>
                                    <th appDataTableHeaderCell="name">{{ 'employees_datetimes_types.name' | translate }}</th>
                                    <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes_types.color' | translate }}</th>
                                    <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes_types.type' | translate }}</th>
                                    <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes_types.worktime_classification' | translate }}</th>
                                    <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes_types.is_approvable' | translate }}</th>
                                    <th appDataTableHeaderCell="created_at">{{ 'employees_datetimes_types.created_at' | translate }}</th>
                                    <th appDataTableHeaderCell class="text-right" [disableSort]="true">{{ 'employees_datetimes_types.table_action' | translate }}</th>
                                </ng-template>

                                <ng-template #dataRow let-row>
                                    <td appDataTableDataCell [class.text-primary]="row.can_delete">
                                        <span *ngIf="row.is_active">{{ row.name }} [{{ row.abbreviation ?? '-' }}]</span>
                                        <s *ngIf="!row.is_active">{{ row.name }} [{{ row.abbreviation ?? '-' }}]</s>
                                    </td>
                                    <td appDataTableDataCell>
                                        <svg class="bar-group rounded-sm" width="20" height="20">
                                            <rect class="bar-progress" height="20" width="20" y="0" x="0"
                                                  [style.fill]="row.color">
                                            </rect>

                                            <ng-container *ngIf="row.is_shift">
                                                <pattern id="diaHatch{{row.employee_datetime_type_ID}}" width="4" height="1" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
                                                    <rect x="0" y="0" width="2" height="1" [style.fill]="getStripeColor(row.color)"/>
                                                </pattern>
                                                <rect class="bar-progress" height="20" width="20" y="0" x="0"
                                                      [style.fill]="'url(#diaHatch'+row.employee_datetime_type_ID+')'">
                                                </rect>
                                            </ng-container>
                                        </svg>
                                    </td>
                                    <td appDataTableDataCell>
                                        <div *ngIf="row.can_plan">{{ 'employees_datetimes_types.can_plan' | translate }}</div>
                                        <div *ngIf="row.timer">{{ 'employees_datetimes_types.timer' | translate }}</div>
                                        <div *ngIf="row.is_shift">{{ 'employees_datetimes_types.is_shift' | translate }}</div>
                                    </td>
                                    <td appDataTableDataCell [class.text-primary]="row.can_delete">
                                        {{ 'employees_datetimes_types.classification.'+row.worktime_classification | translate }}
                                    </td>
                                    <td appDataTableDataCell [class.text-primary]="row.can_delete">
                                        <i class="mdi mdi-24px"
                                           [class.mdi-check]="row.is_approvable"
                                           [class.mdi-close]="!row.is_approvable"></i>
                                    </td>
                                    <td appDataTableDataCell>
                                        {{ row.created_at | date:'dd.MM.yyyy' }}
                                    </td>
                                    <td appDataTableDataCell class="text-right">
                                        <button *ngIf="row.internal_type !== 'PAUSE'" class="btn btn-white btn-icon btn-sm mr-1" (click)="openMultipleCreationModal(row)">
                                            <i class="mdi mdi-plus-box-multiple-outline mdi-24px text-muted"></i>
                                        </button>
                                        <button class="btn btn-white btn-icon btn-sm mr-1" (click)="openEditModal(row)">
                                            <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                        </button>
                                    </td>
                                </ng-template>
                            </app-data-table>
                        </div>
                    </div>
                </div>

                <div class="row mt-3 flex-column-reverse flex-sm-row">
                    <div class="col-12 col-sm-2">
                        <app-items-per-page-select
                            [itemsPerPage]="itemsPerPage"
                            [total]="itemsTotal"
                            (itemsPerPageChanged)="itemsPerPageChanged($event)"
                        ></app-items-per-page-select>
                    </div>

                    <div class="col-12 col-sm-10">
                        <app-pagination
                            [itemsPerPage]="itemsPerPage"
                            [page]="page"
                            [total]="itemsTotal"
                            (pageChanged)="pageChanged($event)"
                        ></app-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
