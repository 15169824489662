import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KPIMetric } from 'src/app/kpi/kpi/state/kpi-metric.model';
import { KPI } from 'src/app/kpi/kpi/state/kpi.model';
import { KPIService } from 'src/app/kpi/kpi/state/kpi.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';

@Component({
    selector: 'app-create-edit-kpi-metric-modal',
    templateUrl: './create-edit-kpi-metric-modal.component.html',
    styleUrls: ['./create-edit-kpi-metric-modal.component.css']
})
export class CreateEditKpiMetricModalComponent implements OnInit {
    public submitted = false;
    public kpiMetricEditForm: UntypedFormGroup;
    public kpiMetric: KPIMetric;
    public kpi: KPI;
    public canEdit = false;
    public canDelete = false;

    constructor(
        private _kpiService: KPIService,
        public activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _authService: AuthenticationService,
    ) { }

    public ngOnInit(): void {
        this.canEdit = this._authService.checkPermission('kpi.edit');
        this.canDelete = this._authService.checkPermission('kpi.delete');
        this.kpiMetricEditForm = this._fb.group({
            name: ['', Validators.required],
            type: ['rating', Validators.required],
            weight: ['25', Validators.required],
            advanced: [false, Validators.required],
            min: [0],
            max: [10],
            threshold_count: ['2'],
            threshold_1: ['33.34'],
            threshold_2: ['66.66'],
            description: [''],
        });

        if (this.kpiMetric) {
            this.kpiMetricEditForm.patchValue({
                name: this.kpiMetric.name,
                type: this.kpiMetric.type,
                advanced: this.kpiMetric.advanced,
                weight: this.kpiMetric.weight,
                min: this.kpiMetric.min,
                max: this.kpiMetric.max,
                threshold_1: this.kpiMetric.threshold_1,
                threshold_2: this.kpiMetric.threshold_2,
                description: this.kpiMetric.description,
            });

            if (this.kpiMetric.threshold_2) {
                this.kpiMetricEditForm.patchValue({threshold_count: '2'});
            } else if (this.kpiMetric.threshold_1) {
                this.kpiMetricEditForm.patchValue({threshold_count: '1'});
            } else {
                this.kpiMetricEditForm.patchValue({threshold_count: 'NONE'});
            }
        }

        if (!this.canEdit) {
            this.kpiMetricEditForm.disable();
        }
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.kpiMetricEditForm.controls;
    }

    public onSubmit(): void {
        this.submitted = true;
        if (!this.kpiMetricEditForm.valid) {
            return;
        }

        if (this.kpiMetricEditForm.value.threshold_count === 'NONE') {
            this.kpiMetricEditForm.removeControl('threshold_1');
            this.kpiMetricEditForm.removeControl('threshold_2');
        }

        this._kpiService.saveKPIMetric(
            this.kpiMetricEditForm.value,
            this.kpi.kpi_ID,
            this.activeModal,
            this.kpiMetric?.kpi_metric_ID,
        );
    }

    public deleteKPIMetric(): void {
        void this._kpiService.deleteKPIMetric(this.kpi.kpi_ID, this.kpiMetric.kpi_metric_ID, this.activeModal);
    }
}
