import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: 'button[formGroup]'
})
export class FilterChangedDirective implements AfterViewInit, OnDestroy {
    @Input() public formGroup: UntypedFormGroup;

    private _controlsSubscription: Subscription;

    constructor(
        public el: ElementRef,
    ) { }

    public ngOnDestroy(): void {
        this._controlsSubscription?.unsubscribe();
    }

    public ngAfterViewInit(): void {
        // check for changes on init, because some components refreshes themselves
        this._checkFormChanges(this.formGroup.value);

        // check for changes every time the form changes value
        this._controlsSubscription = this.formGroup.valueChanges.subscribe((formValue: { [control: string]: any }) => {
            this._checkFormChanges(formValue);
        });
    }

    private _checkFormChanges(formValue: { [p: string]: any }): void {
        const defaultValue = this.formGroup[`defaultValue`] ?? formValue;
        const valueArr = Object.entries(formValue);
        let diffFound = false;

        valueArr.forEach(([key, val]) => {
            if (!diffFound) {
                if ((val === null || val === '') && (defaultValue[key] === null || defaultValue[key] === '')) {
                    return;
                }
                // if the value is an array, we have to check if all the elements are the same
                if (val && typeof val === 'object') {
                    if (!defaultValue[key]?.every((v: any) => val?.includes(v)) && defaultValue[key]?.length) {
                        diffFound = true;
                        return;
                    }
                    if (!val.every((v: any) => defaultValue[key]?.includes(v)) && val.length) {
                        diffFound = true;
                        return;
                    }
                }
                // strings, numbers, booleans ...
                if (typeof val !== 'object' && val !== defaultValue[key]) {
                    diffFound = true;
                    return;
                }
            }
        });

        this.el.nativeElement.classList.toggle('btn--active-dot', diffFound);
    }
}
