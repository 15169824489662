import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment/moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyGroupService } from '../../../../company-group/state/company-group.service';
import { CreateEditEmployeeDatetimeModalComponent } from '../../../../employee/components/partials/create-edit-employee-datetime-modal/create-edit-employee-datetime-modal.component';
import { Employee } from '../../../../employee/models/Employee';
import { AttendanceService } from '../../../../employee/services/attendance.service';
import { EmployeeDatetimeType } from '../../../../setting/models/EmployeeDatetimeType';
import { DatetimesTypesStoreService } from '../../../../setting/services/datetimes-types-store.service';
import { FlatpickrHelper } from '../../../common/FlatpickrHelper';
import { localStorageSafe } from '../../../functions';
import { GanttCategories } from '../../../models/GanttCategories';
import { FlatpickrLocaleService } from '../../../services/flatpickr-locale.service';

@Component({
    selector: 'app-plan-chart',
    templateUrl: './plan-chart.component.html',
    styleUrls: ['./plan-chart.component.css'],
})

export class PlanChartComponent implements OnInit {
    @ViewChild('filterFormOptions', {static: false})
    public filterFormOptions: TemplateRef<ElementRef>;

    @Input() public from: string;
    @Input() public to: string;
    @Input() public showSwitch = false;
    @Input() public isShift = false;

    @Output() public refetchRequests = new EventEmitter<string>();

    public availableCompanyGroups: Array<any>;
    public filterForm: UntypedFormGroup;
    public loadedCompanyGroups: Array<any> = [];
    public loading: Array<boolean> = [];
    public planCategories: Array<GanttCategories> = [];
    public planData: Array<any> = [];
    public usedCompanyGroups: Array<any>;
    public chartLoading = true;
    public svgSetting = {
        width: 256,
        height: 64,
        fontSize: 8,
        axesTopMargin: 5,
        axesBottomMargin: 5,
        datetimeBoxRoundedCorner: 5,
        datetimeBoxStrokeThickness: 1,
        startHour: 7,
        endHour: 17,
        axes: [], // COMPUTED
        fullHeight: 0, // COMPUTED
        actualHeight: 0, // COMPUTED
        plannedHeight: 0, // COMPUTED
        topY: 0, // COMPUTED
        midY: 0, // COMPUTED
        bottomY: 0, // COMPUTED
        actualTopY: 0, // COMPUTED
        plannedTopY: 0, // COMPUTED
    };
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public datetimeTypes$: Observable<EmployeeDatetimeType[]>;

    private _filterFormModalRef: NgbModalRef;

    public constructor(
        public fpHelper: FlatpickrHelper,
        private _attendanceService: AttendanceService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _groupService: CompanyGroupService,
        private _modalService: NgbModal,
        private _router: Router,
        private _datetimeTypesStore: DatetimesTypesStoreService,
    ) {
        this.filterForm = this._fb.group({
            from: [this.from],
            to: [this.to],
            group_by_company_group: [null],
            expend_all_company_groups: [false],
            types: [null]
        });

        this.filterForm[`defaultValue`] = this.filterForm.value;
    }

    public ngOnInit(): void {
        this.datetimeTypes$ = this._datetimeTypesStore.datetimeTypes$
            .pipe(
                map(types => {
                    return types.filter(type => type.is_shift === this.isShift);
                }));

        this.filterForm.patchValue({
            from: this.from,
            to: this.to
        });

        this.calculateSvgSettings();
        this._groupService.getByEmployee(null)
            .subscribe(result => {
                this.availableCompanyGroups = result?.groups;
                this.usedCompanyGroups = result?.groups;
                const availableCompanyGroupsIds = this.availableCompanyGroups.map(group => group.company_group_ID);
                this.refreshLoadedData(availableCompanyGroupsIds);
            });
    }

    public refreshLoadedData(companyGroupIDs: any[]): void {
        const form = this.filterForm.value;
        this.loadedCompanyGroups = companyGroupIDs;
        this._attendanceService.getDateTimesForGanttChart(form.from || this.from, form.to || this.to, this.isShift, companyGroupIDs, null, true, null, null, form.types)
            .subscribe(sub => {
                this.planCategories = this.addStartEndTimestamps(sub?.categories);
                this.planData = sub?.data;
                this.chartLoading = false;
            });
    }

    public hexToSemiTransparent(hexColor: string, opacity: number): string {
        let rgbColor: { r: number; b: number; g: number };
        if (hexColor.startsWith('rgba')) {
            rgbColor = this.getRGBValuesFromRGBA(hexColor);
        } else {
            rgbColor = this.hexToRgb(hexColor);
        }
        return `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${opacity})`;
    }

    public calculateX(startTimestamp: number, endTimestamp: number, partStartTimestamp: number): number {
        const stepSize = (endTimestamp - startTimestamp) / this.svgSetting.width;
        return Math.max((partStartTimestamp - startTimestamp) / stepSize, this.svgSetting.datetimeBoxStrokeThickness);
    }

    public calculateWidth(startTimestamp: number, endTimestamp: number, partStartTimestamp: number, partEndTtimestamp: number): number {
        const x = this.calculateX(startTimestamp, endTimestamp, partStartTimestamp);
        const stepSize = (endTimestamp - startTimestamp) / this.svgSetting.width;
        const width = (partEndTtimestamp - Math.max(startTimestamp, partStartTimestamp)) / stepSize - (x === this.svgSetting.datetimeBoxStrokeThickness ? 2 * this.svgSetting.datetimeBoxStrokeThickness : 0);
        return width >= this.svgSetting.width ? this.svgSetting.width - x : Math.max(width, 1);
    }

    public getStripeColor(color: string) {
        let rgbColor: { r: number; b: number; g: number };
        if (color.startsWith('rgba')) {
            rgbColor = this.getRGBValuesFromRGBA(color);
        } else {
            rgbColor = this.hexToRgb(color);
        }

        // Calculate color brightness
        const brightness = (rgbColor.r * 299 + rgbColor.g * 587 + rgbColor.b * 114) / 1000;

        // Return true if color is light, false if color is dark
        return brightness > 128 ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.3)';
    }

    public openFilterFormOptions(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormOptions);
    }

    public onSubmit(): void {
        this._filterFormModalRef?.close();
        this.refreshLoadedData(this.loadedCompanyGroups);
    }

    public openDatetimeEditModal(box: any, employee: Employee, highlight: boolean, showModal: boolean): void {

        if (highlight || showModal) {
            if (box.type === 'EMPTY') {
                const modalRef = this._modalService.open(CreateEditEmployeeDatetimeModalComponent, {centered: true});

                modalRef.componentInstance.employeeID = employee.employee_ID;
                modalRef.componentInstance.start = moment.unix(box.start_timestamp).format('YYYY-MM-DD');
                modalRef.componentInstance.end = moment.unix(box.end_timestamp).format('YYYY-MM-DD');
                modalRef.componentInstance.isShift = this.isShift;
                modalRef.componentInstance.isPlan = true;

                modalRef.result
                    .then(
                        () => {
                            this._attendanceService.refreshTimestamps$.next();
                            this.refreshLoadedData(this.loadedCompanyGroups);
                            this.refetchRequests.emit('refetch');
                        }, () => {}
                    );
            } else {
                const modalRef = this._modalService.open(CreateEditEmployeeDatetimeModalComponent, {centered: true});
                modalRef.componentInstance.employeeID = employee.employee_ID;
                modalRef.componentInstance.isShift = this.isShift;
                modalRef.componentInstance.isLoading = true;
                modalRef.componentInstance.isPlan = true;

                this._attendanceService.getEmployeeDateTimeByID(employee.employee_ID, box.employee_datetime_ID, 'extend')
                    .subscribe(dateTime => {
                        modalRef.componentInstance.employeeDateTime = dateTime;
                        modalRef.componentInstance.init();
                        modalRef.componentInstance.isLoading = false;

                        modalRef.result
                            .then(
                                () => {
                                    this._attendanceService.refreshTimestamps$.next();
                                    this.refreshLoadedData(this.loadedCompanyGroups);
                                    this.refetchRequests.emit('refetch');
                                },
                                () => {}
                            );
                    });
            }
        }
    }

    public switchToGannt(): void {
        const url = `/employee/attendance/${this.isShift ? 'shifts/chart' : 'chart'}?type=gannt`;
        this._router.navigateByUrl(url);
        localStorageSafe.setItem(`${this.isShift ? 'shifts' : 'attendance'}_chart_type`, 'gannt');
    }

    private addStartEndTimestamps(categories: Array<GanttCategories>): Array<GanttCategories> {
        categories.forEach((obj) => {
            const date = new Date(obj.iso);
            const startTimestamp = new Date(date.getFullYear(), date.getMonth(), date.getDate(), this.svgSetting.startHour, 0, 0).getTime();
            const endTimestamp = new Date(date.getFullYear(), date.getMonth(), date.getDate(), this.svgSetting.endHour, 0, 0).getTime();
            obj.start_timestamp = startTimestamp / 1000;
            obj.end_timestamp = endTimestamp / 1000;
        });
        return categories;
    }

    private getRGBValuesFromRGBA(rgba: string): { r: number, g: number, b: number } {
        const regex = /^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)/i;
        const match = rgba.match(regex);

        if (match) {
            const [, r, g, b] = match;
            return {r: parseInt(r, 10), g: parseInt(g, 10), b: parseInt(b, 10)};
        }

        throw new Error('Invalid RGBA string format');
    }

    private hexToRgb(hexColor: string): { r: number, g: number, b: number } {
        hexColor = hexColor.replace('#', '');

        const red = parseInt(hexColor.substring(0, 2), 16);
        const green = parseInt(hexColor.substring(2, 4), 16);
        const blue = parseInt(hexColor.substring(4, 6), 16);

        return {r: red, g: green, b: blue};
    }

    private calculateSvgSettings(): void {
        this.svgSetting.fullHeight = (this.svgSetting.height - this.svgSetting.axesBottomMargin) - (this.svgSetting.fontSize + 2 + this.svgSetting.axesTopMargin);
        this.svgSetting.actualHeight = this.svgSetting.fullHeight * 0.5;
        this.svgSetting.plannedHeight = this.svgSetting.fullHeight * 0.85;
        this.svgSetting.topY = this.svgSetting.fontSize + 2 + this.svgSetting.axesTopMargin;
        this.svgSetting.bottomY = this.svgSetting.height - this.svgSetting.axesBottomMargin;
        this.svgSetting.midY = this.svgSetting.topY + (this.svgSetting.bottomY - this.svgSetting.topY) / 2;
        this.svgSetting.actualTopY = this.svgSetting.midY - this.svgSetting.actualHeight / 2;
        this.svgSetting.plannedTopY = this.svgSetting.midY - this.svgSetting.plannedHeight / 2;

        let index = 1;
        const hoursCount = this.svgSetting.endHour - this.svgSetting.startHour;
        for (let hour = this.svgSetting.startHour + 1; hour < this.svgSetting.endHour; hour++) {
            this.svgSetting.axes.push({label: hour.toString().padStart(2, '0') + ':00', x: this.svgSetting.width / (hoursCount) * (index++), primary: hour % 2 === 0});
        }
    }
}
