import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { arrayAdd, arrayRemove, arrayUpdate } from '@datorama/akita';

import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';

import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { EmployeeOnboardingTask } from './employee-onboarding-task.model';

import { EmployeeOnboarding } from './employee-onboarding.model';
import { EmployeeOnboardingStore } from './employee-onboarding.store';

@Injectable({
    providedIn: 'root'
})
export class EmployeeOnboardingService {
    public constructor(
        public _employeeOnboardingStore: EmployeeOnboardingStore,
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _swalHelper: SwalHelper,
        private _loaderService: NgxUiLoaderService
    ) { }

    public completeTask(employeeOnboardingID: number | string, employeeOnboardingTask: EmployeeOnboardingTask): void {
        this._loaderService.start();
        this._http.post(`/api/employees-onboardings/${employeeOnboardingID}/tasks/${employeeOnboardingTask.employee_onboarding_task_ID}/complete`, {})
            .subscribe(
                (response: any) => {
                    this._apiHelper.handleSuccessResponse(response);
                    this._loaderService.stop();

                    this._employeeOnboardingStore.update(
                        employeeOnboardingID,
                        originalOnboarding => {
                            const allTasksCount = originalOnboarding.tasks.length;
                            const completedTasksCount = originalOnboarding.tasks.filter(t => t.completed_at).length + 1;
                            const percentProgress = (completedTasksCount / allTasksCount) * 100;

                            return {
                                ...originalOnboarding,
                                tasks: arrayUpdate(
                                    originalOnboarding.tasks,
                                    employeeOnboardingTask.employee_onboarding_task_ID,
                                    {
                                        ...employeeOnboardingTask,
                                        completed_at: moment().format('YYYY-MM-DD')
                                    },
                                    'employee_onboarding_task_ID'
                                ),
                                percent_progress: percentProgress
                            };
                        });
                },
                error => this._apiHelper.handleErrorResponse(error)
            );
    }

    public async deleteTask(employeeOnboarding: EmployeeOnboarding, employeeOnboardingTask: EmployeeOnboardingTask): Promise<void> {
        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();
        const employeeOnboardingID = employeeOnboarding.employee_onboarding_ID;
        const employeeOnboardingTaskID = employeeOnboardingTask.employee_onboarding_task_ID;

        if (deleteConfirmed) {
            this._loaderService.start();
            this._http.delete<ApiResponse>(`/api/employees-onboardings/${employeeOnboardingID}/tasks/${employeeOnboardingTaskID}`)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);
                        this._loaderService.stop();

                        this._employeeOnboardingStore.update(
                            employeeOnboardingID,
                            originalOnboarding => {
                                if (!employeeOnboardingTask.completed_at) {
                                    const allTasksCount = originalOnboarding.tasks.length - 1;
                                    const completedTasksCount = originalOnboarding.tasks.filter(t => t.completed_at).length;
                                    const percentProgress = (completedTasksCount / allTasksCount) * 100;

                                    return {
                                        ...originalOnboarding,
                                        tasks: arrayRemove(
                                            originalOnboarding.tasks,
                                            employeeOnboardingTaskID,
                                            'employee_onboarding_task_ID'
                                        ),
                                        percent_progress: percentProgress
                                    };
                                } else {
                                    const allTasksCount = originalOnboarding.tasks.length - 1;
                                    const completedTasksCount = originalOnboarding.tasks.filter(t => t.completed_at).length - 1;
                                    const percentProgress = (completedTasksCount / allTasksCount) * 100;

                                    return {
                                        ...originalOnboarding,
                                        tasks: arrayRemove(
                                            originalOnboarding.tasks,
                                            employeeOnboardingTaskID,
                                            'employee_onboarding_task_ID'
                                        ),
                                        percent_progress: percentProgress
                                    };
                                }
                            }
                        );
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        }
    }

    public get(employeeID: number, includeCompleted = false): Observable<void> {
        let url = `/api/employees/${employeeID}/onboardings`;
        if (includeCompleted) {
            url = url + `?include_completed=1`;
        }
        return this._http.get<Array<EmployeeOnboarding>>(url)
            .pipe(map(response => this._employeeOnboardingStore.set(response)));
    }

    public getEmployeeOnboarding(employeeID: number, includeCompleted?: boolean): Observable<Array<EmployeeOnboarding>> {
        let url = `/api/employees/${employeeID}/onboardings`;
        if (includeCompleted) {
            url = url + `?include_completed=1`;
        }
        return this._http.get<Array<EmployeeOnboarding>>(url);
    }

    public getActiveEmployeeOnboardings(): Observable<Array<EmployeeOnboarding>> {
        return this._http.get<Array<EmployeeOnboarding>>('/api/employees-onboardings/active-onboardings');
    }

    public saveEmployeeOnboardingTask(
        form: UntypedFormGroup,
        employeeOnboarding: EmployeeOnboarding,
        employeeOnboardingTask: EmployeeOnboardingTask
    ): void {
        let apiUrl = `/api/employees-onboardings/${employeeOnboarding.employee_onboarding_ID}/tasks`;

        if (employeeOnboardingTask) {
            apiUrl += `/${employeeOnboardingTask.employee_onboarding_task_ID}`;
        }

        this._http.post<ApiResponse & { task: EmployeeOnboardingTask; }>(apiUrl, form.value)
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);

                    if (!employeeOnboardingTask) {
                        form.reset();
                    }

                    this._employeeOnboardingStore.update(
                        employeeOnboarding.employee_onboarding_ID,
                        originalOnboarding => {
                            if (!employeeOnboardingTask) {
                                const allTasksCount = originalOnboarding.tasks.length + 1;
                                const completedTasksCount = originalOnboarding.tasks.filter(t => t.completed_at).length;
                                const percentProgress = (completedTasksCount / allTasksCount) * 100;

                                return {
                                    ...originalOnboarding,
                                    tasks: arrayAdd(originalOnboarding.tasks, response.task),
                                    percent_progress: percentProgress
                                };
                            } else {
                                return {
                                    ...originalOnboarding,
                                    tasks: arrayUpdate(
                                        originalOnboarding.tasks,
                                        employeeOnboardingTask.employee_onboarding_task_ID,
                                        {
                                            ...employeeOnboardingTask,
                                            name: form.value.name
                                        },
                                        'employee_onboarding_task_ID'
                                    ),
                                };
                            }
                        });
                },
                error => this._apiHelper.handleErrorResponse(error)
            );
    }

    public saveEmployeeOnboardingTaskNew(form: UntypedFormGroup, employeeOnboarding: EmployeeOnboarding, employeeOnboardingTask: EmployeeOnboardingTask, data: { name: string, assignee_ID: number, deadline: string }) {
        this._http.post<ApiResponse & { task: EmployeeOnboardingTask; }>(`/api/employees-onboardings/${employeeOnboarding.employee_onboarding_ID}/tasks/${employeeOnboardingTask.employee_onboarding_task_ID}`, data)
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);
                    this._employeeOnboardingStore.update(
                        employeeOnboarding.employee_onboarding_ID,
                        originalOnboarding => {
                            if (!employeeOnboardingTask) {
                                const allTasksCount = originalOnboarding.tasks.length + 1;
                                const completedTasksCount = originalOnboarding.tasks.filter(t => t.completed_at).length;
                                const percentProgress = (completedTasksCount / allTasksCount) * 100;

                                return {
                                    ...originalOnboarding,
                                    tasks: arrayAdd(originalOnboarding.tasks, response.task),
                                    percent_progress: percentProgress
                                };
                            } else {
                                return {
                                    ...originalOnboarding,
                                    tasks: arrayUpdate(
                                        originalOnboarding.tasks,
                                        employeeOnboardingTask.employee_onboarding_task_ID,
                                        {
                                            ...employeeOnboardingTask,
                                            name: form.value.name,
                                            assignee_ID: form.value.assignee_ID,
                                            deadline: form.value.deadline,
                                            assignee: response.task.assignee
                                        },
                                        'employee_onboarding_task_ID'
                                    ),
                                };
                            }
                        });
                },
                error => this._apiHelper.handleErrorResponse(error)
            );
    }

    public unCompleteTask(employeeOnboardingID: number | string, employeeOnboardingTask: EmployeeOnboardingTask): void {
        this._loaderService.start();
        this._http.post<ApiResponse>(`/api/employees-onboardings/${employeeOnboardingID}/tasks/${employeeOnboardingTask.employee_onboarding_task_ID}/uncomplete`, {})
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);
                    this._loaderService.stop();

                    this._employeeOnboardingStore.update(
                        employeeOnboardingID,
                        originalOnboarding => {
                            const allTasksCount = originalOnboarding.tasks.length;
                            const completedTasksCount = originalOnboarding.tasks.filter(t => t.completed_at).length - 1;
                            const percentProgress = (completedTasksCount / allTasksCount) * 100;

                            return {
                                ...originalOnboarding,
                                tasks: arrayUpdate(
                                    originalOnboarding.tasks,
                                    employeeOnboardingTask.employee_onboarding_task_ID,
                                    {
                                        ...employeeOnboardingTask,
                                        completed_at: null
                                    },
                                    'employee_onboarding_task_ID'
                                ),
                                percent_progress: percentProgress
                            };
                        });
                },
                error => this._apiHelper.handleErrorResponse(error)
            );
    }
}
