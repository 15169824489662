import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ContentChildren, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, filter, map, shareReplay, tap } from 'rxjs/operators';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';
import { DataTableDataCellDirective } from '../../directives/data-table-data-cell/data-table-data-cell.directive';
import { DataTableHeaderCellDirective } from '../../directives/data-table-header-cell/data-table-header-cell.directive';
import { defaultSort, extendDataCellWithHeaderCellValue, getObjectValueByAccessor, } from '../../functions';
import type { GenericObject } from '../../interface/generic-object.interface';
import { Sort } from '../../interface/sort.interface';

// import type { NestedPaths } from '../../types';
import { DataType, SORT_ASC, TrackByFnType } from '../../types';

interface SplitHeaderCells<T> {
    everyOtherHeaderCell: Array<DataTableHeaderCellDirective<T>>;
    headerCellToBeSortedBy: DataTableHeaderCellDirective<T> | null;
}

interface Metadata<T> {
    index?: number;
    lastIndex?: number;
    size?: number;
    sums?: Summary<T>;
}

interface IColumn<T> {
    // key: NestedPaths<T>;
    key: string;
    name: string;
    pipeParams?: Array<string>;
    type?: DataType;
}

type Summary<T> = {
    // [K in keyof T]?: T[K];
    [summaryKey: string]: {
        type?: DataType;
        value: any;
    };
};

const DEBOUNCE_IN_MS = 150;

const SHOW_NON_RESPONSIVE_BUTTON_BREAKPOINT_IN_PX = 767;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.css']
})
export class DataTableComponent<T extends GenericObject> implements AfterContentChecked, OnChanges, OnDestroy {
    @HostBinding('class.responsive')
    public get isResponsive(): boolean {
        return !this.nonResponsive;
    }

    @ContentChildren(DataTableDataCellDirective)
    public set cellsData(cells: QueryList<DataTableDataCellDirective>) {
        this._cellsData = cells;

        this._cellsData$.next(this._cellsData);
    }

    @ContentChildren(DataTableHeaderCellDirective)
    public set cellsHeader(cells: QueryList<DataTableHeaderCellDirective<T>>) {
        this._cellsHeader = cells;

        this._cellsHeader$.next(this._cellsHeader);
    }

    @ContentChild('dataRow')
    public dataRowTemplate: TemplateRef<ElementRef>;

    @ContentChild('footerRow')
    public footerRowTemplate: TemplateRef<ElementRef>;

    @ContentChild('headerRow')
    public headerRowTemplate: TemplateRef<ElementRef>;

    @ContentChild('headerButton')
    public headerButtonTemplate: TemplateRef<ElementRef>;

    @ViewChild('optionsDialogTemplate')
    public optionsDialogTemplate: ElementRef;

    @ViewChild('tableDataRow')
    public tableDataRow: ElementRef;

    @ViewChild('tableFooterRow')
    public tableFooterRow: ElementRef;

    @ViewChild('tableHeaderRow')
    public tableHeaderRow: ElementRef;

    @Input()
    public allowGrouping = false;

    @Input()
    // public columnsToFilterIn: Array<NestedPaths<T>>;
    public columnsToFilterIn: Array<string>;

    @Input()
    public customNoDataMessage: string;

    @Input()
    public disableInPlaceFiltering = false;

    @Input()
    public disableInPlaceSorting = false;

    @Input()
    public set groupBy(groupBy: IColumn<T>) {
        this._groupBy = groupBy;

        this.optionsForm.patchValue({groupBy: this._groupBy});
    }

    public get groupBy(): IColumn<T> {
        return this._groupBy;
    }

    @Input()
    public loading: boolean;

    @Input()
    public firstColWidth: null;

    @Input()
    public set colsConfig(colsConfig: { width: Array<string> }) {
        this._colsConfig = colsConfig;
    }

    public get colsConfig(): { width: Array<string> } {
        return this._colsConfig;
    }

    private _colsConfig: { width: Array<string> } = {width: []};

    @Input()
    public nonResponsive = false;

    @Input()
    public set rows(rows: Array<T>) {
        this._setNoData(rows);

        this._rows = rows;

        if (this.search && !this.disableInPlaceFiltering) {
            this.searchForm.patchValue({search: this.search}, {emitEvent: this.emitEvent});
        }

        this._rows$.next(rows);
    }

    @Input()
    public emitEvent = false;

    @Input()
    public set search(search: string) {
        this._searchPhrase = search;

        this.searchForm.patchValue({search}, {emitEvent: this.emitEvent});
    }

    public get search(): string {
        return this._searchPhrase;
    }

    @Input()
    public showHeader = true;

    @Input()
    public cssHideHeader = false;

    @Input()
    public showSearch = true;

    @Input()
    public set sort(sort: Sort<T>) {
        this._sort = sort;

        this._updateHeaderCellsSort();
    }

    @Input()
    public set summaries(summaries: Array<IColumn<T>>) {
        this._summaries = summaries;

        this.optionsForm.patchValue({summaries: this._summaries});
    }

    public get summaries(): Array<IColumn<T>> {
        return this._summaries;
    }

    @Input()
    public trackByFn: TrackByFnType<T>;

    /**
     * All DataTableDataCells have to have
     * useMutationObserver set to true
     * when table is using VirtualScroll
     */
    @Input()
    public virtualScroll = false;

    @Output()
    public searchChanged = new EventEmitter<string>();

    @Output()
    public sortChanged = new EventEmitter<Sort<T>>();

    @Output()
    public filteredRowsChanged = new EventEmitter<Array<T>>();

    public columnCount$: Observable<number>;

    public set columns(columns: Array<IColumn<T> | null>) {
        this._columns = columns;

        const sort = {
            column: 'name',
            direction: SORT_ASC
        };

        this.columnsForSelect = [...this.columns]
            .filter(column => column !== null)
            .sort(defaultSort(sort, this._translateService.currentLang));
    }

    public get columns(): Array<IColumn<T> | null> {
        return this._columns;
    }

    public get hiddenInputs$(): BehaviorSubject<boolean> {
        return this._inputVisibilityService.hiddenInputs$;
    }

    public columnsForSelect: Array<IColumn<T>>;
    public metadata: Metadata<T>;
    public noData = true;
    public optionsForm: UntypedFormGroup;
    public rows$: Observable<Array<T>>;
    public isCollapsed: Array<any>;
    public searchForm: UntypedFormGroup;
    public showNonResponsiveButton$: Observable<boolean>;

    private _cellsData: QueryList<DataTableDataCellDirective>;
    private _cellsData$ = new ReplaySubject<QueryList<DataTableDataCellDirective>>(1);
    private _cellExtensionSubscription: Subscription;
    private _cellsHeader: QueryList<DataTableHeaderCellDirective<T>>;
    private _cellsHeader$ = new ReplaySubject<QueryList<DataTableHeaderCellDirective<T>>>(1);
    private _columnCount$ = new ReplaySubject<number>(1);
    private _columns: Array<IColumn<T> | null>;
    private _groupBy: IColumn<T>;
    private _lastTableHeaderRow: ElementRef;
    private _listenerSubscriptions: Array<Subscription> = [];
    private _optionsModalRef: NgbModalRef;
    private _rows: Array<T>;
    private _rows$ = new ReplaySubject<Array<T>>(1);
    private _searchFormSubscription: Subscription;
    private _searchPhrase: string;
    private _sort: Sort<T> | null;
    private _summaries: Array<IColumn<T>>;

    public constructor(
        private _breakpointObserver: BreakpointObserver,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _modalService: NgbModal,
        private _translateService: TranslateService,
        private _inputVisibilityService: InputVisibilityService,
    ) {
        this._init();
    }

    public ngOnDestroy(): void {
        this._cellExtensionSubscription?.unsubscribe();

        this._optionsModalRef?.close();

        this._searchFormSubscription?.unsubscribe();

        this._unsubscribeListeners();
    }

    public ngOnChanges(): void {
        this._updateMetadata();
    }

    public ngAfterContentChecked(): void {
        if (this._lastTableHeaderRow === this.tableHeaderRow) {
            return;
        }

        this._lastTableHeaderRow = this.tableHeaderRow;

        const tableHeaderRowCellsCount = this._cellsHeader?.length;

        this._columnCount$.next(tableHeaderRowCellsCount);

        this._listenToSortChange();

        this._updateHeaderCellsSort();
    }

    public compareColumns(colA: IColumn<T>, colB: IColumn<T>): boolean {
        return colA.key === colB.key;
    }

    // public getObjectValueByAccessor(object: T, accessor: NestedPaths<T>): any {
    public getObjectValueByAccessor(object: T, accessor: string): any {
        return getObjectValueByAccessor(object, accessor);
    }

    public showOptionsDialog(): void {
        this._optionsModalRef = this._modalService.open(this.optionsDialogTemplate, {centered: true});
    }

    public submitOptions(): void {
        this._optionsModalRef?.close();

        this.groupBy = this.optionsForm.value.groupBy || null;
        this.summaries = this.optionsForm.value.summaries || [];

        this.nonResponsive = !this.optionsForm.value.viewCompact;

        this._updateMetadata();
    }

    public trackByColumnKey(index: number, column: IColumn<T>): string | null {
        return column?.key || null;
    }

    private _init(): void {
        this.showNonResponsiveButton$ = this._breakpointObserver
            .observe([`(max-width: ${SHOW_NON_RESPONSIVE_BUTTON_BREAKPOINT_IN_PX}px)`])
            .pipe(
                map(state => state.matches),
                shareReplay()
            );

        this._cellExtensionSubscription = combineLatest([
            this._cellsHeader$,
            this._cellsData$
        ])
            .pipe(
                filter(([h, d]) => h.length > 0 && d.length > 0),
                debounceTime(DEBOUNCE_IN_MS),
                tap(([headerCells, dataCells]) => extendDataCellWithHeaderCellValue(headerCells, dataCells))
            )
            .subscribe(([headerCells]) => {
                this.columns = headerCells.map(headerCell => {
                    const key = headerCell.column;
                    const name = headerCell.content;
                    const type = headerCell.type || null;

                    return key && name ? {
                        key,
                        name,
                        type
                    } : null;
                });

                this._columnCount$.next(this.columns.length);
            });

        this.columnCount$ = this._columnCount$;
        this.rows$ = this._rows$;

        this.isCollapsed = [];
        this._setupOptionsForm();

        this._setupSearchForm();
    }

    public setCollapse(key): void {
        for (let i = key.index; i <= key.lastIndex; i++) {
            this.isCollapsed[i] = !this.isCollapsed[i];
        }
    }

    private _listenToSortChange(): void {
        this._unsubscribeListeners();

        this._cellsHeader.forEach(headerCell => {
            this._listenerSubscriptions.push(headerCell.sortDirectionChanged
                .subscribe(sortDirection => {
                    let sort: Sort<T> = {
                        column: headerCell.column,
                        direction: sortDirection
                    };

                    if (typeof headerCell.name !== 'undefined') {
                        sort = {
                            ...sort,
                            name: headerCell.name
                        };
                    }

                    this.sortChanged.emit(sort);

                    if (!this.disableInPlaceSorting) {
                        this._rows$.next(this._sortBy(sort));
                    }
                })
            );
        });
    }

    private _search(search: string): Array<T> {
        const searchThroughAll = !this.columnsToFilterIn?.length;

        if (!search) {
            return this._rows;
        } else if (searchThroughAll) {
            return this._searchEverywhereShallow(search, this._rows);
        } else {
            return this._searchInKeys(search, this._rows, this.columnsToFilterIn);
        }
    }

    private _searchEverywhereShallow(search: string, rows: Array<T>): Array<T> {
        let row;
        let value: any;

        const filtered = [];

        for (let i = 0, max = rows?.length; i < max; i++) { // tslint:disable-line:prefer-const
            row = rows[i];

            for (let key in row) { // tslint:disable-line:prefer-const
                if (!row.hasOwnProperty(key)) {
                    continue;
                }

                value = row[key];

                if (
                    // @intentional weak comparison
                    value == search || // eslint-disable-line eqeqeq
                    typeof value === 'string' &&
                    value.toLowerCase().includes(search.toLowerCase())
                ) {
                    filtered.push(row);

                    break;
                }
            }
        }

        return filtered;
    }

    // private _searchInKeys(search: string, rows: Array<T>, keys: Array<NestedPaths<T>>): Array<T> {
    private _searchInKeys(search: string, rows: Array<T>, keys: Array<string>): Array<T> {
        const searchedValue = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        let row: T;
        // let key: NestedPaths<T>;
        let key: string;
        let value: any;

        const filtered = [];

        for (let i = 0, max = rows?.length; i < max; i++) { // tslint:disable-line:prefer-const
            row = rows[i];

            for (let n = 0, nMax = keys?.length; n < nMax; n++) { // tslint:disable-line:prefer-const
                key = keys[n];
                value = row[key];

                if (typeof value === 'undefined') {
                    value = getObjectValueByAccessor(row, key);
                }

                const searchValues = searchedValue.split(';');

                for (let j = 0; j < searchValues.length; j++) {
                    const searchValue = searchValues[j].trimStart();

                    if (searchValue !== '') {
                        if (value == searchValue || typeof value === 'string' &&
                            value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchValue)) {
                            filtered.push(row);
                            break;
                        }
                    }
                }
            }
        }

        return filtered;
    }

    private _setNoData(rows: Array<T>): void {
        if (rows?.length > 0) {
            this.noData = false;
        } else {
            this.noData = true;
        }
    }

    private _setupOptionsForm(): void {
        this.optionsForm = this._formBuilder.group({
            groupBy: [null],
            summaries: [null],
            viewCompact: [true],
        });
    }

    private _setupSearchForm(): void {
        this.searchForm = this._formBuilder.group({
            search: [null],
        });

        this._searchFormSubscription = this.searchForm.valueChanges
            .pipe(debounceTime(DEBOUNCE_IN_MS))
            .subscribe((values: { search: string; }) => {
                const search = values.search;

                this.searchChanged.emit(search);

                if (!this.disableInPlaceFiltering) {
                    const rows = this._search(search);

                    this.filteredRowsChanged.emit(rows);

                    this._setNoData(rows);

                    this._rows$.next(rows);

                    this._changeDetectorRef.detectChanges();
                }
            });
    }

    private _sortBy(sort: Sort<T>): Array<T> {
        if (!sort) {
            return this._rows;
        } else {
            const rows = [...this._rows];

            // rows.sort(defaultSort(sort, this._translateService.currentLang, this._groupBy?.key));
            rows.sort(defaultSort(sort, this._translateService.currentLang, null));

            for (let i = 0; i < rows.length; i++) {
                this.isCollapsed[i] = false;
            }

            return rows;
        }
    }

    private _splitHeaderCellsBySort(sort: Sort<T>): SplitHeaderCells<T> {
        const everyOtherHeaderCell: Array<DataTableHeaderCellDirective<T>> = [];

        let headerCellToBeSortedBy: DataTableHeaderCellDirective<T> = null;

        this._cellsHeader?.forEach(headerCell => {
            if (
                headerCell.column === sort?.column ||
                headerCell.name === sort?.column
            ) {
                headerCellToBeSortedBy = headerCell;
            } else {
                everyOtherHeaderCell.push(headerCell);
            }
        });

        return {
            everyOtherHeaderCell,
            headerCellToBeSortedBy
        };
    }

    private _unsubscribeListeners(): void {
        this._listenerSubscriptions.forEach(subscription => subscription?.unsubscribe());

        this._listenerSubscriptions = [];
    }

    private _updateHeaderCellsSort(): void {
        if (!this._sort) {
            return;
        }

        const {headerCellToBeSortedBy, everyOtherHeaderCell} = this._splitHeaderCellsBySort(this._sort);

        if (everyOtherHeaderCell?.length) {
            everyOtherHeaderCell.forEach(headerCell => headerCell.sortDirection = null);
        }

        if (headerCellToBeSortedBy) {
            headerCellToBeSortedBy.sortDirection = this._sort.direction;
        }
    }

    private _updateMetadata(): void {
        if (
            !this._groupBy ||
            !this._rows ||
            !this._sort
        ) {
            return;
        }

        const rows = this._sortBy(this._sort);

        if (rows) {
            const metadata: Metadata<T> = {};

            this.metadata = {};

            for (let i = 0, max = rows.length; i < max; i++) {
                const row = rows[i];
                const groupName = getObjectValueByAccessor(row, this._groupBy.key);

                if (i === 0) {
                    if (metadata[groupName]) {
                        metadata[groupName] = {
                            ...metadata[groupName],
                            index: i,
                            size: 1,
                            sums: this._updateSummaries(row, metadata[groupName].sums)
                        };
                    } else {
                        metadata[groupName] = {
                            index: i,
                            size: 1,
                            sums: this._updateSummaries(row, {})
                        };
                    }
                } else {
                    const previousRowData = rows[i - 1];
                    const previousRowGroup = getObjectValueByAccessor(previousRowData, this._groupBy.key);

                    if (groupName === previousRowGroup) {
                        metadata[groupName].size++;

                        metadata[groupName] = {
                            ...metadata[groupName],
                            sums: this._updateSummaries(row, metadata[groupName].sums)
                        };
                    } else {
                        if (metadata[groupName]) {
                            metadata[groupName] = {
                                ...metadata[groupName],
                                index: i,
                                size: 1,
                                sums: this._updateSummaries(row, metadata[groupName].sums)
                            };
                        } else {
                            metadata[groupName] = {
                                index: i,
                                size: 1,
                                sums: this._updateSummaries(row, {})
                            };
                        }
                    }
                }

                if (
                    typeof metadata[groupName].index !== 'undefined' &&
                    typeof metadata[groupName].size !== 'undefined'
                ) {
                    metadata[groupName] = {
                        ...metadata[groupName],
                        lastIndex: metadata[groupName].index + metadata[groupName].size - 1
                    };
                }
            }

            this.metadata = metadata;

            this._rows$.next(rows);
        }
    }

    private _updateSummaries(row: T, sums: Summary<T>): Summary<T> {
        if (this._summaries) {
            let rowValueForSummary;

            for (let n = 0, max = this._summaries.length; n < max; n++) { // tslint:disable-line:prefer-const
                const summaryKey = this._summaries[n].key;
                const summaryType = this._summaries[n].type;

                rowValueForSummary = getObjectValueByAccessor(row, summaryKey);

                if (
                    typeof rowValueForSummary === 'number' &&
                    isNaN(rowValueForSummary) === false
                ) {
                    if (typeof sums[summaryKey] === 'undefined') {
                        sums[summaryKey] = {
                            type: summaryType,
                            value: 0
                        };
                    }

                    sums[summaryKey].value += rowValueForSummary;
                }
            }
        }

        return sums;
    }
}
