import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { resetStores } from '@datorama/akita';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { HelpStore } from 'src/app/help/state/help.store';
import { Permission } from 'src/app/setting/models/Permission';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { environment } from '../../../environments/environment';
import { Employee } from '../../employee/models/Employee';
import { TwoFactorSecret } from '../../employee/models/TwoFactorSecret';
import { UserInactivityModalComponent } from '../../shared/components/partials/user-inactivity-modal/user-inactivity-modal.component';
import { deleteCookie, getCookie, localStorageSafe, setCookie } from '../../shared/functions';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { LastActiveService } from '../../shared/services/last-active.service';
import { LANG_KEY } from '../constants';
import { LoggedInUser } from '../models/LoggedUser';
import { LoginResponse } from '../models/LoginResponse';
import { DomainService } from './domain.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    public pageTitle = new Subject<any>();
    public permissions: Array<Permission> = [];
    public employee: Employee;
    public loggedUser: LoggedInUser;
    public loggedUser$: Observable<LoggedInUser>;
    public inactivityModalOpened = false;
    public refreshProfile = false;

    private readonly TOKEN_EXPIRY_CHECK_INTERVAL_MS = 5000;
    private readonly TOKEN_EXPIRY_REFRESH_BEFORE_LOGOUT_MS = 15000;
    private readonly USER_INACTIVITY_CHECK_INTERVAL_MS = 1000;
    private readonly USER_INACTIVITY_SHOW_MODAL_BEFORE_LOGOUT_MINUTES = 1;
    private USER_INACTIVITY_MAX_TIME_TO_LOGOUT_MINUTES = 5;

    private _loggedUser$$ = new BehaviorSubject<LoggedInUser | null>(undefined);
    private _tokenExpiryInterval: any;
    private _userInactivityInterval: any;
    private _loggedIn = new BehaviorSubject<boolean>(false);

    public constructor(
        private _apiHelper: ApiHelper,
        private _azureAuthService: MsalService,
        private _domainService: DomainService,
        private _helpStore: HelpStore,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _router: Router,
        private _translateService: TranslateService,
        private _lastActiveService: LastActiveService,
        private _modalService: NgbModal,
    ) {
        this.loggedUser$ = this._loggedUser$$;
        this._azureAuthService.initialize();
        this.checkForSocialLogin();

        console.log('plugin PushNotifications available: ' + Capacitor.isPluginAvailable('PushNotifications'));
        console.log('plugin FirebaseMessaging available: ' + Capacitor.isPluginAvailable('FirebaseMessaging'));
        console.log('plugin App available: ' + Capacitor.isPluginAvailable('App'));
        console.log('isNativePlatform: ' + Capacitor.isNativePlatform());
        console.log('platform: ' + Capacitor.getPlatform());
    }

    public changeAuthStatus(value: boolean): void {
        this._loggedIn.next(value);
    }

    public getAuthStatus(): boolean {
        return this._loggedIn.getValue();
    }

    public getAuthUser(): Observable<LoggedInUser> {
        return this._loggedUser$$
            .pipe(
                switchMap(loggedInUser => {
                    if (typeof loggedInUser !== 'undefined' && !this.refreshProfile) {
                        return this._loggedUser$$;
                    } else {
                        this.refreshProfile = false;
                        return this._http.get<LoggedInUser>('/api/profile')
                            .pipe(
                                tap(loggedUser => {
                                    if (loggedUser?.workspace?.navbar_color) {
                                        const navbar = document.querySelector('div.navbar-custom.topnav-navbar');
                                        const navbarStyle = document.createElement('style');
                                        navbarStyle.id = 'navbarStyle';
                                        document.head.appendChild(navbarStyle);
                                        navbarStyle.innerHTML =
                                            `div.navbar-custom.topnav-navbar { background-color: ${loggedUser?.workspace?.navbar_color}; }
                                            a#topbar-userdrop {background-color: ${loggedUser?.workspace?.navbar_color};}
                                            ng-select div.ng-select-container {background-color: rgba(255,255,255,.8) !important;}
                                            .ng-dropdown-panel * {background-color: rgba(255,255,255,.8) !important;}`;
                                    }
                                    this.removeAppLoader();
                                    if (loggedUser?.user_ID) {
                                        this._setLang(loggedUser);
                                        this._helpStore.update({help_mode: loggedUser?.help_mode});
                                        this.USER_INACTIVITY_MAX_TIME_TO_LOGOUT_MINUTES = loggedUser.auto_logout_minutes;

                                        if (environment.production) {
                                            this.checkUserInactivity();
                                        }

                                        this.checkTokenExpiry();
                                        localStorageSafe.setItem('list_components_config', JSON.stringify(loggedUser.default_filters?.list_components_config));
                                    }

                                    this.permissions = loggedUser?.permissions || [];
                                    this.employee = loggedUser?.employee || null;
                                    this.loggedUser = loggedUser || null;
                                    this._loggedUser$$.next(loggedUser || null);
                                }),
                                catchError(response => {
                                    this.removeAppLoader();
                                    if (
                                        response instanceof HttpErrorResponse &&
                                        response.status === 400 && response.error.message === 'auth.noone_logged'
                                    ) {
                                        localStorageSafe.removeItem('user_last_active_before_unload');
                                    }

                                    if (
                                        response instanceof HttpErrorResponse &&
                                        response.status === 404
                                    ) {
                                        return throwError(response.error.message);
                                    }

                                    return of(null);
                                })
                            );
                    }
                })
            );
    }

    public hasPermissionTo(name: string | Array<string>): Observable<{ can: boolean; }> {
        const hasPermission = this.checkPermission(name);

        return of({can: hasPermission});
    }

    public checkPermission(name: string | Array<string>): boolean {
        let hasPermission = false;

        if (this.permissions.length > 0) {
            if (Array.isArray(name)) {
                name.every(permissionName => {
                    const permission = this.permissions.find(perm => perm.name === permissionName);

                    if (permission) {
                        hasPermission = true;
                        return false;
                    }

                    return true;
                });
            } else {
                const permission = this.permissions.find(perm => perm.name === name);

                if (permission) {
                    hasPermission = true;
                }
            }
        }

        return hasPermission;
    }

    public removeAppLoader(): void {
        document.body.classList.remove('app-loading');
        this._apiHelper.handleNewVersionRefreshed();
    }

    public login(email: string, password: string): void {
        this._loaderService.start();

        this._handleLogin(this._http.post<LoginResponse>(`/api/login`, {email, password}), null, true);
    }

    public loginAsSpecificEmployee(employeeID: number): void {
        this._http.get<{ link: string }>(`/api/employees/${employeeID}/impersonate-link`)
            .subscribe((response: { link: string }) => {
                document.location.href = response.link;
            }, error => {
                this._apiHelper.handleErrorResponse(error);
            });
    }

    public checkForSocialLogin(): void {
        // if it is a mobile app, listen for the opening event of the app with deep link
        if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('App')) {
            App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
                // google login in capacitor
                if (data.url.includes('capacitor=true')) {
                    this._loaderService.start();
                    const decodedUrl = decodeURI(data.url.replace('speybl:///#', 'auth/login/').replace('&capacitor=true', ''));

                    // close the capacitor browser
                    if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Browser')) {
                        void Browser.close();
                    }

                    // redirect to login page to trigger the login
                    void this._router.navigateByUrl(decodedUrl);
                }
            });
        }
    }

    public startLoginLoader(provider = 'standard'): void {
        const loaderEl = document.getElementById('login-' + provider);
        loaderEl?.classList.add('loading');
    }

    public stopLoginLoader(provider = 'standard'): void {
        const loaderEl = document.getElementById('login-' + provider);
        loaderEl?.classList.remove('loading');
    }

    public logout(): void {
        this._clearData();
        void this._router.navigateByUrl('/auth/login');
        this._http.get('/api/logout')
            .subscribe(() => {
                    clearInterval(this._tokenExpiryInterval);
                    clearInterval(this._userInactivityInterval);
                },
                () => {
                    clearInterval(this._tokenExpiryInterval);
                    clearInterval(this._userInactivityInterval);
                });
    }

    public requestResetPasswordMail(email: string): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<any>('/api/forgotten_password', {email})
                .subscribe(
                    () => {
                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public register(data: any): Promise<ApiResponse> {
        this._loaderService.start();

        return new Promise((resolve, reject) => {
            this._http.post('/api/register', data).subscribe((response: ApiResponse) => {
                resolve(response);
                this._loaderService.stop();
            }, error => {
                reject(error.error);
                this._apiHelper.handleErrorResponse(error);
                this._loaderService.stop();
            });
        });
    }

    public resetPassword(password: string, passwordConfirm: string, token: string, email: string): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post('/api/reset_password', {password, passwordConfirm, token, email})
                .subscribe(
                    () => {
                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public magicLinkLogin(stream$: Observable<LoginResponse>): void {
        this._handleLogin(stream$, null, false);
    }

    public checkTokenExpiry(token?: string): void {
        token = token ?? getCookie('token');

        if (token) {
            const expiry = new Date((JSON.parse(atob((token.split('.')[1]))).exp * 1000)).getTime();
            this._tokenExpiryInterval = setInterval(() => {
                const now = new Date().getTime();
                if (now > expiry) {
                    clearInterval(this._tokenExpiryInterval);
                    clearInterval(this._userInactivityInterval);
                    void this._router.navigateByUrl('/auth/login');
                    this._clearData();
                } else if (now + this.TOKEN_EXPIRY_REFRESH_BEFORE_LOGOUT_MS > expiry) {
                    this._refreshToken();
                }
            }, this.TOKEN_EXPIRY_CHECK_INTERVAL_MS);
        } else {
            console.log('no token');
            clearInterval(this._tokenExpiryInterval);
            clearInterval(this._userInactivityInterval);
            this.logout();
        }
    }

    public checkUserInactivity(): void {
        this._userInactivityInterval = setInterval(() => {
            const lastActivity = this._lastActiveService.getLastActiveFromLocalStorage()?.getTime();
            const lastActivityUnload = new Date(localStorageSafe.getItem('user_last_active_before_unload')).getTime();
            const expiry = lastActivity + (this.USER_INACTIVITY_MAX_TIME_TO_LOGOUT_MINUTES * 60 * 1000);
            const expiryUnload = lastActivityUnload + (this.USER_INACTIVITY_MAX_TIME_TO_LOGOUT_MINUTES * 60 * 1000);
            const now = new Date().getTime();
            let showModal = (now + (this.USER_INACTIVITY_SHOW_MODAL_BEFORE_LOGOUT_MINUTES * 60 * 1000)) >= expiry;
            if (now > expiry || (lastActivityUnload !== 0 && now > expiryUnload)) {
                this._clearData();
                void this._router.navigateByUrl('/auth/login');
                localStorageSafe.setItem('inactivity_logout_message', 'true');
                console.log('Logged out due to inactivity.');
                this.logout();
                showModal = false;
                resetStores();
            }

            localStorageSafe.removeItem('user_last_active_before_unload');

            if (showModal && !this.inactivityModalOpened) {
                const modalRef = this._modalService.open(UserInactivityModalComponent, {centered: true, windowClass: 'bg-blur'});
                modalRef.componentInstance.expiry = expiry;
                this.inactivityModalOpened = true;
                modalRef.result
                    .then(
                        () => this.inactivityModalOpened = false,
                        () => {
                            this.inactivityModalOpened = false;
                            localStorageSafe.setItem('inactivity_logout_message', 'true');
                            console.log('inactivity_logout_message');
                            this.logout();
                            resetStores();
                        }
                    );
            }
        }, this.USER_INACTIVITY_CHECK_INTERVAL_MS);
    }

    public loginUsingToken(token: string, tenant: string, provider: string): void {
        this.startLoginLoader(provider);
        this._loaderService.start();
        setCookie('token', token);
        this._domainService.setTenant(tenant);
        this.checkTokenExpiry(token);
        this.refreshProfile = true;

        this.getAuthUser().subscribe(() => {
            this._loaderService.stop();
            void this._router.navigateByUrl('/dashboard');
            this.stopLoginLoader(provider);
        });
    }

    public getTwoFactorSecretKey(userID: number): Observable<TwoFactorSecret> {
        return this._http.get<any>(`/api/users/${userID}/2fa/generate-secret-key`);
    }

    public activateTwoFactor(userID: number, secretKey: string, code: string): Promise<'done'> {
        return new Promise((resolve, reject) => {
            this._http.post<any>(`/api/users/${userID}/2fa/activate`, {secret_key: secretKey, code})
                .subscribe((response: ApiResponse) => {
                        resolve('done');
                        this._apiHelper.handleSuccessResponse(response);
                        this._loaderService.stop();
                    },
                    error => {
                        reject();
                        this._apiHelper.handleErrorResponse(error);
                        this._loaderService.stop();
                    }
                );
        });
    }

    public deactivateTwoFactor(userID: number, form: object): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<any>(`/api/users/${userID}/2fa/deactivate`, form)
                .subscribe((response: ApiResponse) => {
                        resolve('done');
                        this._apiHelper.handleSuccessResponse(response);
                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);
                        this._loaderService.stop();
                    }
                );
        });
    }

    private _clearData(): void {
        deleteCookie('token');
        this._loggedUser$$.next(null);
        this.permissions = [];
        this.loggedUser = null;
        this.employee = null;
    }

    private _handleLogin(stream$: Observable<LoginResponse>, errorMessage?: string, switchingTenant: boolean = true): void {
        stream$
            .pipe(
                tap(login => this._domainService.setTenant(login?.available_tenants?.[0])),
                switchMap(() => {
                    this.refreshProfile = true;

                    return this.getAuthUser()
                        .pipe(first());
                })
            )
            .subscribe(() => {
                    this._loaderService.startLoader('logo');
                    this.changeAuthStatus(true);
                    void this._router.navigateByUrl('/dashboard');
                    this._loaderService.stop();
                    this.stopLoginLoader('microsoft');
                    this.stopLoginLoader();
                    if (!switchingTenant) {
                        window.location.reload();
                    }
                },
                error => {
                    const errorWithCustomMessage = {
                        error: {
                            message: errorMessage
                        },
                        message: null
                    };
                    this._apiHelper.handleErrorResponse(error?.error?.message ? error : errorWithCustomMessage);
                    this._loaderService.stop();
                    this.stopLoginLoader();
                }
            );
    }

    private _setLang(loggedInUser: LoggedInUser): void {
        const locale = loggedInUser.locale;
        const currentLang = this._translateService.currentLang;

        document.documentElement.lang = locale;

        if (
            locale &&
            locale !== currentLang
        ) {
            localStorageSafe.setItem(LANG_KEY, locale);
            this._translateService.use(locale);
        }
    }

    private _refreshToken(): void {
        clearInterval(this._tokenExpiryInterval);
        this._http.get<any>(`/api/refresh-token`)
            .subscribe(token => {
                this.checkTokenExpiry();
            });
    }
}
