import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { DocumentService } from 'src/app/employee/services/document.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AvailableFeatures } from '../../../../employee/models/AvailableFeatures';
import { Employee } from '../../../../employee/models/Employee';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { MultiDocumentTemplateGeneratorComponent } from '../../../../setting/components/sections/multi-document-template-generator/multi-document-template-generator.component';
import { DocumentTemplate } from '../../../../setting/models/DocumentTemplate';
import { DocumentTemplateService } from '../../../../setting/services/document-template.service';
import { SubscriptionService } from '../../../../setting/services/subscription.service';
import { UserService } from '../../../../setting/services/user.service';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';
import { FatherOfListComponent, Sort, SORT_DESC } from '../../../../ui';
import { Document } from '../../../models/Document';

@Component({
    selector: 'app-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent extends FatherOfListComponent<Document> implements OnInit {
    @ViewChild('filterFormModal', {static: false})
    public filterFormModal: TemplateRef<ElementRef>;

    @Input() public filterUnsigned = true;
    @Input() public allowFilterByTemplate = true;
    @Input() public showHeader = false;
    @Input() public documentTemplate: DocumentTemplate;

    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public availableFeatures$: Observable<AvailableFeatures>;
    public employees$: Observable<Employee[]>;
    public documentTemplates$: Observable<DocumentTemplate[]>;
    public dataToExport$: Observable<any>;
    public filterForm: UntypedFormGroup;
    public signatureTypes = [
        {label: this.translateService.instant('documents.type_SIGNED'), value: 'SIGNED'},
        {label: this.translateService.instant('documents.type_TO_BE_SIGNED'), value: 'TO_BE_SIGNED'},
        {label: this.translateService.instant('documents.type_NOT_REQUIRED'), value: 'NOT_REQUIRED'}
    ];
    public sort: Sort<Document> = {
        column: 'created_at',
        direction: SORT_DESC
    };

    private _fetchDocuments$ = new ReplaySubject<void>(1);
    private _filterFormModalRef: NgbModalRef;

    public constructor(
        public translateService: TranslateService,
        public fpHelper: FlatpickrHelper,
        private _documentService: DocumentService,
        private _employeeService: EmployeeService,
        private _subscriptionService: SubscriptionService,
        private _documentTemplateService: DocumentTemplateService,
        private _modalService: NgbModal,
        private _fb: FormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnInit(): void {
        this.filterForm = this._fb.group({
            from: [null],
            to: [null],
            employee_IDs: [null],
            employee_signature_types: [null],
            responsible_employee_IDs: [null],
            responsible_employee_signature_types: [null],
            document_types: [null]
        });

        console.log(this.filterUnsigned);
        if (this.filterUnsigned) {
            this.filterForm.patchValue({
                employee_signature_types: ['TO_BE_SIGNED'],
            });
        }

        if (!this.allowFilterByTemplate) {
            this.filterForm.patchValue({document_types: [this.documentTemplate?.key]});
        }

        this.filterForm[`defaultValue`] = this.filterForm.value;

        this.availableFeatures$ = this._subscriptionService.availableFeatures$;
        this.employees$ = this._employeeService.getAllEmployees(false, 'select').pipe(shareReplay());
        this.documentTemplates$ = this._documentTemplateService.getTemplates(true, 'select');

        this._rows$ = this._fetchDocuments$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                tap(params => this.dataToExport$ = this._documentService.getDocumentsList(params, this.filterForm.value)),
                switchMap(() => this.dataToExport$),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this.useLocalStorage(this.filterForm, 'documentList');

        this._fetchListData();
    }

    public getCount(formGroup: string): number {
        return this.filterForm.get(formGroup).value?.length;
    }

    public trackByFn(index: number, item: Document): number | string {
        return item.document_ID;
    }

    public openFilterFormModal(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormModal);
    }

    public onSubmit(): void {
        this._filterFormModalRef?.close();

        this.saveFormConfigToLocalStorage(this.filterForm.value, 'form');

        this._fetchListData();
    }

    public refreshData(): void {
        this._fetchListData();
    }

    public deleteDocument(documentID: number): void {
        this._documentService.deleteDocument(documentID)
            .then(
                () => { this._fetchListData(); },
                () => { }
            );
    }

    public openTemplateGenerationModal(documentTemplate: DocumentTemplate): void {
        const modalRef = this._modalService.open(MultiDocumentTemplateGeneratorComponent, {centered: true});

        if (documentTemplate) {
            modalRef.componentInstance.selectedTemplate = documentTemplate;
        }

        modalRef.result
            .then(
                () => { },
                () => { }
            );
    }

    protected _fetchListData(): void {
        this._fetchDocuments$.next();
    }
}
