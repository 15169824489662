import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { first, map, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { BranchStoreService } from 'src/app/setting/services/branch-store.service';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { CompanyService } from '../../../../setting/services/company.service';
import { AddressFormComponent } from '../../embedded-forms/address/address-form.component';
import { ContactPersonFormComponent } from '../../embedded-forms/contact-person/contact-person-form.component';
import { EmployeeFormComponent } from '../../embedded-forms/employee/employee-form.component';
import { PersonalDataFormComponent } from '../../embedded-forms/personal-data/personal-data-form.component';
import { PositionFormComponent } from '../../embedded-forms/position/position-form.component';
import { SalaryFormComponent } from '../../embedded-forms/salary/salary-form.component';

@Component({
    selector: 'app-create-edit-employee',
    templateUrl: './create-edit-employee-modal.component.html',
    styleUrls: ['./create-edit-employee-modal.component.css']
})
export class CreateEditEmployeeModalComponent implements OnInit, OnDestroy {
    @Output() setModalSize = new EventEmitter<string>();

    @ViewChild('employeeFormComponentSimple', {static: false}) employeeFormComponentSimple: EmployeeFormComponent;
    @ViewChild('employeeFormComponent', {static: false}) employeeFormComponent: EmployeeFormComponent;
    @ViewChild('personalDataFormComponent', {static: false}) employeePersonalDataFormComponent: PersonalDataFormComponent;
    @ViewChild('addressFormComponent', {static: false}) employeeAddressFormComponent: AddressFormComponent;
    @ViewChild('contactPersonFormComponent', {static: false}) employeeContactPersonFormComponent: ContactPersonFormComponent;
    @ViewChild('positionFormComponent', {static: false}) employeePositionFormComponent: PositionFormComponent;
    @ViewChild('salaryFormComponent', {static: false}) employeeSalaryFormComponent: SalaryFormComponent;

    public employee: Employee;
    public canEdit$: Observable<boolean>;
    public showButton: boolean;
    public message = '';
    public submitted = false;
    public emailTaken = false;
    public personalCodetaken = false;
    public updateRequested = false;
    public archived = false;
    public employeeType = 'COMMON';
    public employeesUsersCounts$: Observable<Array<number>>;
    public hasEditEmployeePermission = false;
    public complexCreate = false;
    public collapsedForms = {
        employee: true, employee_personal_data: true, employee_address: true, employee_contact_person: true, employee_position: true, employee_salary: true, employee_branch: true
    };
    public branchForm: UntypedFormGroup;

    private _availableWidgetsSubscription: Subscription;

    public constructor(
        public activeModal: NgbActiveModal,
        public branchStoreService: BranchStoreService,
        public fpHelper: FlatpickrHelper,
        private _employeeService: EmployeeService,
        private _fb: UntypedFormBuilder,
        private _subscriptionService: SubscriptionService,
        private _authService: AuthenticationService,
        private _companyService: CompanyService,
    ) { }

    public ngOnDestroy(): void {
        this._availableWidgetsSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.setModalSize.emit('md');

        if (this.employee) {
            this.canEdit$ = this._authService.hasPermissionTo('employee.edit')
                .pipe(
                    first(),
                    map(permission => permission.can)
                );
        } else {
            this._availableWidgetsSubscription = this._companyService.availableWidgets$
                .subscribe(widgets => {
                    if (!widgets.employee_creation.settings.simple) {
                        this.complexCreate = true;
                        this.setModalSize.emit('lg');
                    }
                });
        }

        if (this.employee?.permissions?.requestEditEmployee) {
            this.hasEditEmployeePermission = true;
        }

        this.branchForm = this._fb.group({
            branch: [null]
        });

        this.employeesUsersCounts$ = this._subscriptionService.getEmployeesUsersCounts().pipe(shareReplay());

        this.employeesUsersCounts$.subscribe(counts => this.checkCounts(counts));
    }

    public checkCounts(counts: any): void {
        if (counts.employees_limit !== 'unlimited' && counts.employees >= counts.employees_limit && !this.employee) {
            this.showButton = false;
            this.message = 'subscription.max_employees_count_reached';
        } else {
            this.showButton = true;
            this.message = '';
        }
    }

    public onSubmit(): void {
        this.submitted = true;
        let form = null;
        let promise = null;

        if (this.complexCreate) {
            form = {};
            const collapsedFormKeys = Object.keys(this.collapsedForms);
            for (const key of collapsedFormKeys) {
                if (this.collapsedForms[key] === false) {
                    if (key === 'employee_branch') {
                        break;
                    }
                    if (this[this.snakeToCamelCase(key) + 'FormComponent']?.form?.invalid) {
                        return;
                    }
                    form[key] = this[this.snakeToCamelCase(key) + 'FormComponent']?.form?.value;
                }
            }

            if (this.branchForm?.value?.branch !== null) {
                form.employee_branch = this.branchForm?.value;
            }

            if (!form.employee) {
                return;
            }

            if (form.employee.widget_template_ID === 0) {
                form.employee.widget_template_ID = null;
            }

            if (form.employee.company_group_ID === 0) {
                form.employee.company_group_ID = null;
            }

            if (this.archived) {
                this.employeeFormComponent.form.value.in_archive_from = new Date().toISOString().substring(0, 10);
            }

            promise = this._employeeService.saveEmployeeComplex(form);
        } else {
            if (this.employeeFormComponentSimple.form.invalid) {
                return;
            }
            form = this.employeeFormComponentSimple.form.value;

            if (form.widget_template_ID === 0) {
                form.widget_template_ID = null;
            }

            if (form.company_group_ID === 0) {
                form.company_group_ID = null;
            }

            if (this.archived) {
                form.in_archive_from = new Date().toISOString().substring(0, 10);
            }

            if (this.employee) {
                promise = this._employeeService.saveEmployee(form, this.employee.employee_ID);
            } else {
                promise = this._employeeService.saveEmployee(form);
            }
        }

        promise
            .then(() => {
                this.activeModal.close();
            });
    }

    public snakeToCamelCase(str: string): string {
        return str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));
    }

    public setComplexCreate(): void {
        this.complexCreate = !this.complexCreate;
        this.setModalSize.emit(this.complexCreate ? 'lg' : 'md');
        this.submitted = false;
    }

    public collapseForm(form: string): void {
        if (form === 'employee_branch' && this.employeePositionFormComponent?.form?.value?.company_branch_ID !== null && this.branchForm?.value?.branch === null) {
            this.branchForm.patchValue({branch: this.employeePositionFormComponent?.form?.value?.company_branch_ID});
        }
        this.collapsedForms[form] = !this.collapsedForms[form];
    }
}
