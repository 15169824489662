import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Employee } from 'src/app/employee/models/Employee';
import { SalaryService } from 'src/app/employee/services/salary.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';
import { Salary } from '../../../models/Salary';
import { SalaryFormComponent } from '../../embedded-forms/salary/salary-form.component';

@Component({
    selector: 'app-create-employee-salary',
    templateUrl: './create-employee-salary.component.html',
    styleUrls: ['./create-employee-salary.component.css']
})
export class CreateEmployeeSalaryComponent implements OnInit {
    @ViewChild('childRef', {static: false}) formComponent: SalaryFormComponent;

    public submitted = false;
    public salary: Salary;
    public employee: Employee;

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    constructor(
        private salaryService: SalaryService,
        public activeModal: NgbActiveModal,
        public fpHelper: FlatpickrHelper,
        private _inputVisibilityService: InputVisibilityService,
    ) { }

    public ngOnInit(): void {

    }

    public onSubmit(): void {
        const formValue = this.formComponent.form.value;
        this.submitted = true;
        if (this.formComponent.form.invalid) {
            return;
        }

        if (!formValue.has_pension) {
            formValue.pension_type = '';
            formValue.pension_amount = 0;
        }

        if (!formValue.has_salary_deduction) {
            formValue.salary_deduction_type = '';
            formValue.salary_deduction_amount = 0;
        }

        const promise = this.salaryService.saveEmployeeSalaryByEmployeeID(formValue, this.employee.employee_ID, this.salary?.employee_salary_ID);
        promise.then(() => this.activeModal.close('close'));
    }

    public deleteSalary(): void {
        const promise = this.salaryService.deleteEmployeeSalaryByID(this.employee.employee_ID, this.salary.employee_salary_ID);
        promise.then(() => this.activeModal.close('close')).catch(() => {});
    }

}
