import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { first, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { EmployeeReviewService } from 'src/app/kpi/employee-review/employee-review.service';
import { KPIMetric } from 'src/app/kpi/kpi/state/kpi-metric.model';
import { KPI } from 'src/app/kpi/kpi/state/kpi.model';
import { KPIService } from 'src/app/kpi/kpi/state/kpi.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';
import { CreateEditKpiMetricModalComponent } from '../../partials/create-edit-kpi-metric-modal/create-edit-kpi-metric-modal.component';
import { CreateEditKpiModalComponent } from '../../partials/create-edit-kpi-modal/create-edit-kpi-modal.component';
import { CreateEditKPIMetricModificationModalComponent } from '../../partials/create-edit-kpimetric-modification-modal/create-edit-kpimetric-modification-modal.component';
import { KpiRadarChartModalComponent } from '../../partials/kpi-radar-chart-modal-component/kpi-radar-chart-modal-component';

@Component({
    selector: 'app-kpi-overview',
    templateUrl: './kpi-overview.component.html',
    styleUrls: ['./kpi-overview.component.css']
})
export class KpiOverviewComponent implements OnInit {
    public availableEmployees$: Observable<Array<Employee>>;
    public selectedEmployees: Array<boolean> = [];
    public kpi$: Observable<KPI>;
    public availableEmploees: Array<Employee>;
    public sumOfWeights = 0;
    public canEdit = false;

    private _kpiId$: Observable<number>;
    private _fetchKpi$ = new ReplaySubject<void>(1);

    public constructor(
        private _authService: AuthenticationService,
        private _employeeService: EmployeeService,
        private _kpiService: KPIService,
        private _modalService: NgbModal,
        private _reviewService: EmployeeReviewService,
        private _route: ActivatedRoute,
        public fpHelper: FlatpickrHelper,
    ) { }

    public ngOnInit(): void {
        this.canEdit = this._authService.checkPermission('kpi.edit');
        this._kpiId$ = this._route.params
            .pipe(map(params => parseInt(params.kpiID, 10)));
        const hasViewAll = this._authService.checkPermission('employee.view');
        this.kpi$ = this._fetchKpi$
            .pipe(
                withLatestFrom(this._kpiId$),
                switchMap(([noop, kpiId]) => combineLatest([
                    this._kpiService.getKPIByID(kpiId),
                    this._kpiService.getEmployeesForKPI(kpiId)
                ])),
                map(([kpi, employees]) => {
                    this.sumOfWeights = 0;
                    kpi.metrics.forEach(m => {
                        this.sumOfWeights += +m.weight;
                    });
                    return this._extendKPI(kpi, employees);
                })
            );
        // this._authService.hasPermissionTo('kpi.view')
        this.availableEmployees$ = combineLatest([
            this._employeeService.getAllEmployees(false, null, null, null, hasViewAll ? null : 'DESCENDANTS'),
            this.kpi$
                .pipe(map(kpi => (kpi.employees || []).map(e => e.employee_ID)))
        ])
            .pipe(
                map(([allEmployees, assignedEmployeeIds]) => allEmployees.filter(e => assignedEmployeeIds.indexOf(e.employee_ID) === -1)),
                shareReplay()
            );

        this._fetchKpi$.next();
    }

    public onSubmit(f: UntypedFormGroup, kpi: KPI): void {
        if (!f.valid) {
            return;
        }

        const value = f.value;

        this.availableEmployees$
            .pipe(
                first(),
                switchMap(employees => this._kpiService.assignEmployeesToKPI(kpi, value.employeesIDs, employees))
            )
            .subscribe(() => {
                this._fetchKpi$.next();
                this._modalService.dismissAll();
            });
    }

    public onSubmitReviews(f: UntypedFormGroup): void {
        if (!f.valid) {
            return;
        }

        for (const employee of this.availableEmploees) {
            if (!this.selectedEmployees[employee.employee_ID]) {
                const employeeFormGroup = f.get('employees').get(employee.employee_ID.toString());
                employeeFormGroup.disable();
            }
        }

        this._kpiId$
            .pipe(first())
            .subscribe(kpiId => {
                this._reviewService.saveManyReviews(f.value, kpiId);

                f.reset();

                this._fetchKpi$.next();
            });
    }

    public openAssignModal(content: any): void {
        const modalRef = this._modalService.open(content, {centered: true});

        modalRef.result
            .then(
                () => this._fetchKpi$.next(),
                () => { }
            );
    }

    public openEditModal(kpi: KPI): void {
        const modalRef = this._modalService.open(CreateEditKpiModalComponent, {centered: true});

        modalRef.componentInstance.kpi = kpi;

        modalRef.result
            .then(
                () => this._fetchKpi$.next(),
                () => { }
            );
    }

    public openKPIMetricEditModal(kpiMetric: KPIMetric, kpi: KPI): void {
        const modalRef = this._modalService.open(CreateEditKpiMetricModalComponent, {centered: true});

        modalRef.componentInstance.kpiMetric = kpiMetric;
        modalRef.componentInstance.kpi = kpi;

        modalRef.result
            .then(
                () => this._fetchKpi$.next(),
                () => { }
            );
    }

    public openModificationModal(employee: Employee, kpi: KPI): void {
        const modalRef = this._modalService.open(CreateEditKPIMetricModificationModalComponent, {centered: true});

        modalRef.componentInstance.employee = employee;
        modalRef.componentInstance.kpi = kpi;

        modalRef.result
            .then(
                () => this._fetchKpi$.next(),
                () => { }
            );
    }

    public openRadarChart(scoreType: string, employee: Employee, kpi: KPI): void {
        const modalRef = this._modalService.open(KpiRadarChartModalComponent, {centered: true});

        modalRef.componentInstance.employeeID = employee.employee_ID;
        modalRef.componentInstance.kpiID = kpi.kpi_ID;
        modalRef.componentInstance.scoreType = scoreType;

        modalRef.result
            .then(
                () => { },
                () => { }
            );
    }

    public unAssignEmployeeFromKPI(employeeID: number): void {
        this._kpiId$
            .pipe(
                first(),
                switchMap(kpiId => this._kpiService.unAssignEmployeeFromKPI(kpiId, employeeID))
            )
            .subscribe(() => this._fetchKpi$.next());
    }

    public selectEmployee(employeeID: number, event): void {
        this.selectedEmployees[employeeID] = event.target.checked;
    }

    private _extendKPI(kpi: KPI, employees: Array<Employee>): KPI {
        if (kpi) {
            const min = kpi.min;
            const max = kpi.max;
            const percentsForThreshold = 80;
            const range = max - min;

            const progress: {
                progress_1: any,
                progress_2: any,
                progress_3: any,
                progress_4: any,
                progress_5: any,
            } = {
                progress_1: {},
                progress_2: {},
                progress_3: {},
                progress_4: {},
                progress_5: {}
            };

            progress.progress_1 = {
                percents: 10,
                absolute: null
            };

            progress.progress_2 = {
                percents: kpi.threshold_1 * percentsForThreshold / 100,
                absolute: min
            };

            if (kpi.threshold_count === 2) {
                progress.progress_3 = {
                    percents: (kpi.threshold_2 - kpi.threshold_1) * percentsForThreshold / 100,
                    absolute: range * kpi.threshold_1 / 100
                };

                progress.progress_4 = {
                    percents: (100 - kpi.threshold_2) * percentsForThreshold / 100,
                    absolute: range * kpi.threshold_2 / 100
                };
            } else {
                progress.progress_3 = {
                    percents: (100 - kpi.threshold_1) * percentsForThreshold / 100,
                    absolute: range * kpi.threshold_1 / 100
                };

                progress.progress_4 = {
                    percents: null,
                    absolute: null
                };
            }

            progress.progress_5 = {
                percents: 10,
                absolute: max
            };

            kpi = {
                ...kpi,
                employees,
                progress
            };

            for (const employee of employees) {
                this.selectedEmployees[employee.employee_ID] = true;
            }

            this.availableEmploees = employees;
        }

        return kpi;
    }
}
