import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Country } from '../models/Country';
import { CountryServiceService } from './country-service.service';

@Injectable({
    providedIn: 'root'
})
export class CountryStoreService {

    constructor(
        private countryService: CountryServiceService
    ) {
        void this.fetchAll();
    }

    private readonly countrySubject = new BehaviorSubject<Country[]>([]);
    public readonly countries$ = this.countrySubject.asObservable();

    public get countries(): Country[] {
        return this.countrySubject.getValue();
    }

    public set countries(val: Country[]) {
        this.countrySubject.next(val);
    }

    public async fetchAll(): Promise<void> {
        try {
            this.countries = await lastValueFrom(this.countryService.getAllCountries());
        } catch (error) {
            console.error(error);
        }
    }
}
