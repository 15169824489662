<ng-template
  #filterFormOptions
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'employees_datetimes.adjust_filters' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <form
        (ngSubmit)="onSubmit()"
        [formGroup]="filterForm"
        class="filter-form"
      >
          <div class="row">
              <div class="col-12">
                  <div class="form-group">
                      <label>{{ 'employees_datetimes.filter_date_from' | translate }}</label>
                      <input [locale]="locale$ | async" allowInput="true" altFormat="d.m.Y"
                             altInput="true"
                             class="form-control"
                             dateFormat="Y-m-d"
                             formControlName="from"
                             mwlFlatpickr
                             (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                             type="text">
                  </div>
              </div>
          </div>

          <div class="row">
              <div class="col-12">
                  <div class="form-group">
                      <label>{{ 'employees_datetimes.filter_date_to' | translate }}</label>

                      <input [locale]="locale$ | async" allowInput="true" altFormat="d.m.Y"
                             altInput="true"
                             class="form-control"
                             dateFormat="Y-m-d"
                             formControlName="to"
                             mwlFlatpickr
                             (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                             type="text">
                  </div>
              </div>
          </div>

        <div class="row">
          <div class="col-12 text-right">
            <button class="btn btn-primary">
              <span>{{ 'employees_datetimes.action_filter' | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-container>
      <div *ngIf="!loading; else loadingTemplate" class="card shadow h-100">
        <app-help name="absence.list"></app-help>

        <div class="card-header py-3">
          <div class="row">
            <div class="col-6 text-left my-auto">
              <p *ngIf="!isDashboard" class="modal-title font-16 text-dark pt-2 pb-2"> {{ 'employees_datetimes.absence_list' | translate }} </p>
              <p *ngIf="isDashboard" class="modal-title font-16 text-dark pt-2 pb-2"> {{ 'employees_datetimes.absence_list_dashboard' | translate }} </p>
            </div>
            <div class="text-right col-6">
              <button
                (click)="openFilterFormOptions()"
                [formGroup]="filterForm"
                class="btn btn-primary btn-icon"
                type="button"
              >
                <i class="mdi mdi-filter-outline"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div [ngClass]="{'table-nonhover-rows': isDashboard}" class="table-responsive">
                <app-data-table
                  (searchChanged)="searchChanged($event)"
                  (sortChanged)="sortChanged($event)"
                  [disableInPlaceFiltering]="true"
                  [disableInPlaceSorting]="true"
                  [loading]="loading"
                  [rows]="rows"
                  [search]="search"
                  [showHeader]="!isDashboard"
                  [showSearch]="false"
                  [trackByFn]="trackByFn"
                >
                  <ng-template #headerRow>
                    <th [disableSort]="true" appDataTableHeaderCell="fullname">{{ 'employees.name' | translate }}</th>

                    <th [disableSort]="true" appDataTableHeaderCell="type">{{ 'employees_datetimes.type' | translate }}</th>

                    <th *ngIf="!isDashboard" [disableSort]="true" appDataTableHeaderCell="date">{{ 'employees_datetimes.date' | translate }}</th>

                    <th
                      [disableSort]="true"
                      appDataTableHeaderCell
                      class="text-right"
                    >{{ 'employees.table_action' | translate }}</th>
                  </ng-template>

                  <ng-template
                    #dataRow
                    let-row
                  >
                    <td
                      appDataTableDataCell
                      class=""
                    >
                    <app-employee-box-avatar [employee]="row.employee"></app-employee-box-avatar>
                    </td>

                    <td appDataTableDataCell>
                        <div [ngClass]="{'text-right': isDashboard}">
                            <div class="text-warning"> {{ row.type_icon }} {{ row.type_name}} </div>
                            <div *ngIf="isDashboard"> {{ row.from | date:'dd.MM.yyyy' }} → {{ row.to ? (row.to | date: 'dd.MM.yyyy') : ('employees_datetimes.now' | translate) }} </div>
                        </div>
                    </td>

                      <td *ngIf="!isDashboard" appDataTableDataCell="date">
                            {{ row.from | date:'dd.MM.yyyy' }} → {{ row.to ? (row.to | date: 'dd.MM.yyyy') : ('employees_datetimes.now' | translate) }}
                      </td>

                    <td
                      appDataTableDataCell
                      class="text-right"
                      style="width: 80px; overflow: hidden; text-overflow: unset"
                    >
                        <div class="d-flex justify-content-end align-items-center">
                            <span *ngIf="row.to_be_approved"
                                  [ngbTooltip]="('employees_datetimes.to_be_approved' | translate)"
                                  class="entity-indicator-dot employee-state-1 mr-0"
                                  container="body"
                                  placement="top-left"></span>
                            <a
                                *ngIf="row.employee.permissions?.view"
                                [routerLink]="['/employee', row.employee_ID, 'attendance', 'detail', row.employee_datetime_ID]"
                                class="btn btn-white btn-icon btn-sm mr-1"
                                title="{{ 'employees.action_view' | translate }}"
                            >
                                <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                          </a>
                        </div>
                    </td>
                  </ng-template>
                </app-data-table>
              </div>
            </div>
          </div>
          <div *ngIf="isDashboard" class="text-right mt-2 px-2">
            <a class="text-muted" routerLink="/employee/attendance/absence-list">
              <u>{{ 'notifications.action_show_all' | translate }}</u>
            </a>
          </div>
        </div>
          <div *ngIf="!isDashboard" class="row mt-3 flex-column-reverse flex-sm-row">
              <div class="col-12 col-sm-2">
                  <app-items-per-page-select
                      (itemsPerPageChanged)="itemsPerPageChanged($event)"
                      [itemsPerPage]="itemsPerPage"
                      [total]="itemsTotal"
                  ></app-items-per-page-select>
              </div>

              <div class="col-12 col-sm-10">
                  <app-pagination
                      (pageChanged)="pageChanged($event)"
                      [itemsPerPage]="itemsPerPage"
                      [page]="page"
                      [total]="itemsTotal"
                  ></app-pagination>
              </div>
          </div>
      </div>
</ng-container>

<ng-template #loadingTemplate>
        <div class="card shadow p-3">
            <div class="item">
                <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="7"></ngx-skeleton-loader>
            </div>
        </div>
</ng-template>
