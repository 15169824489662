import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';
import { range } from '../../../../utils/array.functions';
import { Employee } from '../../../models/Employee';
import { Salary } from '../../../models/Salary';

@Component({
    selector: 'app-salary-form',
    templateUrl: './salary-form.component.html',
    styleUrls: ['./salary-form.component.css']
})
export class SalaryFormComponent implements OnInit {
    @Input() public submitted = false;
    @Input() public employee: Employee;
    @Input() public salary: Salary;

    public form: UntypedFormGroup;
    public employeesSalariesFrequencies = ['HOUR', 'DAY', 'WEEK', 'TWO_WEEKS', 'MONTH', 'QUARTER', 'OTHER'];
    public rangeFunc = range;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;

    constructor(
        public fpHelper: FlatpickrHelper,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _inputVisibilityService: InputVisibilityService,
    ) { }

    public get f(): { [p: string]: AbstractControl<any, any> } {
        return this.form.controls;
    }

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    public ngOnInit(): void {
        this.form = this._fb.group({
            amount: ['', Validators.required],
            variable_amount: [0],
            from: ['', Validators.required],
            to: [''],
            frequency: 'MONTH',
            second_pension_pillar: [false, Validators.required],
            has_pension: [false, Validators.required],
            pension_type: [null],
            pension_amount: [0],
            has_salary_deduction: [false, Validators.required],
            salary_deduction_type: [null],
            salary_deduction_amount: [0],
            is_student: [false, Validators.required],
            number_of_children: [0, Validators.required],
            number_of_dependents: [0, Validators.required],
            hour_cost: [0],
            month_cost: [0],
            note: [''],
        });

        if (this.salary) {
            this.form.patchValue(this.salary);

            if (!this.employee?.permissions?.createSalary) {
                this.form.disable();
            }

            if (this.hiddenInputs) {
                this.form.disable();
            }
        } else {
            this.form.patchValue({
                second_pension_pillar: this.employee?.salary_values?.second_pension_pillar ?? false,
                has_pension: this.employee?.salary_values?.has_pension ?? false,
                pension_type: this.employee?.salary_values?.pension_type ?? '',
                pension_amount: this.employee?.salary_values?.pension_amount ?? 0,
                has_salary_deduction: this.employee?.salary_values?.has_salary_deduction ?? false,
                salary_deduction_type: this.employee?.salary_values?.salary_deduction_type ?? '',
                salary_deduction_amount: this.employee?.salary_values?.salary_deduction_amount ?? 0,
                is_student: this.employee?.salary_values?.is_student ?? false,
                number_of_children: this.employee?.salary_values?.number_of_children ?? 0,
                number_of_dependents: this.employee?.salary_values?.number_of_dependents ?? 0,
            });
        }
    }
}
