import { Component, Input, OnInit } from '@angular/core';
import { EChartsInitOpts, EChartsOption } from 'echarts';

@Component({
    selector: 'app-bar-echart',
    templateUrl: './bar-echart.component.html',
    styleUrls: ['./bar-echart.component.css']
})
export class BarEChartComponent implements OnInit {
    @Input() public series: any[];
    @Input() public height: number;
    @Input() public labels: string[];

    public emphasisStyle = {
        itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)'
        }
    };

    public initOptions: EChartsInitOpts = {
        renderer: 'svg'
    };

    public chartOptions: EChartsOption = {
        xAxis: {
            show: false,
            name: 'X Axis',
            axisLine: {onZero: true},
            splitLine: {show: false},
            splitArea: {show: false}
        },
        yAxis: {
            splitLine: {
                show: false
            },
        },
        grid: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            width: 'auto',
            height: 'auto',
            show: false
        },
        tooltip: {
            z: 60,
            show: true,
            showContent: true,
            alwaysShowContent: false,
            displayMode: 'single',
            renderMode: 'auto',
            confine: null,
            showDelay: 0,
            hideDelay: 100,
            transitionDuration: 0.4,
            enterable: false,
            backgroundColor: '#fff',
            shadowColor: 'rgba(0, 0, 0, .2)',
            shadowOffsetX: 1,
            shadowOffsetY: 2,
            borderRadius: 4,
            borderWidth: 1,
            padding: null,
            extraCssText: '',
            className: 'echart-tooltip'
        }
    };

    public constructor() {}

    public ngOnInit(): void {
        this.chartOptions.series = this.series.map((item) => {
            return {
                ...item,
                emphasis: this.emphasisStyle
            };
        });
        this.chartOptions.xAxis[`data`] = this.labels;
    }
}
