import { ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { UserService } from '../../../../setting/services/user.service';
import { FatherOfListComponent, Sort, SORT_ASC, SORT_DESC } from '../../../../ui';
import { FlatpickrHelper } from '../../../common/FlatpickrHelper';
import { ActivityLog, ActivityLogFilter } from '../../../models/ActivityLog';
import { ActivityLogService } from '../../../services/activity-log.service';
import { FlatpickrLocaleService } from '../../../services/flatpickr-locale.service';
import { ActivityLogDetailModalComponent } from '../activity-log-detail-modal/activity-log-detail-modal.component';

@Component({
    selector: 'app-activity-log-list',
    templateUrl: './activity-log-list.component.html',
    styleUrls: ['./activity-log-list.component.css']
})
export class ActivityLogListComponent extends FatherOfListComponent<ActivityLog> implements OnInit, OnDestroy {
    @ViewChild('filterFormModal', {static: false})
    public filterFormModal: TemplateRef<ElementRef>;

    public loading = true;
    public isAdmin = false;
    public activityLogs$: Observable<any>;
    public loggedUser = this._authService.loggedUser;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public filterForm: UntypedFormGroup;
    public mode = 'simple';
    public availableFilters: ActivityLogFilter;
    public maxDate = new Date().toISOString();
    public sort: Sort<ActivityLog> = {
        column: 'created_at',
        direction: SORT_DESC
    };

    private _filterFormModalRef: NgbModalRef;
    private _fetchActivityLogs$ = new ReplaySubject<void>(1);
    private _filtersSubscription: Subscription;

    public constructor(
        public fpHelper: FlatpickrHelper,
        private _modalService: NgbModal,
        private _activityLogService: ActivityLogService,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _fb: FormBuilder,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public getCount(field: string) {
        return this.filterForm.get(field).value?.length;
    }

    public ngOnDestroy(): void {
        this._filtersSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.isAdmin = this.loggedUser?.is_admin;

        this.filterForm = this._fb.group({
            subjects: [[]],
            events: [[]],
            causers: [[]],
            employees: [[]],
            from: [null],
            to: [null]
        });

        this.filterForm[`defaultValue`] = this.filterForm.value;

        if (window.location.href.includes('setting/activity-log')) {
            this.mode = 'full';
        }

        this._filtersSubscription = this._activityLogService.getFilters(this.mode)
            .pipe(
                map(filters => {
                    this._rows$ = this._fetchActivityLogs$
                        .pipe(
                            tap(() => this.loading = true),
                            map(() => this._buildParams()),
                            tap(params => this.activityLogs$ = this._activityLogService.getList(params, this.filterForm.value, this.mode)),
                            switchMap(params => this.activityLogs$),
                            map(response => this._setupList(response)),
                            catchError(() => of([])),
                            tap(() => this.loading = false),
                            shareReplay()
                        );

                    this.availableFilters = filters;
                    this._init();
                    this._fetchListData();

                    return filters;
                })
            )
            .subscribe();

    }

    public openFilterModal(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormModal);
    }

    public openDetailModal(row: ActivityLog): void {
        const modalRef = this._modalService.open(ActivityLogDetailModalComponent, {centered: true});
        modalRef.componentInstance.selectedActivityLog = row;
    }

    public trackByFn(index: number, activityLog: ActivityLog): number {
        return activityLog.id;
    }

    public onSubmitFilter(): void {
        this._filterFormModalRef.close();
        this._fetchListData();
    }

    protected _fetchListData(): void {
        this._fetchActivityLogs$.next();
    }
}
