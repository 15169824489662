import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DropzoneConfigInterface, DropzoneDirective } from 'ngx-dropzone-wrapper';
import { Observable, of, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DomainService } from 'src/app/core/services/domain.service';
import { CameraModalComponent } from 'src/app/shared/components/partials/camera-modal/camera-modal.component';
import { FileService } from 'src/app/shared/services/file.service';
import { environment } from 'src/environments/environment';
import { Employee } from '../../../../employee/models/Employee';
import { WindowRef } from '../../../window/';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnChanges, OnDestroy {
    @ViewChild(DropzoneDirective, {static: false})
    public dropZoneDirectiveRef: DropzoneDirective;

    @Input() public additionalParams: any;
    @Input() public allowTakingPicture = true;
    @Input() public clear$: Observable<void>;
    @Input() public fileName: string;
    @Input() public modelName: string;
    @Input() public model: Employee;
    @Input() public modelValue: any;
    @Input() public process$: Observable<void>;
    @Input() public secondaryModelName: string;
    @Input() public secondaryModelValue: any;
    @Input() public uploadMultiple = false;
    @Input() public showPreviewContainer = true;
    @Input() public style = '';
    @Input() public allowedExtensions = '.jpg, .png, .gif, .tiff, .heic, .jpeg, .pdf, .txt, .xls, .xlsx, .xlsm, .csv, .ods, .odt, .odp, .doc, .docx,' +
        ' .docm, .ppt, .pptm, .pptx, .pages, .numbers, .key, .rtf, .zip, .rar';

    @Output() public addedFile = new EventEmitter<File>();
    @Output() public addedFiles = new EventEmitter<File[]>();
    @Output() public failure = new EventEmitter<void>();
    @Output() public reset = new EventEmitter<Array<void>>();
    @Output() public sending = new EventEmitter<[File, XMLHttpRequest, FormData]>();
    @Output() public success = new EventEmitter<void>();
    @Output() public successMultiple = new EventEmitter<void>();
    @Output() public thumbnail = new EventEmitter<[File, string]>();

    public dropZoneConfig: DropzoneConfigInterface;
    public allowedFileSize = 32;
    public isLoading = false;
    public allowedFileExtensionsShort = '.jpg, .png, .gif, .pdf, .docx, .xlsx...';

    private _clearQueueSubscription: Subscription;
    private _processQueueSubscription: Subscription;

    public constructor(
        private _domainService: DomainService,
        private _fileService: FileService,
        private _modalService: NgbModal,
        private _translateService: TranslateService,
        private _windowRef: WindowRef
    ) { }

    public ngOnDestroy(): void {
        this._clearSubscriptions();
    }

    public ngOnChanges(): void {
        this.isLoading = false;
        if (
            this.allowTakingPicture === true &&
            !this.fileName
        ) {
            console.error('FileName has to be set if allowTakingPicture is true (default)');
        }

        this._clearSubscriptions();

        if (this.clear$) {
            this.clear$
                .subscribe(() => this.dropZoneDirectiveRef.reset());
        }

        if (this.process$) {
            this.process$
                .subscribe(() => {
                    if (this.uploadMultiple) {
                        const files = this.dropZoneDirectiveRef.dropzone().files;

                        for (const file of files) {
                            const formValue = this.additionalParams?.formValue[file.upload.uuid];
                            if (!formValue?.employee_ID || !formValue?.checked) {
                                this.dropZoneDirectiveRef.dropzone().removeFile(file);
                            }
                        }
                    }

                    this.dropZoneDirectiveRef.dropzone()?.processQueue();
                });
        }

        this.dropZoneConfig = this._getDropZoneConfig();
    }

    public onAddedFile(file: File): void {
        this.isLoading = true;
        this.addedFile.emit(file);
    }

    public onAddedFiles(files): void {
        const uploadedFiles = this.dropZoneDirectiveRef.dropzone().files;
        const filesArray = Array.from(files);

        for (const file of uploadedFiles) {
            if (!filesArray.find((e: any) => e.upload?.uuid === file.upload?.uuid)) {
                this.dropZoneDirectiveRef.dropzone().removeFile(file);
            }
        }

        this.addedFiles.emit(files);
    }

    public onFailure(event: Array<any>): void {
        if (event[1]?.message) {
            $('div.dz-error-message span').text(this._translateService.instant(event[1].message));
        }
        this.failure.emit();
    }

    public onReset(event: Array<void>): void {
        this.isLoading = false;
        this.reset.emit(event);
    }

    public onSendMultiple(event): void {
        if (this.additionalParams) {
            const files = event[0];
            const employeeIds = [];

            for (const [i, file] of files.entries()) {
                employeeIds[i] = this.additionalParams?.formValue[file.upload.uuid]?.employee_ID;
            }
            event[2].append('data', JSON.stringify(this.additionalParams?.data));
            event[2].append('employee_IDs', JSON.stringify(employeeIds));
        }
    }

    public onSend(event: [File, XMLHttpRequest, FormData]): void {
        if (this.showPreviewContainer) {
            const el = document.createElement('div');
            el.classList.add('col', 'lds-ellipsis');
            el.innerHTML = '<div></div><div></div><div></div><div></div>';
            const col = document.querySelector('.dz-processing > div > div > .align-items-center > .col.pl-0');
            document.querySelector('.dz-processing').classList.add('mb-3');
            col.parentNode.insertBefore(el, col.nextSibling);
            this.sending.emit(event);
            if (this.additionalParams) {
                event[2].append('data', this.additionalParams);
            }
        }
    }

    public onSuccess(): void {
        this.isLoading = false;
        this.success.emit();
    }

    public onSuccessMultiple(): void {
        this.isLoading = false;
        this.successMultiple.emit();
    }

    public onThumbnail(event: [File, string]): void {
        this.thumbnail.emit(event);
    }

    public openWebcamModal(): void {
        const modalRef = this._modalService.open(CameraModalComponent, {centered: true, size: 'lg'});

        modalRef.result
            .then(
                result => {
                    let file = this._createFileFromDataUrl(result);

                    this.addedFile.emit(file);

                    (this.process$ || of(null))
                        .pipe(first())
                        .subscribe(() => {
                            let additionalParams = null;

                            if (this.additionalParams) {
                                additionalParams = {data: this.additionalParams};
                            }

                            file = this._createFileFromDataUrl(result);

                            this._fileService.uploadFile(
                                file,
                                this.modelName,
                                this.modelValue,
                                this.secondaryModelValue,
                                additionalParams
                            )
                                .then(
                                    () => this.success.emit(),
                                    () => this.failure.emit()
                                );
                        });
                },
                () => { }
            );
    }

    private _clearSubscriptions(): void {
        if (this._clearQueueSubscription) {
            this._clearQueueSubscription?.unsubscribe();
        }

        if (this._processQueueSubscription) {
            this._processQueueSubscription?.unsubscribe();
        }
    }

    private _createFileFromDataUrl(dataURL: string): File {
        return this._fileService.dataURLtoFile(dataURL, this.fileName + '.jpeg');
    }

    private _getDropZoneConfig(): DropzoneConfigInterface {
        return {
            acceptedFiles: this.allowedExtensions,
            autoProcessQueue: !Boolean(this.process$),
            autoReset: 250,
            clickable: true,
            uploadMultiple: this.uploadMultiple,
            dictFileTooBig: this._translateService.instant('dropzone.error_file_too_big'),
            dictInvalidFileType: this._translateService.instant('dropzone.error_invalid_file_type'),
            dictResponseError: this._translateService.instant('dropzone.default_message'),
            maxFiles: this.uploadMultiple ? 50 : 1,
            maxFilesize: this.allowedFileSize,
            parallelUploads: 50,
            previewsContainer: this.showPreviewContainer === false ? false : null,
            previewTemplate: this._windowRef.nativeWindow.document.querySelector('#uploadPreviewTemplate').innerHTML,
            url: environment.protocol + this._domainService.getTenantNameWithStringAppend('.') + environment.serverUrl + (this.uploadMultiple ? '/api/files/upload-multiple' : '/api/files'),
            withCredentials: true
        };
    }
}
