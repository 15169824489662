import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CompanyBranch } from 'src/app/setting/models/CompanyBranch';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { CompanyPosition } from '../../../models/CompanyPosition';
import { CompanyPositionService } from '../../../services/company-position.service';
import { UserService } from '../../../services/user.service';
import { CreateEditCompanyPositionComponent } from '../../partials/create-edit-company-position/create-edit-company-position.component';

@Component({
    selector: 'app-company-positions-list',
    templateUrl: './company-positions-list.component.html',
    styleUrls: ['./company-positions-list.component.css']
})
export class CompanyPositionsListComponent extends FatherOfListComponent<CompanyPosition> implements OnInit {
    @ViewChild('filterFormModal', {static: false})
    public filterFormModal: TemplateRef<ElementRef>;

    public canCreate: boolean;
    public canEdit: boolean;
    public filterForm: UntypedFormGroup;
    public sort: Sort<CompanyBranch> = {
        column: 'created_at',
        direction: SORT_ASC
    };
    public positionsTypes = [
        {value: 'HPP', label: this._translateService.instant('employees_positions.HPP')},
        {value: 'DPC', label: this._translateService.instant('employees_positions.DPC')},
        {value: 'DPP', label: this._translateService.instant('employees_positions.DPP')},
        {value: 'ICO', label: this._translateService.instant('employees_positions.ICO')},
        {value: 'EXTERNIST', label: this._translateService.instant('employees_positions.EXTERNIST')},
    ];

    private _fetchPositions$ = new ReplaySubject<void>(1);
    private _filterFormModalRef: NgbModalRef;

    public constructor(
        private _companyPositionService: CompanyPositionService,
        private _modalService: NgbModal,
        private _fb: FormBuilder,
        private _translateService: TranslateService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public getCount(field: string) {
        return this.filterForm.get(field).value?.length;
    }

    public ngOnInit(): void {
        this.canCreate = this._authService.checkPermission('companyPosition.create');
        this.canEdit = this._authService.checkPermission('companyPosition.edit');

        this.filterForm = this._fb.group({
            type: [[]],
        });

        this.filterForm[`defaultValue`] = this.filterForm.value;

        this._rows$ = this._fetchPositions$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._companyPositionService.getPositionsList(params, this.filterForm.value)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();
        this.useLocalStorage(this.filterForm, 'companyPositionList');
        this._fetchListData();
    }

    public openEditModal(position?: CompanyPosition): void {
        const modalRef = this._modalService.open(CreateEditCompanyPositionComponent, {centered: true});
        modalRef.componentInstance.position = position;

        modalRef.result
            .then(
                () => {
                    this._fetchListData();
                },
                () => { }
            );
    }

    public trackByFn(index: number, position: CompanyPosition): number | string {
        return position.company_position_ID;
    }

    public onSubmitFilter(): void {
        this._filterFormModalRef.close();
        this.saveFormConfigToLocalStorage(this.filterForm.value, 'form');
        this._fetchListData();
    }

    public openFilterModal(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormModal);
    }

    protected _fetchListData(): void {
        this._fetchPositions$.next();
    }
}
